import { noop, Notification, ResendEmailRequest } from 'common';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { systemClient } from './httpClients/systemclient';

const EMAILS_SENT_KEY = 'emailSentList';
const RESEND_KEY = 'resendIt';

export function useGetEmailsSent() {
  return useQuery<Notification[]>({
    queryKey: [EMAILS_SENT_KEY],
    queryFn: async () => {
      const { data } = await systemClient.getEmailsSent();
      return data;
    },
  });
}

export function useResendEmail(
  onSuccess: (data: Notification) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) {
  const { refetch: refetchList } = useQuery({
    queryKey: [EMAILS_SENT_KEY],
  });
  return useMutation({
    mutationKey: [RESEND_KEY],
    mutationFn: async ({ emailSentId }: ResendEmailRequest) => {
      const body: ResendEmailRequest = { emailSentId };
      const { data } = await systemClient.resendEmail(body);
      return data;
    },
    onSuccess: (data) => {
      onSuccess(data);
      refetchList().then(noop).catch(noop);
    },
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [EMAILS_SENT_KEY] });
    },
  });
}
