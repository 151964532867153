import { UseMutateFunction } from '@tanstack/react-query';
import {
  arrayTop,
  Button,
  ButtonBar,
  Drawer,
  ExternalCustomer,
  ExternalObjectReference,
  FormField,
  isDefined,
  Loading,
  QuerySelect,
  RequestParams,
  SearchSelect,
  useDebounce,
  useToast,
  useTranslation,
} from 'common';
import React, { useEffect, useState } from 'react';
import {
  filterCrmConnectors,
  useGetConnectors,
} from '../../services/connectors';
import { CrmSourceType } from '../Settings/Connectors/ConnectorConstants/constants';
import { AxiosPromise } from 'axios';
import { useGetAllExternalDeals } from '../../services/externalCrm';

type fetchOptionsQuery<T> = (
  source:
    | 'avalara'
    | 'cacheflow_checkout'
    | 'close'
    | 'hubspot'
    | 'manual'
    | 'netsuite'
    | 'plaid'
    | 'profitwell'
    | 'quickbooks'
    | 'salesforce'
    | 'stripe',
  query?: {
    search?: string;
  },
  params?: RequestParams
) => AxiosPromise<T[]>;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  linkEntityToCRM: UseMutateFunction<
    ExternalObjectReference[],
    unknown,
    ExternalObjectReference,
    unknown
  >;
  isUpdating: boolean;
  fetchOptions?: fetchOptionsQuery<any>;
  headerLabel: string;
  isSearchable: boolean;
  entityLabel: string;
}

const LinkEntityToCrmDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  linkEntityToCRM,
  isUpdating,
  fetchOptions,
  headerLabel,
  isSearchable,
  entityLabel,
}) => {
  const { tk } = useTranslation();
  const showToast = useToast();
  const [selectedSource, setSelectedSource] = useState<CrmSourceType>();
  const [selectedEntity, setSelectedEntity] = useState<
    ExternalCustomer | undefined
  >();
  const [entity, setEntity] = useState<ExternalCustomer[] | []>();
  const [isFetching, setIsFetching] = useState(false);
  const [search, setSearch] = useState<string>('');
  const { data: connectors, isLoading } = useGetConnectors();
  const { data: deals } = useGetAllExternalDeals(selectedSource);

  const submit = () => {
    linkEntityToCRM({
      sourceType: selectedSource,
      sourceId: selectedEntity?.externalId,
    });
  };

  const crmSources = filterCrmConnectors(connectors);

  const [selectedSourceOption, setSelectedSourceOption] = useState<{
    name: string;
    value: string;
  }>();

  const { debouncedFunction: debouncedFetchOptions } = useDebounce(
    (searchParam) => {
      if (searchParam.trim() === '') {
        setEntity([]);
        setIsFetching(false);
        return;
      }

      setIsFetching(true);
      fetchOptions!(selectedSource!, {
        search: searchParam ? `name:${searchParam}` : undefined,
      })
        .then((response) => {
          setEntity(response.data);
        })
        .catch(() => {
          showToast.error('Error fetching options');
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    500
  );

  useEffect(() => {
    if (isSearchable) {
      if (search.trim() !== '' && selectedSource) {
        debouncedFetchOptions(search);
      } else {
        setEntity([]);
        setIsFetching(false);
      }
    } else {
      setEntity(
        deals?.filter((deal) =>
          deal.name?.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
  }, [search]);

  return (
    <Drawer
      footer={
        <ButtonBar>
          <Button
            block
            isDisabled={isUpdating}
            label={tk('Cancel')}
            onClick={onClose}
            type="secondary"
          />
          <Button
            block
            dataTestId="customer-link-to-crm-submit"
            isDisabled={
              !isDefined(selectedSource) ||
              !isDefined(selectedEntity) ||
              isUpdating ||
              isUpdating
            }
            label={tk('Update')}
            onClick={submit}
          />
        </ButtonBar>
      }
      header={headerLabel}
      isOpen={isOpen}
      onClose={onClose}
    >
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <FormField label={tk('Select source')}>
            <SearchSelect
              dataTestId="entity-crm-source"
              displayField="name"
              onChange={(option) => {
                setSelectedSource(option.value as CrmSourceType);
                setSelectedSourceOption(option);
              }}
              options={crmSources}
              placeholder="Select source"
              value={selectedSourceOption || arrayTop(crmSources)}
            />
          </FormField>
          {selectedSourceOption?.name && (
            <FormField label={tk(`${entityLabel} name`)}>
              <QuerySelect
                dataTestId="entity-name"
                displayField="name"
                isSearching={isFetching}
                onChange={setSelectedEntity}
                onSearch={(newSearch) => {
                  setSearch(newSearch);
                }}
                options={entity || deals || []}
                placeholder={`Enter name to get ${entityLabel.toLowerCase()}`}
                value={selectedEntity}
              />
            </FormField>
          )}
        </>
      )}
    </Drawer>
  );
};

export default LinkEntityToCrmDrawer;
