import React from 'react';
import { Card, ZapierConfig } from 'common';
import { useOrgDefaults } from 'app/src/services/orgDefaults';
import { accountContactsConfig, ZAPIER_INFO } from './constants';
import ZapierSVG from '../../../assets/integration-logos/ZapierSVG';
import { ConnectorTabs } from '../../../core-utils/enums/ConnectorTabs';
import { ConnectorConfiguration } from '../Connectors/ConnectorConstants/constants';
import ConnectorSettings, {
  ConnectorTab,
} from '../Connectors/ConnectorSettings';
import useScript from 'app/src/core-utils/helperFunctions/useScript';
import './ZapierSettings.css';

const ZapierSettings = () => {
  const tabs: ConnectorTab[] = [
    {
      key: ConnectorTabs.configuration,
      label: 'Configuration',
      systemOnly: false,
    },
  ];

  const configuration: ConnectorConfiguration = {
    name: null,
    lines: [
      {
        fieldName: 'enabled',
        type: 'toggle',
      },
      {
        fieldName: 'clientId',
        type: 'string',
      },
    ],
  };

  const { data: orgConfigs } = useOrgDefaults();

  const configValue = orgConfigs?.get('zapierConfig')
    ?.configValue as ZapierConfig;

  const isConnected: boolean =
    (configValue?.enabled && configValue?.clientId !== null) || false;

  const loadedScript = useScript({
    url: 'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js',
    id: 'zapier',
    async: true,
    shouldLoad: isConnected,
  } as const);

  return (
    <div>
      <ConnectorSettings
        accountContactsConfig={accountContactsConfig}
        configuration={configuration}
        connectorInfo={ZAPIER_INFO}
        logo={ZapierSVG}
        tabs={tabs}
      />
      {isConnected && loadedScript && (
        <Card>
          <zapier-workflow
            app-selection-display="hide"
            client-id={configValue?.clientId}
            intro-copy-display="hide"
            manage-zaps-display="hide"
            sign-up-email="email_of_your_user@example.com"
            sign-up-first-name="first_name_of_your_user"
            sign-up-last-name="last_name_of_your_user"
            template-limit={2}
            template-style="row"
            theme="light"
          />
        </Card>
      )}
    </div>
  );
};

export default ZapierSettings;
