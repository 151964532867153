import {
  isChangeProposalType,
  isDraftProposalStatus,
  isExpiredProposalStatus,
  isNumber,
  isRenewalProposalType,
  isString,
  isTemplateProposalType,
  PaymentSchedule,
  Product,
  ProductSummary,
  Proposal,
  ProposalSummary,
} from 'common';
import { usePaymentMethods } from 'app/src/services/paymentMethods';
const TEMPLATE = 'template';

export const validateExpirationDate = (value?: string): string | null => {
  if (value === undefined) {
    // no expiration date means the proposal will never expire
    return null;
  }

  // pass time to constructor so that it creates date in local timezone
  const newDate = new Date(`${value}T00:00:00`);
  const todaysDate = new Date();
  todaysDate.setHours(0, 0, 0, 0);
  if (newDate < todaysDate) {
    return 'Expiration date must be in the future or blank.';
  }
  return null;
};

type ProposalValidity = {
  autoRenewalError: null | string | boolean;
  customerError: null | string;
  expirationError: null | string;
  isValid: boolean;
  noManagedPaymentMethodError: null | string;
  noSigningRequiredError: null | string;
  productError: null | string;
  proposalNumberError: null | string;
  termQtyError: null | string;
};

export const isProposalTemplate = (
  proposal: Proposal | ProposalSummary
): boolean => {
  return proposal.proposalType === TEMPLATE;
};

export type ValidationAction = 'create-subscription' | undefined;

export function useValidateProposal(
  proposal: Proposal,
  action: ValidationAction = undefined
): ProposalValidity {
  const { data: paymentMethods } = usePaymentMethods(true, proposal.currency);

  const rv: Omit<ProposalValidity, 'isValid'> = {
    customerError: null,
    productError: null,
    termQtyError: null,
    expirationError: null,
    proposalNumberError: null,
    autoRenewalError: null,
    noManagedPaymentMethodError: null,
    noSigningRequiredError: null,
  };

  const isTemplate = isTemplateProposalType(proposal);

  // Combining Product related errors into one error message
  if (
    !isTemplate &&
    !!proposal.renewalConfiguration &&
    !containsAutoRenewableProduct(proposal)
  ) {
    rv.productError =
      'Auto-renewal requires at least one recurring or usage-based product.';
  } else if (
    !isTemplate &&
    proposal.proposalItems.length === 0 &&
    !isProposalPublished(proposal.status)
  ) {
    rv.productError = 'Add at least one product.';
  }

  if (!isTemplate && !proposal.customer) {
    rv.customerError = 'A customer must be selected.';
  }

  if (!proposal.termQty || proposal.termQty <= 0) {
    rv.termQtyError = 'Term quantity must be greater than 0.';
  }

  if (!proposal.proposalNumber && !isTemplate) {
    rv.proposalNumberError = 'A proposal number needs to be set.';
  }

  // only check expiration in draft status, old proposals with expired expiration dates
  // are still "valid" and we should be able to access the share dialog
  if (!isTemplate && isDraftProposalStatus(proposal)) {
    rv.expirationError = validateExpirationDate(proposal.expiresAt);
  }

  if (action === 'create-subscription') {
    const foundSchedule = proposal.schedules.find(
      (schedule) =>
        (!!proposal.acceptedScheduleId &&
          schedule.id === proposal.acceptedScheduleId) ||
        (!proposal.acceptedScheduleId &&
          schedule.id === proposal.selectedScheduleId) ||
        (!proposal.acceptedScheduleId &&
          !proposal.selectedScheduleId &&
          schedule.payEvery === proposal.defaultBillingPeriod)
    )!;
    const mappedPaymentMethods = foundSchedule.paymentMethodIds?.map((id) =>
      paymentMethods?.find((p) => p.id === id)
    );
    if (mappedPaymentMethods?.find((p) => p?.managed)) {
      rv.noManagedPaymentMethodError =
        'Credit Card and Direct Debit are not supported for this feature, please select another.';
    }

    if (proposal.options?.requiresSigning) {
      rv.noSigningRequiredError =
        'DocuSign is unsupported for this feature, please remove.';
    }
  }

  return {
    ...rv,
    isValid: !Object.values(rv).some(isString),
  };
}

const containsAutoRenewableProduct = (proposal: Proposal): boolean => {
  return proposal.proposalItems.some(
    (item) =>
      item.product.recurrence === 'recurring' ||
      item.product.recurrence === 'usage'
  );
};

export const getPublishStatus = (proposal: Proposal) => {
  const requiresSigning = proposal.options?.requiresSigning;
  return requiresSigning ? 'schedule_selection' : 'active';
};

const ACTIVE_CHANGE_RENEWAL_STATUS_SET = new Set<Proposal['status']>([
  'draft',
  'active',
  'approved',
  'payment_selection',
  'schedule_selection',
  'pending_renewal',
]);

const PUBLISHED_STATUS_SET = new Set<Proposal['status']>([
  'active',
  'payment_selection',
  'schedule_selection',
  'accepted',
  'pending_renewal',
]);

const ACTIVE_STATUS_SET = new Set<Proposal['status']>([
  'active',
  'payment_selection',
  'schedule_selection',
]);

const EXPIRED_STATUS_SET = new Set<Proposal['status']>(['expired']);

export const isProposalPublished = (status?: Proposal['status']): boolean => {
  return !!status && PUBLISHED_STATUS_SET.has(status);
};

export const isProposalReadonly = (proposal: Proposal): boolean => {
  return !!(proposal.deleted || proposal.archived);
};

export const isProposalActive = (status?: Proposal['status']): boolean => {
  return !!status && ACTIVE_STATUS_SET.has(status);
};

export const isExpiredChangeProposal = (proposal: Proposal): boolean => {
  return (
    isChangeProposalType(proposal) &&
    EXPIRED_STATUS_SET.has(proposal.status) &&
    isExpiredProposalStatus(proposal)
  );
};

export const isExpiredRenewalProposal = (proposal: Proposal): boolean => {
  return (
    isRenewalProposalType(proposal) &&
    EXPIRED_STATUS_SET.has(proposal.status) &&
    isExpiredProposalStatus(proposal)
  );
};

export const isActiveChangeProposal = (proposal: Proposal): boolean => {
  return (
    isChangeProposalType(proposal) &&
    ACTIVE_CHANGE_RENEWAL_STATUS_SET.has(proposal.status)
  );
};

export const isActiveRenewalProposal = (proposal: Proposal): boolean => {
  return (
    isRenewalProposalType(proposal) &&
    ACTIVE_CHANGE_RENEWAL_STATUS_SET.has(proposal.status)
  );
};

export const isProposalPendingApproval = (
  status?: Proposal['status']
): boolean => {
  return status === 'pending_approval';
};

export const proposalHasRecipients = (proposal: Proposal): boolean => {
  return proposal.proposalContacts.length > 0;
};

export const isProposalSourced = (proposal: Proposal): boolean =>
  !!proposal.source;

// This is meant to be a unique hash of proposal state that can be used to
// key things like the share drawer iframe where updatedAt is insufficient
export const getProposalHash = (proposal: Proposal | ProposalSummary): string =>
  JSON.stringify(proposal);

const getProductIds = (
  product: Product | ProductSummary | undefined
): string[] => {
  if (!product) {
    return [];
  }

  const ids = [product.id, product.rootId].filter(isString);

  if ('bundleItems' in product && product.bundleItems?.length) {
    return [
      ...ids,
      ...product.bundleItems.reduce<string[]>(
        (acc, bundleItem) => [...acc, ...getProductIds(bundleItem.product)],
        []
      ),
    ].filter(isString);
  }

  return ids;
};

export const getUnselectedProducts = (
  eligibleProducts: ProductSummary[] | undefined,
  existingProducts: Product[] | ProductSummary[]
): ProductSummary[] => {
  if (!eligibleProducts) {
    return [];
  }

  const existingProductAndRootIds: string[] = existingProducts
    .reduce<
      Array<string | undefined>
    >((acc, product) => [...acc, ...getProductIds(product)], [])
    .filter(isString);

  return eligibleProducts.filter((product: ProductSummary) => {
    const { id, rootId } = product;

    return (
      (!rootId || !existingProductAndRootIds.includes(rootId)) &&
      (!id || !existingProductAndRootIds.includes(id))
    );
  });
};

export const findSchedule = (proposal: Proposal): PaymentSchedule | undefined =>
  proposal.schedules.find(
    (schedule) =>
      // Find accepted schedule
      (!!proposal.acceptedScheduleId &&
        schedule.id === proposal.acceptedScheduleId) ||
      // or selected schedule
      (!proposal.acceptedScheduleId &&
        schedule.id === proposal.selectedScheduleId) ||
      // or default schedule
      (!proposal.acceptedScheduleId &&
        !proposal.selectedScheduleId &&
        schedule.payEvery === proposal.defaultBillingPeriod)
  );

export const calcPriceDiscount = (
  overridePrice: number | undefined,
  listPrice: number | undefined
): number => {
  if (!isNumber(overridePrice) || !isNumber(listPrice)) {
    return listPrice || 0;
  }

  const percentDifference: number = Math.round(
    ((listPrice - overridePrice) / listPrice) * 100
  );

  // Markdown, AKA 10% off original price
  if (percentDifference >= 0) {
    return percentDifference;
  }

  // Markup, AKA 110% of original price
  return Math.abs(percentDifference) + 100;
};

export const calcDiscountedPrice = (
  percentOff: number | undefined,
  listPrice: number | undefined
): number => {
  if (!isNumber(percentOff)) {
    return listPrice || 0;
  }

  const val = percentOff > 100 ? percentOff / 100 : 1 - percentOff / 100;
  return (listPrice || 0) * val;
};
