import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { BillingPeriodString } from './PaymentPlanContext';
import {
  BILLING_PERIODS,
  BillingPeriodsType,
  Button,
  ButtonBar,
  Checkbox,
  CreateProposalRequest,
  Drawer,
  getBillingPeriodLabelAdjective,
  getErrorMessage,
  isArrayNonEmpty,
  isChangeProposalType,
  isDefined,
  isDraftProposalStatus,
  Loading,
  Nullable,
  PaymentMethod,
  PaymentSchedule,
  Proposal,
  ToggleSwitch,
  useFlags,
  useToast,
  useTranslation,
} from 'common';
import PaymentMethodName from '../../Settings/PaymentMethods/PaymentMethodName';
import { useUpdatePaymentSchedule } from 'app/src/services/api/schedules/paymentSchedule';
import './PaymentPlanOptionsEditDrawer.scss';
import styles from './PaymentPlanOption.module.scss';
import { useContract } from '../../../services/contract';
import TooltipPill from 'app/src/components/TooltipPill';

interface Props {
  allPaymentMethods: PaymentMethod[];
  closePaymentOptionsEditPanel: () => void;
  isPaymentOptionsEditPanelOpen: boolean;
  proposal: Proposal;
  setProposalField: (
    field: keyof CreateProposalRequest,
    value: Nullable<CreateProposalRequest>[keyof CreateProposalRequest]
  ) => void;
}

const PaymentPlanOptionsEditDrawer: React.FC<Props> = ({
  closePaymentOptionsEditPanel,
  isPaymentOptionsEditPanelOpen,
  allPaymentMethods,
  proposal,
  setProposalField,
}) => {
  const qc = useQueryClient();
  const showToast = useToast();
  const updateSuccess = () => {
    showToast.success(tk('Payment Schedule updated'));
  };
  const updateError = (err: unknown) => {
    showToast.error(
      tk(
        `Something went wrong updating Payment Schedule, please try again: ${getErrorMessage(
          err
        )}`
      )
    );
  };
  const contractDetails = useContract(proposal.contract?.id);
  const saveDefaultEnabledBillingPeriods = (defaultBPs: string[]) => {
    if (defaultBPs.length) {
      setProposalField('billingPeriods', defaultBPs);
    }
  };

  const saveDefaultSelectedBillingPeriod = (defaultBP: string) => {
    if (defaultBP) {
      setProposalField('defaultBillingPeriod', defaultBP);
    }
  };

  const { mutate: updatePaymentSchedule, isPending: isLoadingPaymentSchedule } =
    useUpdatePaymentSchedule(proposal.id, updateSuccess, updateError, qc);

  const savePaymentSchedule = (newSchedule: PaymentSchedule) => {
    updatePaymentSchedule(newSchedule);
  };

  const getUnsupportedProducts = (billingPeriod: BillingPeriodsType) => {
    return proposal.proposalItems.filter(
      (item) =>
        item.product.recurrence !== 'one_time' &&
        item.product.billing !== 'fixed' &&
        !item.product.billingPeriods?.includes(billingPeriod)
    );
  };

  const { tk } = useTranslation();
  const { upfrontPayments, enableChangeProposalPaymentMethods } = useFlags();

  let enabledPeriods: BillingPeriodsType[] = [];
  const billingPeriodsToMethods = new Map<
    BillingPeriodsType,
    PaymentMethod[]
  >();

  const paymentSchedules = proposal.schedules;

  allPaymentMethods.forEach((pm) => {
    if (pm.billingPeriods) {
      pm.billingPeriods.forEach((bp) => {
        const pmList = billingPeriodsToMethods.get(bp) || [];
        billingPeriodsToMethods.set(bp, [...pmList, pm]);
      });
    }
  });

  // check for schedules and payment methods that are no longer active on org, but available in the proposal
  paymentSchedules.forEach((ps) => {
    let pmList = billingPeriodsToMethods.get(ps.payEvery);
    if (!pmList) {
      pmList = [];
    }
    const schedulePaymentMethods = (ps.paymentMethodIds ?? [])
      .map((paymentMethodId) => {
        if (!pmList.find((pm) => pm.id === paymentMethodId)) {
          return allPaymentMethods.find((pm) => pm.id === paymentMethodId);
        }
        return null;
      })
      .filter(isDefined);

    billingPeriodsToMethods.set(ps.payEvery, [
      ...pmList,
      ...schedulePaymentMethods,
    ]);
  });

  enabledPeriods = [...billingPeriodsToMethods.keys()];

  const defaultBillingPeriods = proposal.schedules.map((ps) => ps.payEvery);

  const toggleBillingPeriod = (bp: BillingPeriodsType) => {
    // Check for product support before toggling billing period on.
    const periodWasActive = defaultBillingPeriods.includes(bp);

    if (!periodWasActive) {
      const unsupportedProducts = getUnsupportedProducts(bp);
      if (isArrayNonEmpty(unsupportedProducts)) {
        const billingPeriodLabel = getBillingPeriodLabelAdjective(bp);

        if (unsupportedProducts.length === 1) {
          showToast.error(
            `Product ${
              unsupportedProducts[0].name
            } does not have a price for: ${billingPeriodLabel}`
          );
        } else {
          showToast.error(
            `Products ${unsupportedProducts
              .map((p) => p.name)
              .join(', ')} do not have prices for: ${billingPeriodLabel}`
          );
        }

        return;
      }
    }

    const defaultDraft = periodWasActive
      ? [...defaultBillingPeriods.filter((key) => key !== bp)]
      : [...defaultBillingPeriods, bp];
    saveDefaultEnabledBillingPeriods(defaultDraft);
  };

  const handleUpfrontToggle = (paymentScheduleId?: string) => {
    if (paymentScheduleId) {
      const draftSchedule = paymentSchedules.find(
        (schedule) => schedule.id === paymentScheduleId
      );
      if (draftSchedule) {
        draftSchedule.scheduleType =
          draftSchedule.scheduleType === 'upfront' ? 'standard' : 'upfront';
        savePaymentSchedule(draftSchedule);
      }
    }
  };

  const handlePaymentMethodToggle = (
    paymentScheduleId?: string,
    paymentMethodId?: string
  ) => {
    if (paymentScheduleId && paymentMethodId) {
      const draftSchedule = paymentSchedules.find(
        (schedule) => schedule.id === paymentScheduleId
      );
      if (draftSchedule) {
        if (draftSchedule.paymentMethodIds) {
          if (
            draftSchedule.paymentMethodIds.find(
              (pmId) => pmId === paymentMethodId
            )
          ) {
            const indexPM = draftSchedule.paymentMethodIds.findIndex(
              (pmId) => pmId === paymentMethodId
            );
            const pmDraft = [...draftSchedule.paymentMethodIds];
            pmDraft.splice(indexPM, 1);
            draftSchedule.paymentMethodIds = pmDraft;
          } else {
            draftSchedule.paymentMethodIds = [
              ...draftSchedule.paymentMethodIds,
              paymentMethodId,
            ];
          }
          savePaymentSchedule(draftSchedule);
        }
      }
    }
  };

  const isProposalPaymentMethodDisabled = (pm: PaymentMethod) => {
    if (enableChangeProposalPaymentMethods) {
      const isProposalPaymentMethodDisabledForRenewal =
        proposal.proposalType === 'renewal' &&
        proposal.billingMethod?.paymentMethod.id === pm.id &&
        !proposal.billingMethod?.paymentMethod.enabled;
      const isProposalPaymentMethodDisabledForChange =
        proposal.proposalType === 'change' &&
        contractDetails.data?.billingMethod?.paymentMethod.id === pm.id &&
        !contractDetails.data?.billingMethod?.paymentMethod.enabled;

      return (
        isProposalPaymentMethodDisabledForRenewal ||
        isProposalPaymentMethodDisabledForChange
      );
    } else {
      return (
        proposal.proposalType === 'renewal' &&
        proposal.billingMethod?.paymentMethod.id === pm.id &&
        !proposal.billingMethod?.paymentMethod.enabled
      );
    }
  };

  const MakeDefaultButton = ({ bp }: { bp: BillingPeriodString }) => {
    if (!isDraftProposalStatus(proposal) || !bp) {
      return null;
    }

    return (
      <Button
        className={styles.makeDefaultButton}
        dataTestId={`schedule-make-default-${bp}`}
        label={tk('Make default')}
        onClick={() => saveDefaultSelectedBillingPeriod(bp)}
        type="link"
      />
    );
  };

  return (
    <Drawer
      className="payment-plan-options-edit"
      footer={
        <ButtonBar>
          <Button
            label={tk('Done')}
            onClick={closePaymentOptionsEditPanel}
            type="secondary"
          />
        </ButtonBar>
      }
      header={tk('Payments')}
      isOpen={isPaymentOptionsEditPanelOpen}
      onClose={closePaymentOptionsEditPanel}
    >
      <div className="payment-plan-options-edit-header">
        <h3>{tk('Billing periods & payment methods')}</h3>
        <p>
          {tk(
            'Enable or disable billing periods and the payment methods you wish to offer on this proposal. "Default" indicates the billing period that will be displayed first when a client views this proposal.'
          )}
        </p>
      </div>
      {BILLING_PERIODS.map((billingPeriod) => {
        const billingPeriodEnabled = enabledPeriods.includes(billingPeriod);
        if (billingPeriodEnabled) {
          const ps = paymentSchedules.find(
            (schedule) => schedule.payEvery === billingPeriod
          );
          const isActive = defaultBillingPeriods.includes(billingPeriod);

          if (ps) {
            const paymentMethods =
              billingPeriodsToMethods.get(billingPeriod) || [];
            const selectedPaymentMethods = ps.paymentMethodIds || [];
            return (
              <div className="payment-schedule-wrapper" key={billingPeriod}>
                <div
                  className={clsx(
                    'payment-schedule-header',
                    styles.paymentPlanOption
                  )}
                >
                  <div className="payment-schedule-header-left">
                    <ToggleSwitch
                      alignLabel="right"
                      isDisabled={
                        (paymentSchedules.length === 1 && isActive) ||
                        isChangeProposalType(proposal)
                      }
                      label={`${getBillingPeriodLabelAdjective(
                        billingPeriod
                      )} billing ${isActive ? tk('enabled') : tk('disabled')}`}
                      name={billingPeriod}
                      onChange={() => toggleBillingPeriod(billingPeriod)}
                      value={isActive}
                    />
                    {upfrontPayments && (
                      <Checkbox
                        label="Pay upfront"
                        name="Upfront"
                        onChange={() => handleUpfrontToggle(ps.id)}
                        value={ps.scheduleType === 'upfront'}
                      />
                    )}
                  </div>
                  {proposal.defaultBillingPeriod === billingPeriod ? (
                    <div className="tooltip-pill">
                      <TooltipPill
                        dataTestId={`schedule-default-${ps.payEvery}`}
                        label={tk('Default')}
                        tooltipPlacement="left"
                        tooltipText="Default is the option initially selected in checkout"
                      />
                    </div>
                  ) : (
                    <MakeDefaultButton bp={ps.payEvery} />
                  )}
                  <div />
                </div>
                {isActive && (
                  <div className="payment-options">
                    <h4>{tk('Payment methods')}</h4>

                    {isLoadingPaymentSchedule && (
                      <div className="payment-methods-loading">
                        <Loading />
                      </div>
                    )}
                    {paymentMethods.map((pm) => {
                      const selected =
                        !!pm.id && selectedPaymentMethods.includes(pm.id);

                      const checkboxSelectionDisabled =
                        (selectedPaymentMethods.length < 2 && selected) ||
                        isLoadingPaymentSchedule ||
                        (isProposalPaymentMethodDisabled(pm) && !selected
                          ? false
                          : !pm.enabled && !selected);
                      return (
                        <Checkbox
                          className="pb-6"
                          dataTestId={`billing-period-${billingPeriod}-payment-toggle-${pm.name}`}
                          isDisabled={checkboxSelectionDisabled}
                          key={billingPeriod + pm.id}
                          label={
                            <>
                              <PaymentMethodName method={pm} />
                              {pm.enabled ? null : (
                                <div className="payment-method-disabled">
                                  {tk('disabled')}
                                </div>
                              )}
                            </>
                          }
                          name={billingPeriod + pm.id}
                          onChange={() =>
                            handlePaymentMethodToggle(ps.id, pm.id)
                          }
                          value={selected}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            );
          } else {
            return (
              <div className="payment-schedule-wrapper" key={billingPeriod}>
                <div
                  className={clsx(
                    'payment-schedule-header',
                    styles.paymentPlanOption
                  )}
                >
                  <ToggleSwitch
                    alignLabel="right"
                    isDisabled={isChangeProposalType(proposal) && !isActive}
                    label={`${getBillingPeriodLabelAdjective(
                      billingPeriod
                    )} billing ${isActive ? tk('enabled') : tk('disabled')}`}
                    name={billingPeriod}
                    onChange={() => toggleBillingPeriod(billingPeriod)}
                    value={isActive}
                  />
                </div>
                {isActive && <Loading />}
              </div>
            );
          }
        }
        return null;
      })}
    </Drawer>
  );
};

export default PaymentPlanOptionsEditDrawer;
