import { useQueryClient } from '@tanstack/react-query';
import {
  useDeleteCompanyInfo,
  useSaveCompanyInfo,
} from '../../../services/api/branding/companyInfo';
import { CompanyInfo, useToast } from 'common';
import { useAuth } from '../../../Auth';
import {
  OrganizationProfile,
  useSaveOrganizationInfo,
} from '../../../services/api/profile/user';

import { doCreateDocument } from '../../../services/api/documents/documents';

interface UpdateFormProps {
  onSuccess?: (companyInfo: CompanyInfo) => void;
}

export const useCompanyDetailsForm = ({ onSuccess }: UpdateFormProps) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const showToast = useToast();

  const handleSubmitError = () => {
    showToast.error('Failed to update company details');
  };

  const handleSubmitSuccess = (data: CompanyInfo) => {
    onSuccess?.(data);
    showToast.info('Company details updated successfully');
  };

  const { mutate: updateCompanyInfo } = useSaveCompanyInfo(
    handleSubmitSuccess,
    handleSubmitError,
    queryClient
  );

  const { mutate: updateOrgInfo } = useSaveOrganizationInfo(
    () => {},
    handleSubmitError,
    queryClient
  );

  const removeSpaces = (fileName: string): string => {
    return fileName.replace(/\s+/g, '');
  };

  const submitHandler = async (
    formStateArg: CompanyInfo,
    newLogo: null | File
  ) => {
    const formState = { ...formStateArg };

    if (newLogo) {
      const logo: CompanyInfo['logo'] = await doCreateDocument({
        document: { fileName: removeSpaces(newLogo.name) },
        file: newLogo,
      });
      formState.logoId = logo?.id;
    }

    if (formState.name && auth.organizationName !== formState.name) {
      const orgInfo: OrganizationProfile = {
        id: auth.organizationId,
        name: formState.name,
      };
      updateOrgInfo(orgInfo);
    }

    updateCompanyInfo(formState);
  };

  return { submitHandler };
};

export const useDeleteCompanyDetails = () => {
  const queryClient = useQueryClient();
  const showToast = useToast();

  const handleSubmitError = () => {
    showToast.error('Failed to delete company details');
  };

  const handleSubmitSuccess = () => {
    showToast.info('Company details deleted successfully');
  };

  const { mutate: deleteHandler } = useDeleteCompanyInfo(
    handleSubmitSuccess,
    handleSubmitError,
    queryClient
  );

  return { deleteHandler };
};
