import React from 'react';
import {
  ForceUser,
  formatDatetime,
  Icon,
  OrgConfig,
  SalesforceConfig,
  Select,
  ToggleSwitch,
  ToolTip,
  Type,
  useFlags,
} from 'common';
import styles from './SalesforceConnectionExtras.module.scss';

interface Props {
  connectorConfigValue?: SalesforceConfig;
  salesforceUser: ForceUser;
  setConnectorConfigValue: React.Dispatch<
    React.SetStateAction<OrgConfig['configValue']>
  >;
  setSavableChanges: (value: boolean) => void;
  setSyncSignedDocumentDraft: (value: boolean) => void;
  setSyncSubscriptionsDraft: (value: boolean) => void;
  syncSignedDocument: boolean;
  syncSignedDocumentDraft: boolean;
  syncSubscriptions: boolean;
  syncSubscriptionsDraft: boolean;
}

const SalesforceConnectionExtras: React.FC<Props> = ({
  connectorConfigValue,
  setConnectorConfigValue,
  setSavableChanges,
  setSyncSubscriptionsDraft,
  syncSubscriptions,
  syncSubscriptionsDraft,
  salesforceUser,
  syncSignedDocument,
  syncSignedDocumentDraft,
  setSyncSignedDocumentDraft,
}) => {
  const {
    syncSignedDocument: syncSignedDocumentFlag,
    salesforceSyncLineItems,
  } = useFlags();

  enum signedProposalLocation {
    ATTACHMENTS = 'NOTES & ATTACHMENTS',
    FILES = 'FILES',
  }

  return (
    <>
      <div className="my-4">
        <div>
          {salesforceUser.FirstName} {salesforceUser.LastName} (
          {salesforceUser.Username})
        </div>
      </div>
      <h3>Cacheflow for Salesforce Package</h3>
      <div className="my-4">
        {salesforceUser.PackageInstalledAt ? (
          <div>
            <div>Version: {salesforceUser.PackageVersion}</div>
            <div>
              Installed: {formatDatetime(salesforceUser.PackageInstalledAt)}
            </div>
            {connectorConfigValue?.priceBook2Id && (
              <div>
                Cacheflow price book Id: {connectorConfigValue.priceBook2Id}
              </div>
            )}
          </div>
        ) : (
          <div>
            <Type size={18} paragraph>
              Cacheflow for Salesforce package not installed
            </Type>
          </div>
        )}
      </div>
      <h3>Settings</h3>
      <div className={styles.settings}>
        <div className="text-sm">
          Push subscription information to Salesforce. The Cacheflow for
          Salesforce package must be installed in the organization.
        </div>
        <div>
          <ToggleSwitch
            label="Enable"
            isDisabled={!salesforceUser.PackageInstalledAt}
            value={syncSubscriptionsDraft}
            onChange={(checked: boolean) => {
              setSyncSubscriptionsDraft(checked);
              setSavableChanges(checked !== syncSubscriptions);
            }}
            name="syncSubscriptions"
          />
        </div>
      </div>
      {syncSignedDocumentFlag && (
        <div className={styles.settings}>
          <div className="text-sm">
            Sync signed proposal as PDF to opportunity
          </div>
          <div>
            <ToggleSwitch
              label="Enable"
              value={syncSignedDocumentDraft}
              onChange={(checked: boolean) => {
                setSyncSignedDocumentDraft(checked);
                setSavableChanges(checked !== syncSignedDocument);
              }}
              name="syncSignedDocument"
            />
          </div>
        </div>
      )}
      {syncSignedDocumentDraft && (
        <div className="flex justify-between mt-5 items-center">
          <div className="text-sm">
            Select where you would like signed proposals to sync
          </div>
          <div className="w-1/4">
            <Select
              value={connectorConfigValue?.signedProposalLocation}
              onChange={(e) => {
                setConnectorConfigValue(
                  (prev: OrgConfig['configValue']) =>
                    ({
                      ...prev,
                      signedProposalLocation: e,
                      configType: 'salesforceConfig',
                    }) as OrgConfig['configValue']
                );
                setSavableChanges(true);
              }}
              options={Object.entries(signedProposalLocation).map((entry) => ({
                value: entry[0],
                name: entry[1],
              }))}
            />
          </div>
        </div>
      )}
      {salesforceSyncLineItems && (
        <div className={styles.settings}>
          <div className="text-sm">Sync line items to Salesforce</div>
          <div>
            <ToggleSwitch
              label="Enable"
              isDisabled={!salesforceUser.PackageInstalledAt}
              value={connectorConfigValue?.syncLineItems}
              onChange={(checked: boolean) => {
                setConnectorConfigValue((prev) => ({
                  ...prev,
                  syncLineItems: checked,
                  configType: 'salesforceConfig',
                }));

                setSavableChanges(true);
              }}
              name="syncSignedDocument"
            />
          </div>
        </div>
      )}
      {connectorConfigValue?.syncLineItems && (
        <div className={styles.settings}>
          <div className={styles.toggleSwitchContainer}>
            <span>
              Create new products in Salesforce when creating products in
              Cacheflow.
            </span>
            <ToolTip
              title="All new products added in Cacheflow will be created in Salesforce and related to the Cacheflow price book.
            If this setting is disabled, all new products added in Cacheflow must be linked to existing products in
            Salesforce using the 'Link to CRM'."
            >
              <Icon.Information width={28} height={28} />
            </ToolTip>
          </div>
          <div>
            <ToggleSwitch
              label="Enable"
              isDisabled={!salesforceUser.PackageInstalledAt}
              value={connectorConfigValue.createProduct2}
              onChange={(checked: boolean) => {
                setConnectorConfigValue((prev) => ({
                  ...prev,
                  createProduct2: checked,
                  configType: 'salesforceConfig',
                }));
                setSavableChanges(true);
              }}
              name="syncSubscriptions"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SalesforceConnectionExtras;
