import React from 'react';
import clsx from 'clsx';
import {
  amountToPercentage,
  BillingPeriodsType,
  Button,
  formatDurationRanges,
  formatNumber,
  formatPercentageString,
  Icon,
  isNumber,
  Money,
  PricebookEntry,
  Product,
  ProductLevel,
  ScheduleLine,
  TieredRampingPeriodOverview,
  useTranslation,
} from 'common';
import styles from './TierAdjustmentScheduleOverview.module.scss';

interface Props {
  basePrices: {
    billingPeriodMoney: Money | undefined;
    initialPrice: Money | undefined;
    levelOverridePrice: Money | undefined;
  }[];
  billingPeriod: BillingPeriodsType;
  entries?: PricebookEntry[];
  mergedSchedule: ScheduleLine[];
  pricing: Product['pricing'];
  tiers?: ProductLevel[];
}

const TierAdjustmentScheduleOverview = ({
  basePrices,
  billingPeriod,
  entries,
  mergedSchedule,
  pricing,
  tiers,
}: Props) => {
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const [showLevelDetails, setShowLevelDetails] = React.useState<number | null>(
    null
  );
  const { tk } = useTranslation();

  const isGraduated = pricing === 'graduated';
  const isStairstep = pricing === 'stairstep';

  if (!entries || !tiers) {
    return null;
  }
  // show fewer for graduated because there is more info to show
  const MAX_SCHEDULE_ROWS_TO_DISPLAY = isGraduated ? 3 : 5;

  const checkIfDiscount = (
    price: Money | undefined,
    basePrice: Money | undefined
  ): boolean => {
    return !!(
      !!price &&
      isNumber(price.amount) &&
      basePrice &&
      isNumber(basePrice.amount) &&
      price.amount < basePrice.amount
    );
  };

  const linesToShow = showAll
    ? mergedSchedule
    : mergedSchedule.slice(0, MAX_SCHEDULE_ROWS_TO_DISPLAY);

  const getLevelQuantity = (
    tierStart: number,
    quantity: number,
    nextTier?: ProductLevel
  ) => {
    if (isGraduated) {
      // if there is only one tier to show, we just use the flat quantity
      if (tierStart === 0 && nextTier?.start && quantity < nextTier.start) {
        return formatNumber(quantity);
      }
      // if tierStart is 0, then it is the first tier, and we need to account for
      // the next tier being one more than the quantity of this level
      const tierStartValue = tierStart || 1;

      return nextTier && nextTier.start && nextTier.start <= quantity
        ? formatNumber(nextTier.start - tierStartValue)
        : formatNumber(quantity - (tierStartValue - 1) || 1); // when the level start and quantity are equal, we want to show 1
    }
    return formatNumber(quantity);
  };

  return (
    <div
      className={styles.tierAdjustmentScheduleOverview}
      data-testid="adjustment-schedule-overview"
    >
      {linesToShow.map((override, overrideIndex) => {
        return (
          <div
            key={override.startIndex}
            className={styles.timeBasedOverviewContentRow}
            data-testid={`adjustment-schedule-overview-row-${overrideIndex}`}
          >
            <p data-testid="duration-range">
              {formatDurationRanges(override, billingPeriod)}
            </p>
            <div className={styles.priceOverviewContainer}>
              <div className={styles.priceOverviewLevels}>
                {tiers.map((tier, index) => {
                  const basePrice = basePrices[index]?.billingPeriodMoney;

                  const level = override.levels?.find(
                    (lev) => lev.level === index
                  ) || { level: index, price: basePrice };
                  const nextTier = tiers[index + 1];
                  if (
                    isNumber(override.quantity) &&
                    isNumber(tier.start) &&
                    tier.start <= override.quantity
                  ) {
                    const shouldShowLevel = isGraduated
                      ? tier.start <= override.quantity
                      : tier.start <= override.quantity &&
                        (!nextTier ||
                          !isNumber(nextTier.start) ||
                          nextTier.start > override.quantity);
                    if (!shouldShowLevel) return null;
                    return (
                      <div
                        key={tier.start}
                        className={styles.priceOverview}
                        data-testid={`level-tier-${index}`}
                      >
                        {override.discount &&
                          basePrice &&
                          checkIfDiscount(level.price, basePrice) && (
                            <p
                              className={clsx(
                                styles.strikethrough,
                                styles.priceOverviewDiscount
                              )}
                              data-testid="range-base-price"
                            >
                              {basePrice.formattedAmount}
                            </p>
                          )}
                        <p data-testid="range-price">
                          {level.price?.formattedAmount}
                        </p>
                        {isNumber(override.quantity) && (
                          <p className={styles.qty} data-testid="range-qty">
                            {!isStairstep && 'x '}
                            <strong>
                              {getLevelQuantity(
                                tier.start,
                                override.quantity,
                                nextTier
                              )}{' '}
                            </strong>
                          </p>
                        )}
                        {override.discount &&
                          checkIfDiscount(level.price, basePrice) && (
                            <p
                              className={styles.percentOff}
                              data-testid="range-percent-off"
                            >
                              (
                              {formatPercentageString(
                                amountToPercentage(
                                  basePrice?.amount,
                                  level.price?.amount,
                                  false
                                )
                              )}
                              )
                            </p>
                          )}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
              <div
                className={clsx(
                  styles.showLevelDetails,
                  overrideIndex === showLevelDetails &&
                    styles.showLevelDetailsOpen
                )}
              >
                {overrideIndex === showLevelDetails ? (
                  <Icon.CaretUp
                    className={styles.showLevelDetailsOpen}
                    width={24}
                    height={24}
                    onClick={() => {
                      setShowLevelDetails(null);
                    }}
                    data-testid={`show-level-details-${overrideIndex}`}
                  />
                ) : (
                  <Icon.CaretDown
                    className={styles.showLevelDetailsClosed}
                    width={24}
                    height={24}
                    onClick={() => {
                      setShowLevelDetails(overrideIndex);
                    }}
                    data-testid={`hide-level-details-${overrideIndex}`}
                  />
                )}
              </div>
              {overrideIndex === showLevelDetails && (
                <TieredRampingPeriodOverview
                  levels={override.levels}
                  tiers={tiers}
                  entries={entries}
                  billingPeriod={billingPeriod}
                  basePrices={basePrices.map(
                    (basePrice) => basePrice.billingPeriodMoney
                  )}
                />
              )}
            </div>
          </div>
        );
      })}
      {mergedSchedule.length > MAX_SCHEDULE_ROWS_TO_DISPLAY && (
        <div className={clsx(styles.showAll, showAll && styles.showAllOpen)}>
          <Button
            onClick={() => setShowAll(!showAll)}
            type="link"
            iconBefore={showAll ? Icon.CaretUp : Icon.CaretDown}
            label={showAll ? tk('Show less') : tk('Show all')}
          />
        </div>
      )}
    </div>
  );
};

export default TierAdjustmentScheduleOverview;
