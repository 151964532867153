import React, { useState } from 'react';
import {
  captureError,
  Contract,
  DefaultZeroState,
  formatCurrencyOrDefault,
  formatDate,
  formatDatetimeOrDefault,
  getBillingPeriodLabelAdverb,
  Loading,
  SimpleCell,
  SimpleTable,
} from 'common';
import { useActiveUsageContracts } from '../../services/contract';
import BillingScheduleForm from './BillingScheduleForm';
import {
  fixedTotal,
  getCurrentUsageMap,
  getLastUpdatedUsage,
  getProductIdsForContractsBilling,
  isStaleContract,
  usageTotal,
} from './billingUtilities';
import './billing.css';
import { useBatchProducts } from '../../services/api/products/products';
import { useQueryClient } from '@tanstack/react-query';
import Page from '../../components/Page';
import { useFindOrgDefault } from '../../services/orgDefaults';

const BillingScheduleList = () => {
  const [contractBeingEdited, setContractBeingEdited] = useState<
    Contract | undefined
  >();
  const [idsOfRecentlyUpdated, setIdsOfRecentlyUpdated] = useState<string[]>(
    []
  );
  const queryClient = useQueryClient();

  const {
    data: contracts,
    isPending: isLoadingContracts,
    isError: errorLoadingContracts,
  } = useActiveUsageContracts();

  const { data: staleUsagePeriod } = useFindOrgDefault('staleUsagePeriod');

  const productIdsToFetch: string[] =
    getProductIdsForContractsBilling(contracts);
  const hasProductsToFetch = !!contracts && productIdsToFetch.length > 0;

  const {
    data: products,
    isLoading: isLoadingProducts,
    isError: errorLoadingProducts,
  } = useBatchProducts(queryClient, productIdsToFetch, hasProductsToFetch);

  if (isLoadingContracts || (hasProductsToFetch && isLoadingProducts)) {
    return <Loading />;
  }

  if (errorLoadingContracts || errorLoadingProducts) {
    return <div>Error loading</div>;
  }

  const currentUsageMap = getCurrentUsageMap(contracts);

  const contractsOfInterest = contracts
    .filter((c) => currentUsageMap.get(c.id))
    // TODO: Replace with compareChronological or compareReverseChronological
    .sort(
      (a, b) =>
        new Date(currentUsageMap.get(a.id)?.scheduleDate ?? '').getTime() -
        new Date(currentUsageMap.get(b.id)?.scheduleDate ?? '').getTime()
    );

  const urgentContractIds = new Set<string>();
  if (staleUsagePeriod) {
    if (staleUsagePeriod.intValue === undefined) {
      captureError(new Error('staleUsagePeriod.intValue is undefined'));
    }
    const staleUsagePeriodValue = staleUsagePeriod.intValue || 3;
    contractsOfInterest.forEach((contract) => {
      if (isStaleContract(contract, staleUsagePeriodValue, currentUsageMap)) {
        urgentContractIds.add(contract.id);
      }
    });
  }

  const getCurrentUsageScheduleAmount = (contract: Contract) => {
    const currentUsageSchedule = currentUsageMap.get(contract.id);
    if (currentUsageSchedule) {
      return currentUsageSchedule.billingItems
        .map((item) => item.amountDue)
        .reduce((partialSum, a) => partialSum + a, 0);
    }
    return 0;
  };

  const columns: SimpleCell<Contract>[] = [
    {
      key: 'customerName',
      headerLabel: ['Customer', 'Billing email'],
      renderCell: (contract: Contract) => (
        <div>
          <div>{contract.buyer.name || '-'}</div>
          <div className="text-xs text">
            {contract.buyer.billingContact?.email || '-'}
          </div>
        </div>
      ),
      width: '30%',
      overflowCell: true,
      sortable: true,
      sortValue: (contract: Contract) => contract.buyer.name,
    },
    {
      key: 'nextBillDateSchedule',
      headerLabel: ['Next usage bill date', 'Schedule'],
      renderCell: (contract: Contract) => {
        const nextUsageSchedule = currentUsageMap.get(contract.id);
        const scheduleDate = nextUsageSchedule?.scheduleDate;

        return (
          <div>
            <div>{scheduleDate && <>{formatDate(scheduleDate)}</>}</div>
            <div className="text-xs">
              {contract.billingMethod?.paymentMethod.name}{' '}
              {getBillingPeriodLabelAdverb(contract.billingPeriod)}
            </div>
          </div>
        );
      },
      width: '20%',
      overflowCell: true,
      sortable: true,
      sortValue: (contract: Contract) =>
        currentUsageMap.get(contract.id)?.scheduleDate,
    },
    {
      key: 'fixed',
      headerLabel: 'Fixed',
      renderCell: (contract: Contract) =>
        formatCurrencyOrDefault(
          contract.currency,
          fixedTotal(currentUsageMap.get(contract.id), products)
        ),
    },
    {
      key: 'usage',
      headerLabel: 'Usage',
      renderCell: (contract: Contract) =>
        formatCurrencyOrDefault(
          contract.currency,
          usageTotal(currentUsageMap.get(contract.id), products)
        ),
    },
    {
      key: 'amount',
      headerLabel: 'Total amount',
      renderCell: (contract: Contract) =>
        formatCurrencyOrDefault(
          contract.currency,
          getCurrentUsageScheduleAmount(contract)
        ),
      sortable: true,
      sortValue: (contract: Contract) =>
        getCurrentUsageScheduleAmount(contract) || 0,
    },
    {
      key: 'lastUpdated',
      headerLabel: 'Last updated',
      width: '20%',
      overflowCell: true,
      renderCell: (contract: Contract) => {
        const lastUpdated = getLastUpdatedUsage(
          currentUsageMap.get(contract.id)
        );
        return (
          <div>
            {lastUpdated && (
              <>
                <div>{formatDatetimeOrDefault(lastUpdated.createdAt)}</div>
                <div className="text-xs">{lastUpdated.createdBy}</div>
              </>
            )}
          </div>
        );
      },
      sortable: true,
      sortValue: (contract: Contract) => {
        const lastUpdated = getLastUpdatedUsage(
          currentUsageMap.get(contract.id)
        );
        return lastUpdated?.createdAt || '';
      },
    },
  ];

  const getRowClassName = (row: Contract) => {
    if (idsOfRecentlyUpdated.includes(row.id)) {
      return 'recently-updated-contract-row';
    }
    if (urgentContractIds.has(row.id)) {
      return 'urgent-contract-row';
    }
    return undefined;
  };

  const handleSaveSuccess = () => {
    const contractId = contractBeingEdited?.id;

    if (!contractId) {
      return;
    }

    setIdsOfRecentlyUpdated((prev) => [...prev, contractId]);
    setTimeout(() => {
      setIdsOfRecentlyUpdated((prev) => prev.filter((id) => id !== contractId));
    }, 300);
    setContractBeingEdited(undefined);
  };

  return (
    <Page leftWidget="Usage billing updates">
      <BillingScheduleForm
        billingScheduleId={
          contractBeingEdited
            ? currentUsageMap.get(contractBeingEdited.id)?.id
            : undefined
        }
        contract={contractBeingEdited}
        isOpen={!!contractBeingEdited}
        onClose={() => setContractBeingEdited(undefined)}
        onSaveSuccess={handleSaveSuccess}
      />

      <SimpleTable<Contract>
        cells={columns}
        dataTestId="usage-billing-table"
        disableSearch
        getRowClassName={getRowClassName}
        isLoading={isLoadingContracts}
        minWidth="960px"
        onRowClick={(c) => setContractBeingEdited(c)}
        rows={contractsOfInterest}
        zeroState={
          <DefaultZeroState
            dataTestId="no-usage-billing"
            message="There are no billing updates available to view."
          />
        }
      />
    </Page>
  );
};

export default BillingScheduleList;
