import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ErrorFetching, Icon, Loading } from 'common';
import { useTransaction } from '../../services/api/transactions/transactionsList';
import { Page } from '../../components';
import IDField from '../../components/IDField';
import { useInvoice } from '../../services/api/invoices/invoiceList';
import styles from './PaymentDetailPage.module.scss';
import { PAYMENTS } from '../../core-utils/routes';
import { PaymentSummaryCard } from './Details/PaymentSummaryCard';
import { PaymentInvoicesCard } from './Details/PaymentInvoicesCard';
import { PaymentActionsHeader } from './Details/PaymentActionsHeader';
import TransactionDialog from '../Transactions/TransactionDialog';
import { getDetailLink } from 'app/src/services/routes/routeUtils';

export const PaymentDetailPage: React.FC = () => {
  const { id: transactionId } = useParams<{ id: string }>();
  const { data: payment } = useTransaction(transactionId ?? '');
  const {
    data: invoice,
    isLoading: invoiceLoading,
    isError: invoiceError,
  } = useInvoice(payment?.parentReferenceId ?? '');
  const [paymentDialogState, setPaymentDialogState] = useState(false);
  const [detailLink, setDetailLink] = useState<string | undefined>();

  const isLoading =
    !transactionId || !payment?.parentReferenceId ? true : invoiceLoading;
  const isError = !transactionId || invoiceError;

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorFetching />;
  }

  const openTransactionDialog = () => {
    if (payment?.parentReferenceId && payment.parentReferenceType) {
      const link = getDetailLink(
        payment.parentReferenceType,
        payment.parentReferenceId
      );
      if (link) {
        setDetailLink(link);
      }
      setPaymentDialogState(true);
    }
  };

  return (
    <Page
      className={styles.paymentsDetailPage}
      leftWidget={
        <div className={styles.headerContainer}>
          <Link to={PAYMENTS}>
            <Icon.CurrencyUsd />
          </Link>
          <div className={styles.rightHeader}>
            <div>{invoice?.header.customer.name ?? 'Payment Details'}</div>
            <div className={styles.subtext}>
              <IDField documentID={payment?.id}>
                <div>{payment?.id?.substring(0, 8) ?? '-'}</div>
              </IDField>
            </div>
          </div>
        </div>
      }
      rightWidget={
        <PaymentActionsHeader
          openDialog={openTransactionDialog}
          payment={payment}
        />
      }
    >
      {payment && invoice && (
        <PaymentSummaryCard
          customer={invoice.header.customer}
          payment={payment}
        />
      )}
      {invoice && <PaymentInvoicesCard invoices={[invoice]} />}
      {paymentDialogState && (
        <TransactionDialog
          detailLink={detailLink ?? 'na'}
          isOpen={paymentDialogState}
          setOpenState={setPaymentDialogState}
          transactionId={transactionId}
        />
      )}
    </Page>
  );
};
