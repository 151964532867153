import { Icon, SyncEvent, ToolTip, useFlags } from 'common';
import React from 'react';
import CloseSVG from '../assets/integration-logos/CloseSVG';
import HubspotSVG from '../assets/integration-logos/hubspotSVG';
import SalesforceLogoSVG from '../assets/integration-logos/salesforceLogoSVG';
import styles from './ExternalEntityLink.module.scss';

import {
  ConnectableEntity,
  getCRMExternalSource,
} from '../core-utils/helperFunctions/types';
import QuickbooksSVG from '../assets/integration-logos/QuickbooksSVG';

interface Props {
  entity: ConnectableEntity;
  showId?: boolean;
  lastSyncEvent?: SyncEvent;
}

const ExternalEntityLink: React.FC<Props> = ({
  entity,
  showId = false,
  lastSyncEvent,
}) => {
  const { salesforceConnector, hubspotConnector, closeConnector, quickbooks } =
    useFlags();

  if (
    !salesforceConnector &&
    !hubspotConnector &&
    !closeConnector &&
    !quickbooks
  ) {
    return null;
  }

  const externalSource = getCRMExternalSource(entity);

  if (!externalSource && !lastSyncEvent) {
    return null;
  }

  const getLinkId = () => {
    if (externalSource?.sourceType === 'close' && externalSource.sourceId) {
      // the close id is super long so just take the last 12 characters
      return externalSource.sourceId.slice(-12);
    }
    return externalSource?.sourceId;
  };

  return (
    <div className={styles.container}>
      <a
        href={externalSource?.sourceLink}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        {showId && <div>{getLinkId()}</div>}
        {externalSource?.sourceType === 'salesforce' && (
          <SalesforceLogoSVG height={24} width={24} />
        )}
        {externalSource?.sourceType === 'hubspot' && (
          <HubspotSVG height={24} width={24} />
        )}
        {externalSource?.sourceType === 'close' && (
          <CloseSVG height={24} width={24} />
        )}
        {(externalSource?.sourceType === 'quickbooks' || lastSyncEvent) && (
          <div>
            <div>
              <QuickbooksSVG height={24} width={24} />
            </div>
            <div>
              {lastSyncEvent?.error && (
                <ToolTip
                  title={lastSyncEvent.error}
                  customStyling={{
                    width: '100%',
                  }}
                >
                  <Icon.Alert width={27} className={styles.icon} />
                </ToolTip>
              )}
            </div>
          </div>
        )}
      </a>
    </div>
  );
};

export default ExternalEntityLink;
