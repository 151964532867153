import React from 'react';
import { Button, CardRowValue, Icon } from 'common';
import clsx from 'clsx';

interface POLabel {
  isEditDisabled?: boolean;
  label: string;
  poDisplayValue: string;
  setEditingPO: React.Dispatch<React.SetStateAction<boolean>>;
}

const POLabelWithValue: React.FC<POLabel> = ({
  label,
  poDisplayValue,
  setEditingPO,
  isEditDisabled,
}) => (
  <CardRowValue alignLabel="center" label={label}>
    <div className="flex items-center gap-2">
      {poDisplayValue && <div>{poDisplayValue}</div>}
      <Button
        label="Edit"
        className={clsx({
          invisible: isEditDisabled,
        })}
        iconBefore={Icon.Edit}
        onClick={() => setEditingPO(true)}
        type="secondary"
        isDisabled={isEditDisabled}
      />
    </div>
  </CardRowValue>
);

export default POLabelWithValue;
