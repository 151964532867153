import React, { useState } from 'react';
import {
  Cell,
  formatNullableFormattedAmount,
  getIconCell,
  getOrDefault,
  InvoiceSummary,
  InvoiceWithSyncSummaryRest,
  NavigationIcon,
  pushIf,
  QueryTable,
} from 'common';
import { useNavigate } from 'react-router-dom';
import DateTimeCell from 'app/src/components/DateTimeCell';
import StatusLabel from 'app/src/components/StatusLabel';
import { apiClient } from '../../services/httpClients/app';
import { ALL_INVOICES_KEY } from '../../services/api/invoices/invoiceList';
import InvoiceOptionsButton from './ActionBar/InvoiceOptionsButton';
import {
  useIsCurrentUserAdmin,
  useIsSuperAdmin,
} from '../../core-utils/helperFunctions/userServiceHelper';
import InvoiceVoidConfirmationModal from './InvoiceVoidConfirmationModal';
import ExternalEntityLink from 'app/src/components/ExternalEntityLink';
import { ConnectableEntity } from 'app/src/core-utils/helperFunctions/types';
import { useBillingSchedule } from 'app/src/services/billing';
import { InvoicePaymentUpdateDrawer } from '../Subscriptions/SubscriptionDetail/PaymentSchedule/PaymentUpdateForm/InvoicePaymentUpdateDrawer';

interface Props
  extends Pick<
    React.ComponentProps<typeof QueryTable>,
    'baseSearchValues' | 'filters'
  > {
  excludeCols?: string[];
  isQuickBooksEnabled?: boolean;
}

const InvoiceTable: React.FC<Props> = ({
  excludeCols,
  filters,
  isQuickBooksEnabled,
  ...passDownProps
}) => {
  const navigate = useNavigate();
  const isSuperAdmin = useIsSuperAdmin();
  const isAdminRole = useIsCurrentUserAdmin();
  const [isConfirmUnvoidModalOpen, setIsConfirmUnvoidModalOpen] =
    useState<boolean>(false);

  const [selectedInvoiceCustomer, setSelectedInvoiceCustomer] = useState<
    string | undefined
  >();

  const [billingScheduleId, setBillingScheduleId] = useState<
    string | undefined
  >();

  const { data: schedule, isLoading } = useBillingSchedule(billingScheduleId);

  const handleRowClick = (row: InvoiceSummary) => {
    navigate(`/invoices/${row.id}`);
  };

  const cells: Cell<InvoiceSummary>[] = [
    getIconCell(NavigationIcon.Agreements),
    {
      key: 'invoiceNumber',
      headerLabel: 'Invoice no.',
      searchable: true,
      width: '15%',
      sortable: true,
      allowWrap: true,
      renderCell: (invoice: InvoiceSummary) => (
        <div className="font-semibold truncate">
          {getOrDefault(invoice.invoiceNumber)}
        </div>
      ),
    },
    {
      key: 'customer.name',
      headerLabel: 'Customer',
      searchable: true,
      sortable: true,
      allowWrap: true,
      width: '15%',
      renderCell: (invoice: InvoiceSummary) => (
        <div className="font-semibold truncate">
          {getOrDefault(invoice.customerName)}
        </div>
      ),
    },
    {
      key: 'amount',
      headerLabel: 'Amount',
      width: '110px',
      sortable: true,
      renderCell: (invoice: InvoiceSummary) =>
        formatNullableFormattedAmount(invoice.amountFormatted),
    },
    {
      key: 'balance',
      headerLabel: 'Balance',
      width: '110px',
      sortable: true,
      renderCell: (invoice: InvoiceSummary) =>
        formatNullableFormattedAmount(invoice.balanceFormatted),
    },
    {
      key: 'schedule.invoiceDate',
      headerLabel: 'Invoice date',
      width: '110px',
      sortable: true,
      renderCell: (invoice: InvoiceSummary) => (
        <DateTimeCell date={invoice.invoiceDate} showTime={false} />
      ),
    },
    {
      key: 'schedule.dueDate',
      headerLabel: 'Due date',
      width: '110px',
      sortable: true,
      renderCell: (invoice: InvoiceSummary) => (
        <DateTimeCell date={invoice.dueDate} showTime={false} />
      ),
    },
    ...pushIf(!!isQuickBooksEnabled, {
      key: 'syncStatus',
      headerLabel: 'Sync status',
      width: '75px',
      renderCell: (invoice: InvoiceWithSyncSummaryRest) => (
        <div className="flex justify-start">
          <ExternalEntityLink
            entity={
              {
                externalId: invoice.sourceDisplayId,
                externalLink: invoice.externalLink,
                source: invoice.sourceType,
              } as ConnectableEntity
            }
            lastSyncEvent={invoice.lastSyncEvent}
          />
        </div>
      ),
    }),
    {
      key: 'displayStatus',
      headerLabel: 'Status',
      width: '120px',
      align: 'center' as Cell<InvoiceSummary>['align'],
      searchable: true,
      renderCell: (invoice: InvoiceSummary) => (
        <StatusLabel feature="invoice" status={invoice.status} />
      ),
    },
    ...pushIf(isAdminRole || isSuperAdmin, {
      key: 'options',
      headerLabel: 'Options',
      clickable: true,
      width: 64,
      renderCell: (data: InvoiceWithSyncSummaryRest) => (
        <div className="flex gap-2 items-center">
          <InvoiceOptionsButton
            setBillingScheduleId={setBillingScheduleId}
            borderless={false}
            invoice={data}
            sourceId={data.sourceDisplayId}
            scheduleId={data.scheduleId}
            subscriptionId={data.subscriptionId}
            setIsConfirmUnvoidModalOpen={setIsConfirmUnvoidModalOpen}
            setSelectedInvoiceCustomer={setSelectedInvoiceCustomer}
          />
        </div>
      ),
    }),
  ].filter((cell) => !excludeCols?.includes(cell.key));

  return (
    <>
      <InvoicePaymentUpdateDrawer
        isOpen={!!billingScheduleId && !isLoading}
        onClose={() => setBillingScheduleId(undefined)}
        selectedPayment={schedule}
        customerName={selectedInvoiceCustomer}
      />
      <InvoiceVoidConfirmationModal
        isModalOpen={isConfirmUnvoidModalOpen}
        setIsModalOpen={setIsConfirmUnvoidModalOpen}
      />
      <QueryTable<InvoiceSummary>
        {...passDownProps}
        isLoading={!!billingScheduleId && isLoading}
        queryKey={ALL_INVOICES_KEY}
        cells={cells}
        fetchList={apiClient.listInvoices}
        filters={filters}
        onRowClick={handleRowClick}
        zeroStateMessage="There are currently no invoices."
      />
    </>
  );
};

export default InvoiceTable;
