import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  ErrorFetching,
  formatDatetime,
  Loading,
  Notification,
  SimpleCell,
  SimpleTable,
  useToast,
} from 'common';
import Page from '../../../components/Page';
import { useGetEmailsSent, useResendEmail } from '../../../services/emails';
import NotificationErrorMessage from './NotificationErrorMessage';

const EmailHistory: React.FC = () => {
  const showToast = useToast();
  const qc = useQueryClient();
  const { data, isLoading, isError } = useGetEmailsSent();

  const { mutate } = useResendEmail(
    (resent: Notification) =>
      resent.success
        ? showToast.info('Resent successfully')
        : showToast.error('Failed to send again!'),
    () => showToast.error('Failed to resend'),
    qc
  );

  const resendEmail = (emailSentId: string) => {
    mutate({ emailSentId });
  };

  const cells: SimpleCell<Notification>[] = [
    {
      key: 'orgName',
      headerLabel: 'Org',
      width: '20%',
      searchable: true,
      sortable: true,
      renderCell: (row: Notification) => <div>{row.orgName}</div>,
    },
    {
      key: 'subject',
      headerLabel: 'Subject',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (row: Notification) => <div>{row.subject}</div>,
    },
    {
      key: 'recipients',
      headerLabel: 'Recipients',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (row: Notification) => <div>{row.recipients.join(', ')}</div>,
    },
    {
      key: 'emailKey',
      headerLabel: 'Email key',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (row: Notification) => <div>{row.emailKey}</div>,
    },
    {
      key: 'processed',
      headerLabel: 'Proccessed at',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (row: Notification) => (
        <div>{formatDatetime(row.processedAt)}</div>
      ),
    },
    {
      key: 'success',
      headerLabel: 'Success',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (row: Notification) => (
        <div>{row.success ? 'Yes' : 'No'}</div>
      ),
    },
    {
      key: 'error',
      headerLabel: 'Error',
      width: '10%',
      renderCell: (row: Notification) =>
        row.errorMessage ? (
          <NotificationErrorMessage errorMessage={row.errorMessage} />
        ) : (
          <div>-</div>
        ),
    },
    {
      key: 'resend',
      headerLabel: 'Resend',
      width: '10%',
      searchable: true,
      sortable: true,
      renderCell: (row: Notification) => (
        <div>
          <Button onClick={() => resendEmail(row.id)} label="Resend" />
        </div>
      ),
    },
  ];

  if (isError) {
    return <ErrorFetching />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page>
      <SimpleTable<Notification>
        cells={cells}
        rows={data}
        isLoading={isLoading}
        zeroStateMessage="There are currently no emails to display."
      />
    </Page>
  );
};

export default EmailHistory;
