import { ConnectorInfo, OrgConfig } from 'common';

const ZAPIER: string = '';
const ZAPIER_CONFIG: OrgConfig['configKey'] = 'zapierConfig';
const ZAPIER_SOURCE: ConnectorInfo['source'] = 'zapier';

export const ZAPIER_INFO = {
  configType: ZAPIER_CONFIG,
  source: ZAPIER_SOURCE,
  name: ZAPIER,
  showSaveButton: true,
};

export const accountContactsConfig = {
  integration: 'zapierConfig',
  description: 'The ZAPIER connector',
  cacheflowToIntegrationSection: [],
  integrationToCacheflowSection: [],
};
