import React, { useState } from 'react';

import {
  Card,
  CardHeader,
  Contract,
  CustomizeTieredPriceModal,
  getLastAcceptedProposal,
  isProductTiered,
  Item,
  ItemRow,
  ItemSchedule,
  ProductGrid,
  Proposal,
} from 'common';

type ModalDefaults =
  | {
      isOpen: false;
    }
  | {
      isOpen: true;
      item: Item;
      selectedSchedule: ItemSchedule;
    };

const priceModalDefaults: ModalDefaults = {
  isOpen: false,
};

interface Props {
  contract: Contract;
  contractProposals?: Proposal[];
}

export const ProductsCard = ({ contract, contractProposals }: Props) => {
  const [priceModal, setPriceModal] =
    useState<ModalDefaults>(priceModalDefaults);

  const handleShowPriceModalClick = (
    selectedSchedule: ItemSchedule,
    item: Item
  ) => {
    setPriceModal({
      isOpen: true,
      selectedSchedule,
      item,
    });
  };

  const { billingPeriod } = contract;
  const lastAcceptedProposal = getLastAcceptedProposal(contractProposals);

  if (!lastAcceptedProposal) return null;

  return (
    <Card>
      <CardHeader name="Products" />
      <ProductGrid>
        {lastAcceptedProposal.proposalItems.map((item) => (
          <ItemRow
            billingPeriod={billingPeriod}
            isDisabled
            item={item}
            key={item.id}
            onShowPriceModalClick={handleShowPriceModalClick}
            proposal={lastAcceptedProposal}
          />
        ))}
      </ProductGrid>

      {priceModal.isOpen && isProductTiered(priceModal.item.product) && (
        <CustomizeTieredPriceModal
          closeModal={() => setPriceModal(priceModalDefaults)}
          isOpen={priceModal.isOpen}
          isViewOnlyMode
          selectedItem={priceModal.item}
          selectedSchedule={priceModal.selectedSchedule}
        />
      )}
    </Card>
  );
};
