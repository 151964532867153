import React from 'react';
import clsx from 'clsx';

import { formatHumanReadable, useTranslation } from 'common';

import styles from './StatusLabel.module.scss';

interface Props {
  classNames?: string;
  displayStatus?: string;
  feature?: string;
  status?: string;
}

const StatusLabel: React.FC<Props> = ({
  feature,
  status,
  displayStatus,
  classNames,
}: Props) => {
  const { tk } = useTranslation();

  const getStatus = () => {
    if (displayStatus === 'Archived') {
      return status;
    }
    if (status === 'in_progress') return 'in progress';
    return (
      (displayStatus && tk(`status.${displayStatus}`)) ||
      tk(`${feature}.status.${status}`, formatHumanReadable(status)) ||
      '-'
    );
  };

  const getColor = () => {
    const statusValue = status;

    switch (feature) {
      case 'document':
        switch (statusValue) {
          case 'active':
            return 'text-green bg-green-lightest';
          case 'inactive':
            return 'text-black bg-slate-lighter';
          default:
            return '';
        }
      case 'product':
        switch (statusValue) {
          case 'active':
          case 'success':
            return 'text-green bg-green-lightest';
          case 'inactive':
          case 'error':
            return 'text-red bg-red-lighter';
          default:
            return '';
        }
      case 'plan':
        switch (statusValue) {
          case 'active':
            return 'text-green bg-green-lightest';
          case 'inactive':
            return 'text-red bg-red-lighter';
          default:
            return '';
        }
      case 'organization':
        switch (statusValue) {
          case 'pending':
            return 'text-black bg-slate-lighter';
          case 'open':
            return 'text-orange bg-orange-lightest';
          case 'in_progress':
            return 'text-black bg-slate-lighter';
          case 'closed':
          case 'error':
            return 'text-red bg-red-lighter';
          case 'rejected':
            return 'text-purple bg-purple-lightest';
          case 'active':
          case 'success':
            return 'text-green bg-green-lightest';
          default:
            return '';
        }
      case 'transaction':
        switch (statusValue) {
          case 'in_progress':
          case 'pending':
            return 'text-black bg-slate-lighter';
          case 'success':
          case 'posted':
            return 'text-green bg-green-lightest';
          case 'initiated':
            return 'text-black bg-slate-lighter';
          case 'failed':
          case 'declined':
            return 'text-purple bg-purple-lightest';
          case 'cancelled':
          case 'reversed':
            return 'text-red bg-red-lighter';
          default:
            return '';
        }

      case 'invoice':
        switch (statusValue) {
          case 'draft':
            return 'text-black bg-gray-lines';
          case 'open':
            return 'text-black bg-slate-lighter';
          case 'paid':
          case 'success':
            return 'text-green bg-green-lightest';
          case 'failed':
          case 'past_due':
            return 'text-purple bg-purple-lightest';
          case 'voided':
            return 'text-red bg-red-lightest';
          case 'cancelled':
          case 'error':
            return 'text-red bg-red-lighter';
          default:
            return '';
        }

      case 'proposal':
        // override signed - awaiting payment
        // the proposal does not contain contract payment info in the case of acceptOnSigning being enabled
        if (displayStatus === 'Signed - awaiting payment') {
          return 'text-orange bg-orange-lightest';
        }
        // if proposal status is not draft, expired, cancelled, or accepted, we default to active styles
        switch (statusValue) {
          case 'draft':
            return 'text-black bg-gray-lines';
          case 'expired':
            return 'text-purple bg-purple-lightest';
          case 'cancelled':
            return 'text-red-darker bg-red-lightest';
          case 'pending_renewal':
          case 'accepted':
          case 'success':
            return 'text-green bg-green-lightest';
          case 'error':
            return 'text-red bg-red-lighter';
          default:
            return 'text-orange bg-orange-lightest';
        }
      case 'billingschedule':
        switch (statusValue) {
          case 'pending':
            return 'text-black bg-slate-lighter';
          case 'paid':
          case 'success':
            return 'text-green bg-green-lightest';
          case 'error':
            return 'text-red bg-red-lighter';
          default:
            return '';
        }

      case 'contract':
        switch (statusValue) {
          case 'active':
          case 'success':
            return 'text-green bg-green-lightest';
          case 'overdue':
            return 'text-purple bg-purple-lightest';
          case 'cancelled':
          case 'error':
            return 'text-red bg-red-lighter';
          case 'ended':
            return 'text-black bg-slate-lighter';
          default:
            return '';
        }
      case 'customer':
        switch (statusValue) {
          case 'success':
            return 'text-green bg-green-lightest';
          case 'error':
            return 'text-red bg-red-lighter';
          default:
            return '';
        }
      case 'contact':
        switch (statusValue) {
          case 'success':
            return 'text-green bg-green-lightest';
          case 'error':
            return 'text-red bg-red-lighter';
          default:
            return '';
        }
      case 'paymentMethod':
        switch (statusValue) {
          case 'success':
            return 'text-green bg-green-lightest';
          case 'error':
            return 'text-red bg-red-lighter';
          default:
            return '';
        }
      case 'transfer':
        switch (statusValue) {
          case 'success':
            return 'text-green bg-green-lightest';
          case 'error':
            return 'text-red bg-red-lighter';
          default:
            return '';
        }
      case 'proposalitem':
        switch (statusValue) {
          case 'success':
            return 'text-green bg-green-lightest';
          case 'error':
            return 'text-red bg-red-lighter';
          default:
            return '';
        }
      case 'refund':
        switch (statusValue) {
          case 'pending':
            return 'text-orange bg-orange-lightest';
          case 'in_progress':
            return 'text-blue bg-blue-lightest';
          case 'posted':
            return 'text-green bg-green-lightest';
          case 'failed':
          case 'cancelled':
          case 'declined':
            return 'text-red bg-red-lightest';
          case 'reversed':
            return 'text-purple bg-purple-lightest';
          default:
            return '';
        }
      default:
        switch (statusValue) {
          case 'success':
            return 'text-green bg-green-lightest';
          case 'error':
            return 'text-red bg-red-lighter';
          default:
            return '';
        }
    }
  };

  return (
    <div className={styles.statusLabel}>
      <div
        className={clsx(styles.statusBody, getColor(), classNames)}
        data-testid="label-status"
      >
        {getStatus()}
      </div>
    </div>
  );
};

export default StatusLabel;
