import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  Organization,
  OrgConfig,
  OrgResetRequest,
  ProvisionOrganizationRequest,
  SystemEvent,
} from 'common';
import { systemClient } from '../../httpClients/systemclient';

const ALL_ORGANIZATIONS_KEY = 'ORGANIZATIONS';

// poor man's OrganizationView
interface OrganizationProfile {
  configs?: OrgConfig[];
  migrationJobs?: SystemEvent[];
  options?: Record<string, boolean>;
  org: Organization;
  scheduleJobs?: SystemEvent[];
}

const submitOrganization = async (org: Organization) => {
  const { data } = await systemClient.createOrg(org);
  return data;
};

const organizationsGet = async () => {
  const { data } = await systemClient.listSystemOrgs();
  return data;
};

export const authOrgPost = async (id: string, reason?: string) => {
  const { data } = await systemClient.createSystemAuth({
    orgId: id,
    reason,
  });
  return data;
};

export const useOrganization = (
  id: string,
  enabled?: boolean
): UseQueryResult<OrganizationProfile> =>
  useQuery({
    queryKey: [ALL_ORGANIZATIONS_KEY, id],
    queryFn: async () => {
      if (!id) return {} as OrganizationProfile;
      const { data } = await systemClient.getSystemOrgView(id);
      return data as OrganizationProfile;
    },
    enabled,
  });

const updateOrganizationOption = async (
  id: string,
  request: ProvisionOrganizationRequest
) => {
  const { data } = await systemClient.updateOrgOptions(id, request);
  return data;
};

export const useUpdateOrganizationOption = (
  orgId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [ALL_ORGANIZATIONS_KEY],
    mutationFn: async (request: ProvisionOrganizationRequest) =>
      updateOrganizationOption(orgId, request),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_ORGANIZATIONS_KEY] });
    },
  });

export const useOrganizations = (
  enabled?: boolean
): UseQueryResult<Organization[]> =>
  useQuery({
    queryKey: [ALL_ORGANIZATIONS_KEY],
    queryFn: organizationsGet,
    enabled,
  });

export const useSubmitOrganization = (
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [ALL_ORGANIZATIONS_KEY],
    mutationFn: submitOrganization,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_ORGANIZATIONS_KEY] });
    },
  });

const updateOrganization = async (id: string, request: Organization) => {
  const { data } = await systemClient.updateSystemOrg(id, request);
  return data;
};

export const useUpdateOrganizationAPI = (
  id: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [ALL_ORGANIZATIONS_KEY],
    mutationFn: (request: Organization) => updateOrganization(id, request),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_ORGANIZATIONS_KEY] });
    },
  });

const resetOrganization = async (id: string, request: OrgResetRequest) => {
  const { data } = await systemClient.resetOrg(id, request);
  return data;
};

export const useResetOrganizationAPI = (
  orgId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [ALL_ORGANIZATIONS_KEY],
    mutationFn: (request: OrgResetRequest) => resetOrganization(orgId, request),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ALL_ORGANIZATIONS_KEY] });
    },
  });
