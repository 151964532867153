import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  useGetProposal,
  useGetProposalApprovalRequests,
  useUpdateProposal,
  useUpdateSigningDocument,
} from '../../../services/proposal';
import {
  CreateProposalRequest,
  formatHumanReadable,
  getDatetime,
  getErrorStatus,
  hasAnAcceptedProposalStatus,
  isApprovedProposalStatus,
  isArrayNonEmpty,
  isDraftProposalStatus,
  isScheduleSelectionProposalStatus,
  isTemplateProposalType,
  Loading,
  Nullable,
  Proposal,
  useToast,
} from 'common';
import { getIsSignatureRequired } from 'checkout/src/utils/workflow';
import { getUserFacingMessage } from '../../../services/utilities';
import ProposalLoadError from '../ProposalLoadError';
import ProposalEditor from './ProposalEditor';
import ShareProposalPage from './ProposalShare/ShareProposalPage';
import { getPublishStatus } from './proposalUtilities';
import {
  FLAG_TYPES,
  isActionAllowed,
  useIsCurrentUserAdmin,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';

const ProposalLoader: React.FC = () => {
  const { id: proposalId } = useParams<{ id: string }>();

  if (!proposalId) {
    return <ProposalLoadError />;
  }

  const [proposalKey, setProposalKey] = useState<string>(proposalId);
  const [isSharing, setIsSharing] = useState<boolean>(false);
  const showToast = useToast();
  const queryClient = useQueryClient();
  const canSign =
    isActionAllowed(FLAG_TYPES.PROPOSAL, 'sign') || useIsCurrentUserAdmin();

  const {
    data: proposal,
    isLoading: isProposalLoading,
    error,
    refetch,
  } = useGetProposal(proposalId);

  const handleUpdateError = async (responseError: unknown) => {
    showToast.error(
      getUserFacingMessage(responseError) || 'Proposal failed to save'
    );
    // go back to a known good state on error
    setProposalKey(getDatetime());
    await refetch();
  };

  const handleUpdateSuccess = (updatedProposal: Proposal) => {
    if (proposal) {
      // status change is worthy of a toast
      const prefix = isTemplateProposalType(updatedProposal)
        ? 'Template'
        : 'Proposal';

      if (proposal.displayStatus !== updatedProposal.displayStatus) {
        showToast.info(
          `${prefix} status is now: ${formatHumanReadable(
            updatedProposal.displayStatus
          )}`
        );
      }
    }
  };

  const { mutate: updateSigningDocument, isIdle: isSigningApiIdle } =
    useUpdateSigningDocument(
      proposalId,
      handleUpdateSuccess,
      handleUpdateError,
      queryClient
    );

  if (
    proposal?.signingDocument &&
    isScheduleSelectionProposalStatus(proposal) &&
    getIsSignatureRequired(proposal) &&
    isSigningApiIdle &&
    canSign
  ) {
    updateSigningDocument({ isPolling: true });
  }

  const { mutate: updateProposal, isPending: isLoading } = useUpdateProposal(
    proposalId,
    { onSuccess: handleUpdateSuccess, onError: handleUpdateError }
  );

  const onRecipientsChange = async (contactIds: string[]) => {
    if (!proposal) return;
    const updateRequest: CreateProposalRequest = { contactIds };
    const noRecipientsLeft: boolean = !contactIds.length;

    if (
      noRecipientsLeft &&
      !hasAnAcceptedProposalStatus(proposal) &&
      proposal.id
    ) {
      // if proposal was previously approved, move back to approved, else draft
      const data = await useGetProposalApprovalRequests(proposal.id);
      if (isArrayNonEmpty(data) && data[0].status === 'approved') {
        updateRequest.status = 'approved';
      } else {
        updateRequest.status = 'draft';
      }
    } else if (
      isDraftProposalStatus(proposal) ||
      isApprovedProposalStatus(proposal)
    ) {
      updateRequest.status = getPublishStatus(proposal);
    }

    save(updateRequest);

    if (noRecipientsLeft) {
      // If the last remaining contact is removed, new contacts cannot be added until proposal is approved again
      setIsSharing(false);
    }
  };

  // Don't save unless one value has changed.
  const hasChanged = (
    updated: Nullable<CreateProposalRequest>,
    current: Proposal
  ) =>
    Object.keys(updated).find(
      (key) =>
        updated[key as keyof CreateProposalRequest] !==
        current[key as keyof Proposal]
    );

  const save = (updateProposalRequest: Nullable<CreateProposalRequest>) => {
    if (!proposal) {
      return;
    }

    updateProposalRequest.proposalType = proposal.proposalType;
    if (hasChanged(updateProposalRequest, proposal)) {
      updateProposal({
        ...updateProposalRequest,
      });
    }
  };

  if (isProposalLoading) return <Loading />;

  const errorStatus = getErrorStatus(error);

  if (errorStatus || !proposal) {
    return <ProposalLoadError status={errorStatus || 500} />;
  }

  return (
    <>
      <ShareProposalPage
        isOpen={isSharing}
        onClose={() => {
          setIsSharing(false);
        }}
        onRecipientsChange={onRecipientsChange}
        proposal={proposal}
      />
      <ProposalEditor
        isSaving={isLoading}
        key={proposalKey}
        onShare={() => {
          setIsSharing(!isSharing);
        }}
        proposal={proposal}
        save={save}
      />
    </>
  );
};

export default ProposalLoader;
