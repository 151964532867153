import { ConnectorInfo, OrgConfig } from 'common';

const QUICKBOOKS: string = 'QuickBooks';
const QUICKBOOKS_CONFIG: OrgConfig['configKey'] = 'quickbooksConfig';
const QUICKBOOKS_SOURCE: ConnectorInfo['source'] = 'quickbooks';

export const QUICKBOOKS_INFO = {
  configType: QUICKBOOKS_CONFIG,
  source: QUICKBOOKS_SOURCE,
  name: QUICKBOOKS,
  showSaveButton: true,
};

export const accountContactsConfig = {
  integration: 'QuickBooks',
  description:
    'The QuickBooks Online connector automatically syncs invoices and payments to QuickBooks.',
  cacheflowToIntegrationSection: [
    {
      left: 'Customers',
      right: 'Customers',
    },
    {
      left: 'Products',
      right: 'Items',
    },
    {
      left: 'Billing/Payment Schedules',
      right: 'Invoices',
    },
    {
      left: 'Billing/Payment Schedule Items',
      right: 'Invoice Lines',
    },
    {
      left: 'Payments',
      right: 'Payments',
    },
  ],
  integrationToCacheflowSection: [
    {
      left: 'Payments',
      right: 'Payments',
    },
  ],
};
