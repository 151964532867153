import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Button,
  ButtonBar,
  CreateRefundRequest,
  DatePicker,
  Drawer,
  formatDateOrDefault,
  FormField,
  getToday,
  TextArea,
  Transfer,
  useToast,
} from 'common';

import { useCreateRefund } from 'app/src/services/api/refunds/refunds';

interface Props {
  isOpen: boolean;
  transfer: Transfer;
  customerName: string;
  onClose: () => void;
}

export const IssueRefundDrawer = ({
  isOpen,
  transfer,
  onClose,
  customerName,
}: Props) => {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const [formState, setFormState] = useState<CreateRefundRequest>({
    note: '',
    processDate: getToday(),
    transferId: transfer.id ?? '',
    amount: transfer.amount,
  });

  const { mutate: createRefundMutation, isPending } = useCreateRefund(
    () => {
      onClose();
      showToast.success('Refund initiated');
    },
    () => {
      showToast.error('Refund failed');
    },
    queryClient
  );

  const handleSubmit = () => {
    const refundRequest: CreateRefundRequest = {
      amount: formState.amount,
      transferId: formState.transferId,
      processDate: formState.processDate,
      note: formState.note,
    };

    createRefundMutation(refundRequest);
  };

  const handleChange = (field: string, value: string) => {
    setFormState({
      ...formState,
      [field]: value,
    });
  };

  return (
    <Drawer header="Refund" isOpen={isOpen} onClose={onClose}>
      <FormField label="Customer name">{customerName}</FormField>

      <FormField label="Payment Id">{transfer.id}</FormField>

      <FormField label="Payment amount">{transfer.amountFormatted}</FormField>

      <FormField label="Payment date">
        {formatDateOrDefault(transfer.processedAt)}
      </FormField>

      <FormField label="Refund amount">{transfer.amountFormatted}</FormField>

      <FormField label="Refund date">
        <DatePicker
          onChange={(value) => handleChange('processDate', value)}
          value={formState.processDate}
        />
      </FormField>

      <FormField label="Notes">
        <TextArea
          id="notes"
          onChange={(value) => handleChange('note', value)}
          value={formState.note}
        />
      </FormField>

      <ButtonBar>
        <Button label="Cancel" onClick={onClose} type="secondary" />
        <Button isDisabled={isPending} label="Refund" onClick={handleSubmit} />
      </ButtonBar>
    </Drawer>
  );
};
