import React from 'react';
import {
  BillingPeriodsType,
  isNumber,
  Item,
  PricebookEntry,
  Product,
  ProductLevel,
  ScheduleLine,
  useTranslation,
} from 'common';
import TBDTierRow from './TBDTierRow';
import styles from './TBDTier.module.scss';
import TimeBasedSubBar from '../TimeBasedSubBar';
import { updateRowItemPrice } from './utils';

interface Props {
  billingPeriod: BillingPeriodsType;
  currency?: string;
  entries: PricebookEntry[];
  item: Item;
  pricing: Product['pricing'];
  quantity?: number;
  scheduleRows: ScheduleLine[];
  setDraftSchedule: (scheduleDraft: ScheduleLine[]) => void;
  startIndex?: number;
  tiers: ProductLevel[];
}

const TimeBasedTieredSchedule = ({
  scheduleRows,
  quantity,
  setDraftSchedule,
  currency,
  startIndex,
  tiers,
  entries,
  item,
  billingPeriod,
  pricing,
}: Props) => {
  const { tk } = useTranslation();

  const updateQuantityOverride = (value: number, startInd: number) => {
    // with a change proposal, the starting index may not be zero
    const index = scheduleRows.findIndex((row) => row.startIndex === startInd);
    // @ts-ignore TODO: Fix
    scheduleRows[index].quantity = value;
    setDraftSchedule([...scheduleRows]);
  };

  return (
    <div className={styles.scheduleWrapper}>
      <TimeBasedSubBar
        item={item}
        onUpdateSchedules={setDraftSchedule}
        scheduleLines={scheduleRows}
      />
      <div className={styles.scheduleHeader}>
        <p className={styles.scheduleHeaderItemNarrow} />
        <p className={styles.scheduleHeaderItemNarrow}>{tk('Payment')}</p>
        <p className={styles.scheduleHeaderItemNarrow}>{tk('Qty')}</p>
        <p className={styles.scheduleHeaderItemNarrow}>{tk('Tier')}</p>
        <p className={styles.scheduleHeaderItemNarrow}>{tk('List price')}</p>
        <p className={styles.scheduleHeaderItemNarrow}>{tk('List adj.')}</p>
        <p className={styles.scheduleHeaderItem}>
          {pricing === 'stairstep' ? tk('Tier price') : tk('Unit price')}
        </p>
      </div>
      {scheduleRows.map((rowItem, index) => {
        return (
          <TBDTierRow
            key={index + 1}
            item={item}
            rowItem={rowItem}
            period={index + (startIndex || 0) + 1}
            updateRowItemLevelPrice={(
              listPrice,
              salesPrice,
              idx,
              levelIndex
            ) => {
              setDraftSchedule(
                updateRowItemPrice(
                  scheduleRows,
                  listPrice,
                  salesPrice,
                  idx,
                  levelIndex
                )
              );
            }}
            updateQuantityOverride={updateQuantityOverride}
            quantity={isNumber(rowItem.quantity) ? rowItem.quantity : quantity}
            currency={currency}
            tiers={tiers}
            entries={entries}
            billingPeriod={billingPeriod}
            pricing={pricing}
          />
        );
      })}
    </div>
  );
};

export default TimeBasedTieredSchedule;
