import React, { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Icon,
  useCurrentProposal,
  useRowItem,
  useToast,
  useTranslation,
  PopOutMenuOption,
} from 'common';
import { useDeleteProposalItem } from '../../../../../../services/proposal';
import { useIsItemRemovable } from './useIsItemRemovable';

export const DeleteItemAction = () => {
  const qc = useQueryClient();
  const showToast = useToast();
  const { tk } = useTranslation();

  const item = useRowItem();
  const proposal = useCurrentProposal();

  const { isRemovable } = useIsItemRemovable();

  const { mutate: deleteProposalItem } = useDeleteProposalItem(
    proposal.id,
    null,
    () => showToast.error('Proposal item deletion failed'),
    qc
  );

  const clickHandler = useCallback(() => {
    deleteProposalItem(item.id);
  }, [deleteProposalItem]);

  return (
    <PopOutMenuOption
      dataTestId={`remove-proposal-item-${item.id}`}
      icon={Icon.Close}
      isDisabled={!isRemovable}
      onClick={clickHandler}
      title={tk('Remove item')}
    />
  );
};
