import React, { useEffect, useMemo } from 'react';
import {
  ConnectorConfiguration,
  ConnectorInfoObject,
  Option,
} from '../ConnectorConstants/constants';
import ConnectorLocation from '../ConnectorLocation';
import {
  AccountingConfig,
  arrayTop,
  AvalaraConfig,
  FormField,
  Input,
  OrgConfig,
  Select,
  ToggleSwitch,
  ToolTip,
  useFlags,
  useFormValidation,
  useTranslation,
} from 'common';

interface Props {
  configuration: ConnectorConfiguration;
  connectorConfigValue: OrgConfig['configValue'];
  connectorInfo: ConnectorInfoObject;
  setConnectorConfigValue: React.Dispatch<
    React.SetStateAction<OrgConfig['configValue']>
  >;
  setSaveableChanges: (arg: boolean) => void;
}

const ConnectorConfigurationEditor = ({
  setConnectorConfigValue,
  configuration,
  connectorConfigValue,
  setSaveableChanges,
  connectorInfo,
}: Props) => {
  const { tk } = useTranslation();
  const { locationCodeMapping } = useFlags();
  const getValues = (): { [key: string]: any } => {
    const values = configuration.name
      ? (connectorConfigValue as any)[configuration.name]
      : connectorConfigValue;

    return values ?? {};
  };

  const getInfoMessege = (
    configurationLine: keyof AccountingConfig
  ): string => {
    if (configurationLine === 'productAccountCode') {
      return 'This account will be set as an income account for any new product created by Cacheflow during sync. Existing products in QuickBooks will not be updated.';
    } else if (configurationLine === 'depositAccountCode') {
      return 'This account will be set as deposit to account while creating payments in QuickBooks.';
    } else if (configurationLine === 'taxAccountCode') {
      return 'Income Account for tax line items';
    }
    return '';
  };

  const isAvalaraConfig = (c: OrgConfig['configValue']): c is AvalaraConfig =>
    c?.configType === 'avalaraConfig';
  const avalaraConfigOrUndefined = (
    c: OrgConfig['configValue']
  ): AvalaraConfig | undefined => (isAvalaraConfig(c) ? c : undefined);

  const requiredFields = useMemo(
    () =>
      configuration.lines
        .filter((line) => line.isRequired)
        .map((line) => ({
          fieldName: line.fieldName,
          humanReadableName: line.humanReadableName,
          isRequired: line.isRequired,
        })),
    [configuration.lines]
  );

  const { getErrorToShow, setHasVisitedField, isFormValid } = useFormValidation(
    requiredFields,
    getValues()
  );

  const isAvalaraConfigValid = useMemo(() => {
    if (!isAvalaraConfig(connectorConfigValue)) {
      return true;
    }

    return connectorConfigValue?.enabled;
  }, [connectorConfigValue]);

  useEffect(() => {
    if (avalaraConfigOrUndefined(connectorConfigValue)?.enabled) {
      setSaveableChanges(isFormValid);
    }
  }, [
    requiredFields,
    avalaraConfigOrUndefined(connectorConfigValue)?.enabled,
    isFormValid,
  ]);

  const handleFieldChange = (fieldName: string, value: any) => {
    const configName = configuration.name;
    const values = getValues();

    const change: OrgConfig['configValue'] = configName
      ? { [configName]: { ...values, [fieldName]: value } }
      : { [fieldName]: value };

    values[fieldName] = value;
    setConnectorConfigValue((prev) => ({
      ...prev,
      ...change,
    }));
    (connectorConfigValue as AvalaraConfig).enabled
      ? setSaveableChanges(isFormValid)
      : setSaveableChanges(true);
  };

  const initialValueBoolean = (name: string) => {
    const values = getValues();
    return !!values[name];
  };

  function initialValue(name: string) {
    const values = getValues();
    const value =
      values[name] ??
      (() => {
        const lineEntry = configuration.lines.find(
          (entry) => entry.fieldName === name
        );
        const options: Option[] = lineEntry?.inputProps?.options;
        return arrayTop(options)?.value;
      })();
    return value ?? '';
  }

  const handleToggle = (fieldName: string) => {
    const values = getValues();
    handleFieldChange(fieldName, !values[fieldName]);
  };

  return (
    <div className="connector-configuration">
      <section className="bottom">
        {locationCodeMapping &&
          connectorConfigValue &&
          'locationCodeType' in connectorConfigValue && (
            <div className="mb-2">
              <ConnectorLocation
                setSavableChanges={setSaveableChanges}
                connectorConfigValue={connectorConfigValue}
                setConnectorConfigValue={setConnectorConfigValue}
              />
            </div>
          )}
        {configuration.toggleSwitchForEnablement && (
          <FormField
            label={tk(`connectors.${connectorInfo.source}.enableToggle`)}
          >
            <ToggleSwitch
              onChange={(e) => {
                setConnectorConfigValue((prev) => ({
                  ...prev,
                  enabled: e,
                }));
                setSaveableChanges(true);
              }}
              value={
                connectorConfigValue &&
                'enabled' in connectorConfigValue &&
                connectorConfigValue.enabled
              }
              name="input-enabled-toggle"
            />
          </FormField>
        )}

        {configuration.lines
          .filter((line) => line.include !== false)
          .map((line) => (
            <React.Fragment key={`connector-config-${line.fieldName}`}>
              {line.type === 'toggle' &&
              connectorInfo.configType !== 'hubspotConfig' ? (
                <FormField>
                  <ToggleSwitch
                    label={tk(
                      line.humanReadableName ||
                        `${connectorInfo.source}.${line.fieldName}`
                    )}
                    onChange={() => handleToggle(line.fieldName)}
                    value={initialValueBoolean(line.fieldName)}
                    name={`input-${line.fieldName}`}
                  />
                </FormField>
              ) : (
                line.type !== 'toggle' && (
                  <FormField
                    label={tk(
                      line.humanReadableName ||
                        `${connectorInfo.source}.${line.fieldName}`
                    )}
                    errorToShow={
                      line.isRequired && isAvalaraConfigValid
                        ? getErrorToShow(line.fieldName)
                        : undefined
                    }
                  >
                    {line.type === 'select' && (
                      <ToolTip
                        title={getInfoMessege(
                          line.fieldName as keyof AccountingConfig
                        )}
                      >
                        <Select<string>
                          dataTestId={`input-${line.fieldName}`}
                          onChange={(value) =>
                            handleFieldChange(line.fieldName, value)
                          }
                          value={initialValue(line.fieldName)}
                          options={line.inputProps?.options ?? []}
                        />
                      </ToolTip>
                    )}

                    {line.type === 'string' && (
                      <Input
                        id={`input-${line.fieldName}`}
                        onChange={(rv) => handleFieldChange(line.fieldName, rv)}
                        value={initialValue(line.fieldName)}
                        onBlur={() =>
                          line.isRequired && setHasVisitedField(line.fieldName)
                        }
                      />
                    )}

                    {line.type === 'password' && (
                      <Input
                        id={`input-${line.fieldName}`}
                        type="password"
                        onChange={(rv) => handleFieldChange(line.fieldName, rv)}
                        value={initialValue(line.fieldName)}
                        onBlur={() =>
                          line.isRequired && setHasVisitedField(line.fieldName)
                        }
                      />
                    )}
                  </FormField>
                )
              )}
            </React.Fragment>
          ))}
      </section>
    </div>
  );
};

export default ConnectorConfigurationEditor;
