import { ProposalItemRequest, useCurrentProposal, useRowItem } from 'common';
import React, { useCallback } from 'react';
import { useUpdateProposalItem } from '../../../../../../services/proposal';
import CustomizeDateButton from '../../CustomizeDateButton';

export const CustomizeItemDateAction = () => {
  const item = useRowItem();
  const proposal = useCurrentProposal();
  const { mutate } = useUpdateProposalItem(proposal.id);

  const clickHandler = useCallback(
    (body: ProposalItemRequest) => {
      mutate({ id: item.id, body });
    },
    [mutate]
  );

  return (
    <CustomizeDateButton
      item={item}
      onUpdateItem={clickHandler}
      proposal={proposal}
    />
  );
};
