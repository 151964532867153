import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CloseConfig,
  Config,
  ConnectorInfo,
  ConnectorSetup,
  getErrorMessage,
  Loading,
  noop,
  pushIf,
  useFlags,
  useToast,
} from 'common';
import { useDocuSignAccount } from '../../services/api/docusign/docusign';
import { useFindSlackWorkspace } from '../../services/slack';
import IACListItem from './IACListItem';
import { ENVIRONMENT_CONFIG } from '../../../config/hosts';
import SlackLogoSVG from '../../assets/integration-logos/slackLogoSVG';
import SalesforceLogoSVG from '../../assets/integration-logos/salesforceLogoSVG';
import HubspotSVG from '../../assets/integration-logos/hubspotSVG';
import DocuSignLogoSVG from '../../assets/integration-logos/docuSignLogoSVG';
import ZapierLogoSVG from 'app/src/assets/integration-logos/ZapierLogoSVG';
import {
  SETTINGS,
  SETTINGS_AVALARA,
  SETTINGS_CLOSE,
  SETTINGS_HUBSPOT,
  SETTINGS_QUICKBOOKS,
  SETTINGS_WARMLY,
  SETTINGS_SALESFORCE,
  SETTINGS_SIGNING,
  SETTINGS_SLACK,
  SETTINGS_STRIPE,
  SETTINGS_ZAPIER,
} from '../../core-utils/routes';
import BackArrow from 'app/src/components/Navigation/BackArrow';
import { useAuthorizeConnector } from '../../services/connectors';
import './IntegrationsAndConnectors.css';

import QuickbooksSVG from '../../assets/integration-logos/QuickbooksSVG';
import Page from '../../components/Page';
import { useStripeAccount } from 'app/src/services/api/stripe/stripe';
import StripeLogoSVG from 'app/src/assets/integration-logos/StripeLogoSVG';
import AvalaraLogoSVG from '../../assets/integration-logos/AvalaraLogoSVG';
import CloseSVG from '../../assets/integration-logos/CloseSVG';
import { useFindOrgDefault, useOrgDefaults } from '../../services/orgDefaults';
import {
  GatewayType,
  getGatewayType,
  isConnectorEnabled,
} from './connectorHelpers';
import warmlyLogo from '../../assets/integration-logos/warmly.png';

const IntegrationsAndConnectorsList: React.FC = () => {
  const navigate = useNavigate();
  const {
    closeConnector,
    hubspotConnector,
    quickbooks,
    salesforceConnector,
    taxyTurby,
    warmlyAi,
    zapierConnector,
  } = useFlags();
  const { data: slackData } = useFindSlackWorkspace();
  const { data: docuSignData } = useDocuSignAccount();
  const { data: stripeAccountData } = useStripeAccount();
  const { data: closeConfig } = useFindOrgDefault('closeConfig');
  const [closeEnabled, setCloseEnabled] = useState(false);

  const showToast = useToast();

  const { data: orgConfigMap } = useOrgDefaults();

  const handleSaveSuccess = (setup: ConnectorSetup) => {
    window.location.href = setup.authorizeUrl!;
  };

  const handleSaveError = (error: unknown) => {
    const msg = getErrorMessage(error);
    showToast.error('Error connecting connector: ' + msg);
  };

  const { mutate: authConnector } = useAuthorizeConnector(
    handleSaveSuccess,
    handleSaveError
  );

  useEffect(() => {
    if (closeConfig) {
      const config = closeConfig.configValue as CloseConfig;
      setCloseEnabled(!!config.enabled);
    }
  }, [closeConfig]);

  const connect = (
    source: ConnectorInfo['source'],
    _configType: Config['configType'],
    _path: string,
    gatewayType: GatewayType = 'cacheflow'
  ) => {
    if (gatewayType === 'cacheflow') {
      authConnector({
        type: source,
        redirectUrl:
          (ENVIRONMENT_CONFIG.shouldUseHttps ? 'https' : 'http') +
          '://' +
          ENVIRONMENT_CONFIG.cfBaseAppHost +
          `/settings/auth/callback/${source}`,
      });
      return;
    }

    showToast.error('Gateway not supported ' + gatewayType);
  };

  if (!orgConfigMap) {
    return <Loading />;
  }

  // @ts-ignore
  const integrations: Array<{
    description: string;
    gatewayType?: 'cacheflow' | 'paragon' | undefined;
    handleActivate: (gatewayType?: GatewayType) => void | Promise<void>;
    isConnected: () => boolean;
    isDisabled?: () => boolean;
    isFeatureEnabled: boolean;
    logo: React.ReactElement;
    name: string;
    path: string;
  }> = [
    {
      name: 'Stripe',
      description: 'Collect payments using credit cards and direct debit.',
      isConnected: () => !!stripeAccountData,
      logo: <StripeLogoSVG width={72} height={72} />,
      handleActivate: () => {
        navigate(SETTINGS_STRIPE);
      },
      isFeatureEnabled: true,
      path: SETTINGS_STRIPE,
    },
    {
      name: 'Salesforce Connector',
      description:
        'Sync your Salesforce opportunities, create and attribute proposals in Cacheflow, and update status and documents to the opportunity record in Salesforce.',
      isConnected: () => isConnectorEnabled(orgConfigMap, 'salesforceConfig'),
      gatewayType: getGatewayType(orgConfigMap, 'salesforceConfig'),
      logo: <SalesforceLogoSVG width={72} height={72} />,

      handleActivate: (gatewayType) => {
        connect(
          'salesforce',
          'salesforceConfig',
          SETTINGS_SALESFORCE,
          gatewayType
        );
      },
      path: SETTINGS_SALESFORCE,
      isFeatureEnabled: salesforceConnector,
    },
    {
      name: 'HubSpot Connector',
      description:
        'Sync your HubSpot deals, create and attribute proposals in Cacheflow, and update status and documents to the deal record in HubSpot.',
      isConnected: () => isConnectorEnabled(orgConfigMap, 'hubspotConfig'),
      gatewayType: getGatewayType(orgConfigMap, 'hubspotConfig'),
      logo: <HubspotSVG width={72} height={72} />,
      handleActivate: (gatewayType) => {
        connect('hubspot', 'hubspotConfig', SETTINGS_HUBSPOT, gatewayType);
      },
      path: SETTINGS_HUBSPOT,
      isFeatureEnabled: hubspotConnector,
    },
    {
      name: 'Close Connector',
      description:
        'Sync your Close opportunities, create and attribute proposals in Cacheflow, and update status and documents to the deal record in Close.',
      isConnected: () => closeEnabled,
      isDisabled: () => false,
      logo: <CloseSVG width={72} height={72} />,
      handleActivate: () => {
        navigate(SETTINGS_CLOSE);
      },
      path: SETTINGS_CLOSE,
      isFeatureEnabled: closeConnector,
    },
    {
      name: 'QuickBooks Connector',
      description: 'Sync with QuickBooks',
      isConnected: () => isConnectorEnabled(orgConfigMap, 'quickbooksConfig'),
      gatewayType: getGatewayType(orgConfigMap, 'quickbooksConfig'),
      logo: <QuickbooksSVG width={72} height={72} />,
      handleActivate: (gatewayType) => {
        connect(
          'quickbooks',
          'quickbooksConfig',
          SETTINGS_QUICKBOOKS,
          gatewayType
        );
      },
      path: SETTINGS_QUICKBOOKS,
      isFeatureEnabled: quickbooks,
    },
    {
      name: 'Warmly Chat',
      description: 'Chat and video call directly on your proposals.',
      isConnected: () => isConnectorEnabled(orgConfigMap, 'warmlyConfig'),
      logo: <img alt="Warmly" src={warmlyLogo} width={72} height={72} />,
      handleActivate: () => {
        navigate(SETTINGS_WARMLY);
      },
      path: SETTINGS_WARMLY,
      isFeatureEnabled: warmlyAi,
    },
    {
      name: 'Slack Connector',
      description:
        'Receive instant updates on your proposals and manage approvals directly in Slack.',
      isConnected: () => !!slackData,
      logo: <SlackLogoSVG width={60} height={60} />,
      handleActivate: () => {
        navigate(SETTINGS_SLACK);
      },
      path: SETTINGS_SLACK,
      isFeatureEnabled: true,
    },
    {
      name: 'Sign with DocuSign',
      description:
        "Integrate your DocuSign account into Cacheflow's online proposal checkout to eliminate the back and forth with your customer.",
      isConnected: () => !!docuSignData,
      logo: <DocuSignLogoSVG width={72} height={72} />,
      handleActivate: () => {
        navigate(SETTINGS_SIGNING);
      },
      path: SETTINGS_SIGNING,
      isFeatureEnabled: true,
    },
    ...pushIf(taxyTurby, {
      name: 'Avalara Connector',
      description: 'Avalara Connector',
      isConnected: () => taxyTurby,
      isDisabled: () => false,
      logo: <AvalaraLogoSVG width={72} height={72} />,
      handleActivate: noop,
      isFeatureEnabled: taxyTurby,
      path: SETTINGS_AVALARA,
    }),
    {
      name: 'Zapier Connector',
      description: 'Connect Cacheflow with any other app through zaps',
      isConnected: () => isConnectorEnabled(orgConfigMap, 'zapierConfig'),
      gatewayType: getGatewayType(orgConfigMap, 'zapierConfig'),
      logo: <ZapierLogoSVG height={72} width={72} />,
      handleActivate: () => {
        navigate(SETTINGS_ZAPIER);
      },
      path: SETTINGS_ZAPIER,
      isFeatureEnabled: zapierConnector,
    },
  ];

  return (
    <Page
      className="integrations-and-connections-list"
      data-testid="integrations"
      leftWidget={
        <BackArrow path={SETTINGS} title="Integrations & connectors" />
      }
    >
      {integrations
        .filter((listItem) => listItem.isFeatureEnabled)
        .map((listItem) => (
          <IACListItem key={listItem.name} {...listItem} />
        ))}
    </Page>
  );
};

export default IntegrationsAndConnectorsList;
