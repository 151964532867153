import React, { useEffect, useRef, useState } from 'react';
import {
  doDocuSignSetup,
  getConsentURL,
  getDocuSignUser,
  useDocuSignAccount,
} from '../../../../services/api/docusign/docusign';
import {
  Button,
  Card,
  DocusignAccount,
  DocusignUser,
  log,
  noop,
  Type,
} from 'common';
import { useActiveUser } from '../../../../Auth';
import SellerSignaturePreview from './SellerSignaturePreview';
import SellerSignatureModal from './SellerSignatureModal';
import { ConnectionStatus } from '../../../../components';
import LogoCardHeader from '../../Connectors/LogoCardHeader';
import DocuSignLogoSVG from '../../../../assets/integration-logos/docuSignLogoSVG';

const Signing: React.FC = () => {
  const apiResponse = useDocuSignAccount();

  const [docuSignAccount, setDocuSignAccount] = useState<DocusignAccount>();
  const [docuSignUser, setDocuSignUser] = useState<DocusignUser>();
  const [setupError, setSetupError] = useState<string>();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [signatureModalIsOpen, setSignatureModalIsOpen] =
    useState<boolean>(false);
  const activeUserId = useActiveUser()?.id;
  const isOnMountCalled = useRef(false);

  async function performSetup(codeValue: string) {
    setInProgress(true);
    const response = await doDocuSignSetup(codeValue);

    if (response.status >= 400) {
      setSetupError('setup error');
      setInProgress(false);
    } else {
      await apiResponse.refetch();
    }
  }

  useEffect(() => {
    if (isOnMountCalled.current) return;
    isOnMountCalled.current = true;

    const queryParams = new URLSearchParams(window.location.search);
    const dsError = queryParams.get('dsError');
    const dsErrorMessage = queryParams.get('dsErrorMessage');
    if (dsError) setSetupError(`${dsError}: ${dsErrorMessage}`);

    const dsCode = queryParams.get('dsCode');
    if (dsCode) {
      performSetup(dsCode).then(noop, log.error);
    }
  }, []);

  const setupHandler = () => {
    window.location.href = getConsentURL();
  };

  useEffect(() => {
    if (
      !docuSignAccount &&
      !apiResponse.isLoading &&
      apiResponse.data &&
      !apiResponse.isError
    ) {
      setDocuSignAccount(apiResponse.data);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (docuSignAccount) {
      getDocuSignUser()
        .then((data) => {
          setDocuSignUser(data);
          setInProgress(false);
        })
        .catch(() => {
          setSetupError('Setup error. Failed to retrieve DocuSign user.');
          setInProgress(false);
        });
    }
  }, [docuSignAccount]);

  const getStatusMessage = (): string => {
    if (setupError) {
      return setupError;
    }

    if (docuSignAccount) {
      if (docuSignUser) {
        return `Connected with: ${docuSignUser.username}`;
      } else {
        return 'Connected';
      }
    }

    return '';
  };

  return (
    <>
      <Card>
        <LogoCardHeader logo={DocuSignLogoSVG} name="DocuSign">
          <Button
            className="h-auto py-2"
            isLoading={inProgress}
            label={docuSignAccount ? 'Reconfigure DocuSign' : 'Set up DocuSign'}
            onClick={setupHandler}
            type={docuSignAccount ? 'secondary' : 'primary'}
          />
        </LogoCardHeader>
        <div className="py-4">
          <Type paragraph>
            Connecting DocuSign to Cacheflow allows on-the-fly creation of
            signable contracts based on proposals.
          </Type>

          {setupError && (
            <div className="flex items-center">
              <p className="text-red">{setupError}</p>
            </div>
          )}

          {(docuSignAccount ?? setupError) && (
            <ConnectionStatus
              isConnected={!!docuSignAccount || !!setupError}
              message={getStatusMessage()}
            />
          )}
        </div>
      </Card>

      {activeUserId && (
        <Card>
          <SellerSignaturePreview
            openSignatureModal={() => setSignatureModalIsOpen(true)}
            userId={activeUserId}
          />
        </Card>
      )}

      {activeUserId && (
        <SellerSignatureModal
          closeModal={() => setSignatureModalIsOpen(false)}
          isOpen={signatureModalIsOpen}
          userId={activeUserId}
        />
      )}
    </>
  );
};

export default Signing;
