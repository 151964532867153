import React from 'react';
import Page from 'app/src/components/Page';
import InvoiceTable from './InvoiceTable';
import { useIsConnectorEnabled } from 'app/src/services/connectors';
import { Filter, pushIf } from 'common';

const InvoiceListPage = () => {
  enum InvoiceStatus {
    cancelled = 'Cancelled',
    draft = 'Draft',
    open = 'Open',
    paid = 'Paid',
    past_due = 'Past due',
    voided = 'Void',
  }

  enum SyncStatus {
    success = 'Success',
    error = 'Failed',
  }

  const quickBooksConnectionStatus = useIsConnectorEnabled('quickbooksConfig');

  return (
    <Page leftWidget="Invoices">
      <InvoiceTable
        isQuickBooksEnabled={quickBooksConnectionStatus}
        filters={[
          {
            type: 'search',
            key: 'search',
            searchFields: ['customer.name', 'invoiceNumber'],
          },
          {
            type: 'dropdown',
            key: 'displayStatus',
            humanReadableName: 'Status',
            fieldName: 'status',
            options: Object.keys(InvoiceStatus).map((key) => ({
              value: key,
              name: InvoiceStatus[key as keyof typeof InvoiceStatus].valueOf(),
            })),
          },
          ...pushIf<Filter>(quickBooksConnectionStatus, {
            type: 'dropdown',
            key: 'syncStatus',
            humanReadableName: 'Sync status',
            fieldName: 'syncStatus',
            options: Object.keys(SyncStatus).map((key) => ({
              value: key,
              name: SyncStatus[key as keyof typeof SyncStatus].valueOf(),
            })),
          }),
          {
            type: 'valueRadio',
            key: 'amount',
            humanReadableName: 'Amount',
            fieldName: 'amount',
            prefix: '$',
          },
          {
            type: 'valueRadio',
            key: 'balance',
            humanReadableName: 'Balance',
            fieldName: 'balance',
            prefix: '$',
          },
          {
            type: 'dateRadio',
            key: 'schedule.invoiceDate',
            humanReadableName: 'Invoice date',
            fieldName: 'schedule.invoiceDate',
            optionsType: 'past',
          },
          {
            type: 'dateRadio',
            key: 'schedule.dueDate',
            humanReadableName: 'Due date',
            fieldName: 'schedule.dueDate',
            optionsType: 'futureAndPast',
          },
          {
            type: 'toggle',
            key: 'include_archived',
            humanReadableName: 'Include archived',
            fieldName: 'include_archived',
            separateQueryParam: true,
            value: 'true',
          },
        ]}
      />
    </Page>
  );
};

export default InvoiceListPage;
