import React, { useState } from 'react';
import ProposalCard from '../ProposalCard/ProposalCard';
import {
  basisPointsToPercentage,
  Checkbox,
  ConfirmModal,
  CreateProposalRequest,
  CurrencySearchSelect,
  Customer,
  DatePicker,
  formatDateOrDefault,
  formatMoneyOrDefault,
  hasUsage,
  Input,
  InputError,
  isDraftProposalStatus,
  isExpiredProposalStatus,
  isNumber,
  log,
  Nullable,
  PaymentSchedule,
  Proposal,
  ProposalLabel,
  SmallGrayText,
  useFlags,
  useTranslation,
} from 'common';
import ProposalTerms from './ProposalTerms';
import CustomerSelect from './CustomerSelect';
import {
  isProposalSourced,
  isProposalTemplate,
  proposalHasRecipients,
  validateExpirationDate,
} from '../proposalUtilities';
import EffectiveDate from 'app/src/pages/Proposals/ProposalEditor/ProposalCore/EffectiveDate';
import {
  useAllCurrencies,
  useOrgCurrencies,
} from '../../../../services/api/currency/currency';
import styles from './ProposalCore.module.scss';

interface Props {
  isAccepted: boolean;
  isDisabled: boolean;
  isProposalTypeChange: boolean;
  isProposalTypeRenewal: boolean;
  isPublished: boolean;
  onCoreChange: (
    updateProposalRequest: Nullable<CreateProposalRequest>
  ) => void;
  paymentSchedule: PaymentSchedule;
  proposal: Proposal;
}

const ProposalCore = ({
  proposal,
  paymentSchedule,
  onCoreChange,
  isPublished,
  isAccepted,
  isDisabled,
  isProposalTypeChange,
  isProposalTypeRenewal,
}: Props) => {
  const { tk } = useTranslation();
  const { enhancedSpans } = useFlags();
  const isTemplate = isProposalTemplate(proposal);

  const [currencyToChange, setCurrencyToChange] = useState<
    string | undefined
  >();
  const [customerToChange, setCustomerToChange] = useState<Customer | null>(
    null
  );
  const [
    isOpenChangeCustomerCurrencyModal,
    setIsOpenChangeCustomerCurrencyModal,
  ] = useState(false);

  const onChange = (
    field: keyof CreateProposalRequest,
    value: Nullable<CreateProposalRequest>[keyof CreateProposalRequest],
    isValid: boolean
  ) => {
    if (isValid) {
      onCoreChange({
        [field]: value,
      });
    }
  };

  const handleExpirationDateChange = (value: string): void => {
    // if date is not complete - doesn't have m, d, or y - it will be ""
    if (value === '') {
      onChange('expiresAt', null, true);
      return;
    }

    // if date is complete, save it to server (even if it is in the past)
    // - this will be shown with an error message below input and in the
    // pre-request-approval and pre-share checklists
    onChange('expiresAt', value, true);
  };

  const handleProposalNumberChange = (value: string): void => {
    const toPersist = value.trim() === '' ? null : value.trim();
    onChange('proposalNumber', toPersist, true);
  };

  const onUpdateCustomer = (
    customer: Customer | undefined,
    currency?: string
  ) => {
    const updateRequest: Nullable<CreateProposalRequest> = {
      customerId: customer?.id ?? null,
    };

    // Only update proposal name if it's not set,
    // or it was set to the currently set customer
    if (
      proposal.name === 'Untitled' ||
      (proposal.customer && proposal.customer.name === proposal.name)
    ) {
      updateRequest.name = customer?.name ?? 'Untitled';
    }

    if (currency) {
      updateRequest.currency = currency;
    }

    onCoreChange(updateRequest);
    setIsOpenChangeCustomerCurrencyModal(false);
  };

  const onSelectCustomer = (customer: Customer | undefined) => {
    if (
      customer?.defaultCurrency === proposal.currency ||
      proposal.proposalItems.length === 0
    ) {
      onUpdateCustomer(customer);
    } else {
      setCustomerToChange(customer ?? null);
      setIsOpenChangeCustomerCurrencyModal(true);
    }
  };

  const handleToggleShowTotalContractValue = (checked: boolean) => {
    onCoreChange({
      options: {
        ...proposal.options,
        showTotalContractValue: checked,
      },
    });
  };

  const handleToggleHideDiscounts = (checked: boolean) => {
    onCoreChange({
      options: {
        ...proposal.options,
        hideDiscounts: checked,
      },
    });
  };

  const changeCurrency = (currency: string) => {
    onCoreChange({
      currency,
    });
    setCurrencyToChange(undefined);
  };

  const initiateChangeCurrency = (currency: string) => {
    if (proposal.proposalItems.length > 0 && currency !== proposal.currency) {
      setCurrencyToChange(currency);
    } else {
      changeCurrency(currency);
    }
  };

  const cancelChangeCurrency = () => {
    setCurrencyToChange(undefined);
  };

  const cancelChangeCustomer = () => {
    setIsOpenChangeCustomerCurrencyModal(false);
    setCustomerToChange(null);
  };

  const isPublishedOrAccepted = isPublished || isAccepted;
  const hasProposalRecipients = proposalHasRecipients(proposal);
  const hasItems = proposal.proposalItems.length > 0;

  const expirationError =
    isDraftProposalStatus(proposal) &&
    validateExpirationDate(proposal.expiresAt);
  const isSourcedProposal = isProposalSourced(proposal);

  const { data: currencies } = useAllCurrencies();
  const { data: orgCurrencies } = useOrgCurrencies();

  return (
    <ProposalCard
      className={styles.proposalCore}
      isDisabled={isDisabled}
      noPadding
    >
      <ConfirmModal
        confirmText={`${tk('Change currency to')} ${currencyToChange}`}
        dataTestId="confirm-change-currency-modal"
        header={
          <h3>
            {tk("Are you sure you want to change this proposal's currency?")}
          </h3>
        }
        isOpen={!!currencyToChange}
        onClose={cancelChangeCurrency}
        onConfirm={() => {
          if (currencyToChange) {
            changeCurrency(currencyToChange);
          } else {
            log.error('no currency to change');
          }
        }}
      >
        <p>
          {tk(
            'Any products without support for the new currency and all price adjustments applied in the current currency will not be retained.'
          )}
        </p>
      </ConfirmModal>
      <ConfirmModal
        confirmText={tk('Change customer and currency')}
        header={
          <>{tk("Are you sure you want to change this proposal's customer?")}</>
        }
        isOpen={isOpenChangeCustomerCurrencyModal && !!customerToChange}
        onClose={cancelChangeCustomer}
        onConfirm={() => {
          if (customerToChange) onUpdateCustomer(customerToChange);
        }}
      >
        <p>
          {tk(
            'Choosing this customer will change the currency on this proposal.' +
              ' Any products without support for the new currency and all price adjustments applied in the current currency will not be retained.'
          )}
        </p>
      </ConfirmModal>

      {!isTemplate && (
        <div className={styles.proposalCoreWrapper}>
          <div className={styles.proposalCoreSection}>
            <ProposalLabel
              className={styles.proposalCardLabel}
              name={tk('Customer')}
            />
            <CustomerSelect
              isDisabled={
                isDisabled ||
                hasProposalRecipients ||
                isProposalTypeChange ||
                isSourcedProposal ||
                isProposalTypeRenewal
              }
              onSelectCustomer={onSelectCustomer}
              selectedCustomer={proposal.customer}
            />
            {isSourcedProposal && (
              <SmallGrayText
                text={tk(
                  'Customers of proposals created from external sources cannot be changed.'
                )}
              />
            )}
          </div>
          <div className={styles.proposalCoreSection}>
            <ProposalLabel
              className={styles.proposalCardLabel}
              name={tk('Proposal No.')}
            />
            <Input
              dataTestId="reference"
              isDisabled={isDisabled}
              onBlur={handleProposalNumberChange}
              placeholder="Reference (optional)"
              value={proposal.proposalNumber}
            />
          </div>
          <div>
            <ProposalLabel
              className={styles.proposalCardLabel}
              name={tk('Proposal expires')}
            />
            {!isDisabled && (
              <DatePicker
                dataTestId="expiresAt"
                disablePastDates
                onChange={handleExpirationDateChange}
                value={proposal.expiresAt}
              />
            )}
            {expirationError && !isDisabled && (
              <InputError errorToShow={expirationError} />
            )}
            {isDisabled && (
              <Input
                dataTestId="expriresAt"
                isDisabled
                value={
                  proposal.expiresAt
                    ? formatDateOrDefault(proposal.expiresAt)
                    : tk('Never expires')
                }
              />
            )}
          </div>
        </div>
      )}
      <div
        className={`grid ${
          isTemplate ? 'grid-cols-2' : 'grid-cols-3'
        } gap-8 p-6`}
      >
        <div>
          <ProposalLabel
            className={styles.proposalCardLabel}
            name={tk('Term')}
          />
          <ProposalTerms
            isDisabled={isDisabled || isProposalTypeChange}
            onTermChanges={onCoreChange}
            proposal={proposal}
          />
          {orgCurrencies && orgCurrencies.length > 1 && (
            <>
              <ProposalLabel
                className={styles.proposalCardLabel}
                name={tk('Currency')}
              />
              <CurrencySearchSelect
                currencies={currencies}
                dataTestId="currency-select"
                defaultSelectedCurrency={proposal.currency}
                isDisabled={
                  isDisabled || isProposalTypeChange || isProposalTypeRenewal
                }
                onSelectCurrency={initiateChangeCurrency}
                orgCurrencies={orgCurrencies}
              />
            </>
          )}
        </div>
        {!isTemplate && (
          <div className={styles.spacing}>
            <ProposalLabel
              className={styles.proposalCardLabel}
              name={
                isProposalTypeRenewal
                  ? 'Renewal effective date'
                  : 'Effective date'
              }
            />
            <EffectiveDate
              isAccepted={isAccepted}
              isDisabled={isDisabled}
              onCoreChange={onCoreChange}
              proposal={proposal}
            />
          </div>
        )}

        <div className={styles.spacing}>
          {!isTemplate && (
            <>
              <ProposalLabel
                className={styles.proposalCardLabel}
                name={tk('Total contract value')}
              />
              <div className={styles.total}>
                {hasItems
                  ? paymentSchedule.totalFormatted
                  : proposal.amountFormatted}

                {enhancedSpans &&
                  isNumber(proposal.changes?.amountChange) &&
                  proposal.changes.amountChange > 0 && (
                    <div className={styles.amountChange}>
                      {formatMoneyOrDefault(
                        {
                          amount: proposal.changes.amountChange,
                          currency: proposal.currency,
                        },
                        {
                          signDisplay: 'exceptZero',
                        }
                      )}
                    </div>
                  )}
              </div>
            </>
          )}

          {!proposal.options?.hideDiscounts &&
            !!paymentSchedule.totalDiscount &&
            paymentSchedule.totalDiscount > 0 && (
              <div className="text-slate">
                Discount {paymentSchedule.totalDiscountFormatted}
                {` (${basisPointsToPercentage(
                  paymentSchedule.totalDiscountPercent
                )}`}
                )
              </div>
            )}
          {hasUsage(proposal) && (
            <div className="text-sm opacity-50 font-normal">
              {tk('will vary based on usage')}
            </div>
          )}
          <div>
            <Checkbox
              dataTestId="showTotalContractValue"
              id="showTotalContractValue"
              isDisabled={
                isDisabled ||
                isPublishedOrAccepted ||
                isExpiredProposalStatus(proposal)
              }
              label={tk('Show amount in checkout')}
              name={tk('showTotalContractValue')}
              onChange={handleToggleShowTotalContractValue}
              value={proposal.options?.showTotalContractValue}
            />
          </div>
          <div>
            <Checkbox
              dataTestId="hideDiscounts"
              id="hideDiscounts"
              isDisabled={
                isDisabled ||
                isPublishedOrAccepted ||
                isExpiredProposalStatus(proposal)
              }
              label={tk('Hide discounts in checkout')}
              name="hideDiscounts"
              onChange={handleToggleHideDiscounts}
              value={proposal.options?.hideDiscounts}
            />
          </div>
        </div>
      </div>
    </ProposalCard>
  );
};

export default ProposalCore;
