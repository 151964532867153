import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useCreateProductUsage } from '../../services/billing';
import {
  Button,
  Contract,
  Drawer,
  formatDateOrDefault,
  getBillingPeriodLabelAdverb,
  InputRef,
  isNumber,
  Product,
  ProductUsageRequest,
  useToast,
  useTranslation,
} from 'common';
import ProductUsageField from './ProductUsageField';
import BillingScheduleUpdateLogs from './BillingScheduleUpdateLogs';
import { getProductIdsForContractBilling } from './billingUtilities';
import IDField from 'app/src/components/IDField';
import { useNavigate } from 'react-router-dom';

import { isAuthorizedInRoles } from '../../core-utils/helperFunctions/userServiceHelper';
import { useBatchProducts } from '../../services/api/products/products';

interface Props {
  billingScheduleId?: string;
  contract: Contract;
  onClose: () => void;
  onSaveSuccess: () => void;
}

interface UsageItemDraft {
  billingItemId: string;
  originalQuantity: number | undefined;
  productName: string;
  quantity: number | undefined;
  itemName?: string;
}

const BillingScheduleForm: React.FC<Props> = ({
  contract,
  billingScheduleId,
  onSaveSuccess,
  onClose,
}: Props) => {
  const { tk } = useTranslation();
  const showToast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const firstInputRef = useRef<InputRef>(null);
  const editable = isAuthorizedInRoles(['admin']);

  const activeUsageSchedule = contract.billingSchedules.find(
    (bs) => bs.id === billingScheduleId
  );

  const productIdsToFetch: string[] = getProductIdsForContractBilling(contract);

  const hasProductsToFetch = !!contract && productIdsToFetch.length > 0;

  const { data: products } = useBatchProducts(
    queryClient,
    productIdsToFetch,
    hasProductsToFetch
  );

  const [draft, setDraft] = useState<UsageItemDraft[]>([]);

  useEffect(() => {
    setTimeout(() => {
      if (firstInputRef.current) {
        firstInputRef.current.focus();
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (activeUsageSchedule && products) {
      const productsMap = products.reduce((map, product) => {
        map.set(product.id, product);
        return map;
      }, new Map<string, Product>());

      const usageItems = activeUsageSchedule.billingItems
        .filter(
          (item) =>
            item.product.id &&
            productsMap.get(item.product.id)?.recurrence === 'usage'
        )
        .map<UsageItemDraft>((item) => {
          const product = productsMap.get(item.product.id!)!;
          return {
            billingItemId: item.id,
            quantity: item.latestProductUsage?.quantity || 0,
            originalQuantity: item.latestProductUsage?.quantity || 0,
            productName: product.name!,
            itemName: item.name,
          };
        });

      usageItems.sort((a, b) => a.productName.localeCompare(b.productName));
      setDraft(usageItems);
    }
  }, [activeUsageSchedule, products]);

  const handleCreateSuccess = () => {
    if (contract.buyer.name) {
      showToast.info(`${contract.buyer.name} updated`);
    }
    onSaveSuccess();
  };

  const handleCreateError = () => {
    showToast.error(
      'The deadline has passed for sending the invoice for this billing period. Please contact your administrator for further assistance.'
    );
  };

  const { mutate: createProductUsage } = useCreateProductUsage(
    contract.id,
    handleCreateSuccess,
    handleCreateError,
    queryClient
  );

  const updateQuantity = (
    newQuantity: number | undefined,
    itemIndex: number
  ) => {
    setDraft((prev) => {
      return prev.map((item, i) =>
        i === itemIndex ? { ...item, quantity: newQuantity } : item
      );
    });
  };

  const productUsageRequests = draft
    .filter((item) => item.quantity !== item.originalQuantity)
    .filter((usage) => isNumber(usage.quantity) && usage.quantity >= 0)
    .map((item) => ({
      billingItemId: item.billingItemId,
      quantity: item.quantity,
    })) as ProductUsageRequest[];

  const isFormValid = productUsageRequests.length > 0;

  const submit = () => {
    createProductUsage(productUsageRequests);
  };

  const submitIfValid = () => {
    if (isFormValid) {
      submit();
    }
  };

  if (!contract) {
    return null;
  }

  return (
    <>
      <div className="px-6 pb-6">
        <div className="pb-4 text-xs border border-t-0 border-x-0 border-gray-lines">
          <div className="flex items-center">
            <div>Subscription:</div>
            <div className="pl-2 font-bold">
              <IDField documentID={contract.contractNumber}>
                {contract.contractNumber}
              </IDField>
            </div>
            <button
              type="button"
              onClick={() => navigate(`/subscriptions/${contract.id}`)}
              className="px-3 py-2 bg-white rounded text-blue"
            >
              View
            </button>
          </div>

          <div>
            {tk('Bill to')}
            {': '}
            <span className="font-bold">{contract.buyer.name}</span>
          </div>

          <div>
            {tk('Email')}
            {': '}
            {contract.buyer.billingContact?.email}
          </div>

          <div>
            {tk('Billed')} {getBillingPeriodLabelAdverb(contract.billingPeriod)}{' '}
            {tk('via')} {contract.billingMethod?.name}
          </div>

          <div>
            {tk('Due date')}{' '}
            {activeUsageSchedule && (
              <>{formatDateOrDefault(activeUsageSchedule.dueDate)}</>
            )}
          </div>
        </div>

        <div className="mt-4">
          {draft.map((item, itemIndex) => (
            <div key={item.billingItemId}>
              <ProductUsageField
                ref={itemIndex === 0 ? firstInputRef : undefined}
                editable={editable}
                onPressEnter={submitIfValid}
                quantity={item.quantity}
                productName={item.itemName || item.productName}
                onChange={(newQuantity) =>
                  updateQuantity(newQuantity, itemIndex)
                }
              />
            </div>
          ))}
        </div>

        <div className="flex justify-between gap-2 pt-4">
          {editable && (
            <>
              <Button
                type="secondary"
                label="Cancel"
                className="w-full h-full"
                onClick={onClose}
              />
              <Button
                label="Update"
                onClick={submit}
                isDisabled={!isFormValid}
                className="w-full h-full"
              />
            </>
          )}
        </div>
      </div>
      {activeUsageSchedule && products && (
        <BillingScheduleUpdateLogs
          billingSchedule={activeUsageSchedule}
          products={products}
        />
      )}
    </>
  );
};

interface HandlerProps {
  billingScheduleId?: string;
  contract?: Contract;
  isOpen: boolean;
  onClose: () => void;
  onSaveSuccess: () => void;
}

// trying out a pattern like this to handle the weirdness that comes components
// inside Drawers always being rendered even when it doesn't have the data to do
// what it needs to do
const NoIdHandler: React.FC<HandlerProps> = (props: HandlerProps) => {
  let body = null;

  if (props.isOpen && props.contract && props.billingScheduleId) {
    const { isOpen, contract, billingScheduleId, ...childProps } = props;
    body = (
      <BillingScheduleForm
        {...childProps}
        billingScheduleId={props.billingScheduleId}
        contract={props.contract}
      />
    );
  }

  return (
    <Drawer
      noPaddingX
      onClose={props.onClose}
      isOpen={props.isOpen}
      header="Usage entry"
    >
      {body}
    </Drawer>
  );
};

export default NoIdHandler;
