import React, { useState } from 'react';
import {
  BillingScheduleSyncSummary,
  Button,
  ConnectorInfo,
  ErrorCell,
  formatDateOrDefault,
  formatDatetimeOrDefault,
  getErrorMessage,
  Icon,
  PopOutMenu,
  PopOutMenuOption,
  QueryCell,
  QueryTable,
  SyncEventDetail,
  ToolTip,
  useFlags,
  useToast,
} from 'common';
import { ENVIRONMENT_CONFIG } from 'app/config/hosts';
import { Link } from 'react-router-dom';
import { useSyncInvoice } from '../../../../services/contract';
import { useQueryClient } from '@tanstack/react-query';
import { apiClient } from '../../../../services/httpClients/app';
import IDField from 'app/src/components/IDField';
import StatusLabel from 'app/src/components/StatusLabel';
import {
  getLatestEvent,
  reconciliationFilters,
} from '../../../../services/reoconcile';
import { useResyncSelectedSchedules } from 'app/src/services/quickbooks';
import { useIsSuperAdmin } from 'app/src/core-utils/helperFunctions/userServiceHelper';

interface Props {
  connectorName?: ConnectorInfo['source'];
}

const InvoiceReconcile = ({ connectorName }: Props) => {
  const queryClient = useQueryClient();
  const showToast = useToast();
  const { invoices, quickbooksResyncFailedInvoices } = useFlags();
  const isSuperAdmin = useIsSuperAdmin();

  const handleSyncSuccess = () => {
    showToast.success('Sync started');
  };

  const handleSyncError = (error: unknown) => {
    const msg = getErrorMessage(error);
    showToast.error('Sync failed: ' + msg);
  };
  const { mutate: syncInvoice } = useSyncInvoice(
    'all',
    handleSyncSuccess,
    handleSyncError,
    queryClient
  );

  const { mutate: resyncSelectedSchedules } = useResyncSelectedSchedules(
    handleSyncSuccess,
    handleSyncError
  );

  const getLink = (data: BillingScheduleSyncSummary) => {
    const event = getLatestEvent(data);
    if (event && event.source === connectorName) {
      const href = !ENVIRONMENT_CONFIG.isProduction
        ? 'https://app.sandbox.qbo.intuit.com/app/invoice?txnId=' +
          event.externalId
        : 'https://app.qbo.intuit.com/app/invoice?txnId=' + event.externalId;
      return (
        <div className="underline text-blue">
          <Link to={href}>{event.externalId}</Link>
        </div>
      );
    }
    return null;
  };

  const cells: QueryCell<BillingScheduleSyncSummary>[] = [
    {
      renderCell: (data: BillingScheduleSyncSummary) => (
        <div className="underline text-blue">
          <Link
            to={`/customers/${data.customerReference?.id}`}
            className=" block truncate"
          >
            {data.customerReference?.name}
          </Link>
        </div>
      ),
      key: 'contract.buyer.name',
      headerLabel: 'Customer name',
      sortable: true,
      overflowCell: true,
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) => {
        return (
          <>
            {invoices && (
              <div className="underline text-blue">
                <IDField documentID={data.invoiceReference?.name}>
                  <Link to={`/invoices/${data.invoiceReference?.id}`}>
                    {data.invoiceReference?.name}
                  </Link>
                </IDField>
              </div>
            )}
            {!invoices && data.invoiceReference?.name}
          </>
        );
      },
      key: 'invoice.invoiceNumber',
      headerLabel: 'Transaction no.',
      width: 180,
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) =>
        formatDateOrDefault(data.createdAt),
      key: 'createdAt',
      headerLabel: 'Created date',
      sortable: true,
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) =>
        formatDateOrDefault(data.updatedAt),
      key: 'updatedAt',
      headerLabel: 'Updated date',
      sortable: true,
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) => <>{getLink(data)}</>,
      key: 'externalLink',
      headerLabel: 'QuickBooks transaction no.',
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) => {
        const eventDetail = getLatestEvent(data);
        return (
          <div>
            <div className="p-1">
              {formatDatetimeOrDefault(eventDetail?.createdAt)}{' '}
            </div>
          </div>
        );
      },
      key: 'Lastsynced',
      headerLabel: 'Last sync date',
      allowWrap: true,
      sortable: true,
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) => {
        const eventDetail: SyncEventDetail | undefined = getLatestEvent(data);
        if (eventDetail?.status === 'success') {
          return <StatusLabel status="success" feature="invoice" />;
        } else if (eventDetail?.status === 'error') {
          return (
            <ErrorCell
              error={eventDetail.error}
              statusLabel={<StatusLabel status="error" feature="invoice" />}
            />
          );
        }
        return 'Not synced';
      },
      key: 'lastEvent.status',
      headerLabel: 'Sync status',
      width: 180,
      align: 'center',
      sortable: true,
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) => {
        const eventDetail = getLatestEvent(data);
        if (eventDetail?.status === 'success') return null;
        return (
          <PopOutMenu
            data-testid="options-button"
            isDisabled={getIsSelectedOption(data.id)}
          >
            <PopOutMenuOption
              title="Sync to ERP"
              onClick={() => (data.id ? syncInvoice(data.id) : null)}
              icon={Icon.Repeat}
            />
          </PopOutMenu>
        );
      },
      key: 'options',
      headerLabel: 'Options',
      width: 64,
    },
  ];

  const getIsSelectedOption = (id: string) => {
    return selectedRowKeys.includes(id);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  type RowSelection = NonNullable<
    React.ComponentProps<
      typeof QueryTable<BillingScheduleSyncSummary>
    >['rowSelection']
  >;

  const onSelectChange: RowSelection['onChange'] = (
    selectedKeys,
    selectedRows
  ) => {
    const hasSuccess = selectedRows.some(
      (row: BillingScheduleSyncSummary) =>
        !!row.eventDetails?.some(
          (detail: SyncEventDetail) => detail.status === 'success'
        )
    );
    setIsDisabled(hasSuccess || selectedKeys.length > 10);
    setSelectedRowKeys(selectedKeys.map(String));
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const syncTooltipMessage = (
    isSuccessSelected: boolean,
    selectionCount: number
  ) => {
    if (selectionCount && selectionCount > 10) {
      return 'You can select up to a maximum of 10 records for syncing';
    } else if (isSuccessSelected) {
      return 'This functionality is not available for invoices that have been successfully synced';
    }
    return '';
  };

  return (
    <div className="flex flex-col">
      {(isSuperAdmin || quickbooksResyncFailedInvoices) && (
        <div className="flex flex-row justify-end">
          <ToolTip
            title={syncTooltipMessage(isDisabled, selectedRowKeys.length)}
          >
            <Button
              isDisabled={isDisabled}
              label="Sync to ERP"
              onClick={() => {
                selectedRowKeys.length > 0 &&
                  resyncSelectedSchedules(selectedRowKeys);
              }}
            />
          </ToolTip>
        </div>
      )}
      <QueryTable<BillingScheduleSyncSummary>
        cells={cells}
        fetchList={apiClient.getEventsByBillingSchedule}
        filters={reconciliationFilters(false, [
          {
            field: 'invoice.invoiceNumber',
            operation: 'contains',
          },
        ])}
        getRowClassName={() => 'text-xs'}
        persistInUrl={false}
        queryKey="getEventsByBillingSchedule"
        rowSelection={quickbooksResyncFailedInvoices ? rowSelection : undefined}
        tooltipText="Search for Cacheflow invoice number"
        zeroStateMessage="There are currently no invoices."
      />
    </div>
  );
};
export default InvoiceReconcile;
