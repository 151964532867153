import React, { useEffect, useState } from 'react';

import { CreateProposalRequest, Customer, Modal, Proposal } from 'common';
import CustomerFormInline from '../../../Sales/Customers/CustomerFormInline';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCoreChange: (updateProposalRequest: CreateProposalRequest) => void;
  proposal: Proposal;
}

export const SubscriptionCreateModal = ({
  isOpen,
  proposal,
  onCoreChange,
  onClose,
  onConfirm,
}: Props) => {
  const [customerBeingEdited, setCustomerBeingEdited] = useState<
    Customer | undefined
  >(proposal.customer);

  const [customerStarterName, setCustomerStarterName] = useState<
    string | undefined
  >(customerBeingEdited?.name);

  useEffect(() => {
    setCustomerBeingEdited(proposal.customer);
  }, [customerBeingEdited, isOpen]);

  const onUpdateCustomer = (
    customer: Customer | undefined,
    currency?: string
  ) => {
    const updateRequest: CreateProposalRequest = {
      customerId: customer?.id || null!,
    };
    if (
      proposal.name === 'Untitled' ||
      (proposal.customer && proposal.customer.name === proposal.name)
    ) {
      updateRequest.name = customer?.name || 'Untitled';
    }

    if (currency) {
      updateRequest.currency = currency;
    }
    if (customer?.billingContact?.id) {
      updateRequest.contactIds = [customer.billingContact.id];
    }

    onCoreChange(updateRequest);
    onConfirm();
  };

  const closeCustomerForm = () => {
    setCustomerBeingEdited(undefined);
    setCustomerStarterName(undefined);
    onClose();
  };

  const handleCreateCustomer = (customer: Customer) => {
    setCustomerBeingEdited(customer);
    setCustomerStarterName(customer.name);
    onUpdateCustomer(customer);
  };

  const handleDeleteCustomer = () => {
    setCustomerBeingEdited(undefined);
    onUpdateCustomer(undefined);
  };

  return (
    <Modal header="Create subscription" isOpen={isOpen} onClose={onClose}>
      <CustomerFormInline
        isDisabled={false}
        customerBeingEdited={customerBeingEdited}
        onCreateCustomer={handleCreateCustomer}
        onCloseForm={closeCustomerForm}
        customerStarterName={customerStarterName}
        onDeleteCustomer={handleDeleteCustomer}
        isInProposalEditor
      />
    </Modal>
  );
};
