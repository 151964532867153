import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';

import {
  Button,
  ButtonBar,
  Card,
  CardHeader,
  FormField,
  HighlightPill,
  Input,
  InvoiceConfig as InvoiceSettingsConfigValue,
  isDefined,
  Loading,
  noop,
  NumberInput,
  OrgConfig,
  PaymentGracePeriodConfig,
  Select,
  ToggleSwitch,
  useFlags,
  useToast,
  useTranslation,
} from 'common';

import {
  useFindOrgDefault,
  useSetOrgConfigDefault,
} from '../../../services/orgDefaults';
import RichTextInput from '../../../components/RichTextEditor/RichTextInput';
import {
  AdvanceDateOptions,
  INVOICE_ADVANCE_DATE_OPTIONS,
} from 'app/src/core-utils/helperFunctions/types';
import { GracePeriodSection } from './GracePeriod/GracePeriodSection';

import styles from './InvoiceSettings.module.scss';

const INVOICE_CONFIG_KEY: OrgConfig['configKey'] = 'invoiceConfig';
const GRACE_PERIOD_CONFIG_KEY: OrgConfig['configKey'] = 'paymentsGracePeriod';

const InvoiceSettings: React.FC = () => {
  const [draft, setDraft] = useState<InvoiceSettingsConfigValue>({});

  const [gracePeriodState, setGracePeriodState] =
    useState<PaymentGracePeriodConfig>({
      enabled: false,
      defaultGracePeriodInDays: 0,
    });

  const { invoiceInAdvance: invoiceInAdvanceFlag } = useFlags();
  const [savableChanges, setSavableChanges] = useState(false);

  const showToast = useToast();
  const queryClient = useQueryClient();
  const { tk } = useTranslation();

  const { data: invoiceConfig, isLoading: isInvoiceLoading } =
    useFindOrgDefault(INVOICE_CONFIG_KEY);

  const { data: gracePeriodConfig, isLoading: isGracePeriodLoading } =
    useFindOrgDefault(GRACE_PERIOD_CONFIG_KEY);

  const { mutate, isPending: isSaving } = useSetOrgConfigDefault(
    INVOICE_CONFIG_KEY,
    () => showToast.info(tk('Setting saved.')),
    () => showToast.error(tk('Failed to save setting. Please try again.')),
    queryClient
  );

  const { mutate: mutateGracePeriodConfig } = useSetOrgConfigDefault(
    GRACE_PERIOD_CONFIG_KEY,
    () => noop,
    () => noop,
    queryClient
  );

  useEffect(() => {
    if (invoiceConfig) {
      const configValueIn: InvoiceSettingsConfigValue | undefined =
        invoiceConfig.configValue;

      if (configValueIn) {
        setDraft(configValueIn);
      }
    }
  }, [invoiceConfig]);

  useEffect(() => {
    if (
      gracePeriodConfig &&
      typeof gracePeriodConfig.configValue === 'object'
    ) {
      setGracePeriodState(gracePeriodConfig.configValue);
    }
  }, [gracePeriodConfig]);

  const isFormValid = () => {
    if (savableChanges) {
      if (draft.invoiceInAdvanceEnabled) {
        return draft.invoiceInAdvanceDays !== undefined;
      }
      return true;
    }
    return false;
  };

  const handleGracePeriodChanges = (
    field: keyof PaymentGracePeriodConfig,
    value: boolean | number | string | undefined
  ) => {
    const newState: PaymentGracePeriodConfig = {
      ...gracePeriodState,
      [field]: value,
    };
    setSavableChanges(true);
    setGracePeriodState(newState);
  };

  const saveConfig = () => {
    if (isFormValid()) {
      mutate({
        configValue: {
          ...draft,
          configType: INVOICE_CONFIG_KEY,
        },
      });
    }

    mutateGracePeriodConfig({
      configValue: {
        ...gracePeriodState,
      },
    });
    setSavableChanges(false);
  };

  if (
    isInvoiceLoading ||
    isGracePeriodLoading ||
    !invoiceConfig ||
    !gracePeriodConfig
  ) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader name="Invoice settings" />

      <p className="font-bold pt-2">Invoice email</p>
      <FormField label="Automatically email invoice to customer on invoice date for subscriptions with external payment methods">
        <div className="flex gap-4 pl-4">
          <ToggleSwitch
            label="Send email"
            name="sendInvoiceEmailNonManaged"
            onChange={(newValue) => {
              setDraft((prev) => ({
                ...prev,
                sendInvoiceEmailNonManaged: newValue,
              }));
              setSavableChanges(true);
            }}
            value={!!draft.sendInvoiceEmailNonManaged}
          />
          <ToggleSwitch
            isDisabled={!draft.sendInvoiceEmailNonManaged}
            label="Attach invoice PDF"
            name="attachPdfToInvoiceEmailNonManaged"
            onChange={(newValue) => {
              setDraft((prev) => ({
                ...prev,
                attachPdfToInvoiceEmailNonManaged: newValue,
              }));
              setSavableChanges(true);
            }}
            value={!!draft.attachPdfToInvoiceEmailNonManaged}
          />
        </div>
      </FormField>

      <p className="font-bold pt-2">Payment receipt email</p>
      <FormField
        label="Automatically email payment receipt to customer on payment for
        subscriptions with external payment methods"
      >
        <div className="flex gap-4 pl-4">
          <ToggleSwitch
            label="Send email"
            name="sendPaymentReceiptEmailNonManaged"
            onChange={(newValue) => {
              setDraft((prev) => ({
                ...prev,
                sendPaymentReceiptEmailNonManaged: newValue,
              }));
              setSavableChanges(true);
            }}
            value={!!draft.sendPaymentReceiptEmailNonManaged}
          />
          <ToggleSwitch
            isDisabled={!draft.sendPaymentReceiptEmailNonManaged}
            label="Attach invoice PDF"
            name="attachPdfToPaymentReceiptNonManaged"
            onChange={(newValue) => {
              setDraft((prev) => ({
                ...prev,
                attachPdfToPaymentReceiptNonManaged: newValue,
              }));
              setSavableChanges(true);
            }}
            value={!!draft.attachPdfToPaymentReceiptNonManaged}
          />
        </div>
      </FormField>
      <FormField
        label="Automatically email payment receipt to customer on payment for
        subscriptions with credit card or direct debit as payment method"
      >
        <div className="flex gap-4 pl-4">
          <ToggleSwitch
            label="Send email"
            name="sendPaymentReceiptEmailManaged"
            onChange={(newValue) => {
              setDraft((prev) => ({
                ...prev,
                sendPaymentReceiptEmailManaged: newValue,
              }));
              setSavableChanges(true);
            }}
            value={!!draft.sendPaymentReceiptEmailManaged}
          />
          <ToggleSwitch
            isDisabled={!draft.sendPaymentReceiptEmailManaged}
            label="Attach invoice PDF"
            name="attachPdfToPaymentReceiptManaged"
            onChange={(newValue) => {
              setDraft((prev) => ({
                ...prev,
                attachPdfToPaymentReceiptManaged: newValue,
              }));
              setSavableChanges(true);
            }}
            value={!!draft.attachPdfToPaymentReceiptManaged}
          />
        </div>
      </FormField>

      <p className={styles.sectionLabel}>Invoice prefix</p>
      <FormField label="">
        <Input
          onChange={(newValue) => {
            setDraft((prev) => ({ ...prev, invoicePrefix: newValue }));
            setSavableChanges(true);
          }}
          value={draft.invoicePrefix}
        />
      </FormField>

      <p className={styles.sectionLabel}>Invoice date</p>
      <FormField label="">
        <ToggleSwitch
          label="Set invoice date to current date for backdated billing schedules"
          name="currentDateForBackdatedInvoices"
          onChange={(newValue) => {
            setDraft((prev) => ({
              ...prev,
              currentDateForBackdatedInvoices: newValue,
            }));
            setSavableChanges(true);
          }}
          value={draft.currentDateForBackdatedInvoices}
        />
      </FormField>

      <p className={styles.sectionLabel}>Payment info</p>
      <FormField label="">
        <RichTextInput
          featureSet="invoice.paymentInfo"
          onChange={(newValue) => {
            setDraft((prev) => ({
              ...prev,
              paymentInfo: newValue,
            }));
            setSavableChanges(true);
          }}
          value={draft.paymentInfo}
        />
      </FormField>

      {invoiceInAdvanceFlag && (
        <>
          <div className="flex items-center mb-2 mt-[7px]">
            <p className="font-bold w-[200px]">Enable invoicing in advance</p>
            <HighlightPill className="mt-[2px] ml-[-5px]" label="BETA" />
            <ToggleSwitch
              className="font-bold ml-2"
              name="enableInvoicingInAdvance"
              onChange={(newValue) => {
                setDraft((prev) => ({
                  ...prev,
                  invoiceInAdvanceEnabled: newValue,
                }));
                setSavableChanges(true);
              }}
              value={!!draft.invoiceInAdvanceEnabled}
            />
          </div>

          {draft.invoiceInAdvanceEnabled && (
            <div className={styles.invoiceInAdvance}>
              <FormField className={styles.formField} label="">
                <ToggleSwitch
                  label="Default invoicing in advance on new proposals"
                  name="invoiceInAdvanceProposalDefault"
                  onChange={(newValue) => {
                    setDraft((prev) => ({
                      ...prev,
                      invoiceInAdvanceProposalDefault: newValue,
                    }));
                    setSavableChanges(true);
                  }}
                  value={!!draft.invoiceInAdvanceProposalDefault}
                />
              </FormField>

              <FormField label="Days in advance">
                <NumberInput
                  className={clsx(
                    styles.input,
                    !isDefined(draft.invoiceInAdvanceDays) && styles.inputError
                  )}
                  min={1}
                  onChange={(newValue) => {
                    setDraft((prev) => ({
                      ...prev,
                      invoiceInAdvanceDays: newValue,
                    }));
                    setSavableChanges(true);
                  }}
                  type="number"
                  value={draft.invoiceInAdvanceDays}
                />
              </FormField>

              <FormField label="Set invoice date to">
                <Select
                  className={styles.input}
                  onChange={(newValue: AdvanceDateOptions) => {
                    setDraft((prev) => ({
                      ...prev,
                      invoiceInAdvanceDateOption: newValue,
                    }));
                    setSavableChanges(true);
                  }}
                  options={INVOICE_ADVANCE_DATE_OPTIONS}
                  value={draft.invoiceInAdvanceDateOption}
                />
              </FormField>
            </div>
          )}
        </>
      )}

      <GracePeriodSection
        gracePeriodState={gracePeriodState}
        onChange={handleGracePeriodChanges}
      />

      <div className={styles.invoiceToggleContainer}>
        <p className={styles.heading}>Show invoice balances on Invoice PDF</p>
        <ToggleSwitch
          className={styles.toggle}
          name="showInvoiceBalances"
          onChange={(newValue) => {
            setDraft((prev) => ({
              ...prev,
              showInvoiceBalances: newValue,
            }));
            setSavableChanges(true);
          }}
          value={!!draft.showInvoiceBalances}
        />
      </div>

      <div className={styles.invoiceToggleContainer}>
        <p className={styles.heading}>
          Show payments transactions on Invoice PDF
        </p>
        <ToggleSwitch
          className={styles.toggle}
          name="showPaymentTransactions"
          onChange={(newValue) => {
            setDraft((prev) => ({
              ...prev,
              showPaymentTransactions: newValue,
            }));
            setSavableChanges(true);
          }}
          value={!!draft.showPaymentTransactions}
        />
      </div>

      <ButtonBar>
        <Button
          isDisabled={!isFormValid()}
          isLoading={isSaving}
          label="Save"
          onClick={saveConfig}
        />
      </ButtonBar>
    </Card>
  );
};

export default InvoiceSettings;
