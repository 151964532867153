import React from 'react';
import clsx from 'clsx';
import { ProductSummary, Proposal, useTranslation } from 'common';
import { getProductPricingSummary } from '../../../Sales/Products/ProductsList/productUtils';
import { getProposalProductValidationMessage } from '../../../../core-utils/helperFunctions/productUtils';
import Pill from './Pill';
import styles from './ProductOption.module.scss';

interface Props {
  option: ProductSummary;
  proposal?: Proposal;
}

const ProductOption: React.FC<Props> = ({ option, proposal }) => {
  const { tk } = useTranslation();

  const validationMessage = getProposalProductValidationMessage(
    option,
    proposal
  );

  const getPill = () => {
    if (!proposal) {
      return null;
    }

    if (validationMessage) {
      return <Pill label={tk(validationMessage)} type="error" isDisabled />;
    }

    if (option.productType?.includes('bundle')) {
      return <Pill label="Bundle" type="info" />;
    }

    return null;
  };

  const classes = clsx(
    styles.productOption,
    !validationMessage && styles.isValid
  );

  return (
    <div className={classes}>
      <p className={styles.productName}>{option.name}</p>

      {option.internalName && option.internalName !== option.name && (
        <p className={styles.productDetail}>{option.internalName}</p>
      )}

      <p className={styles.productDetail}>{getProductPricingSummary(option)}</p>

      {getPill()}
    </div>
  );
};

export default ProductOption;
