import React from 'react';
import {
  assertDefined,
  isNumber,
  Item,
  Money,
  MoneyInput,
  NumberInput,
  useFlags,
  useTranslation,
} from 'common';
import { calcDiscountedPrice, calcPriceDiscount } from '../proposalUtilities';
import styles from './ListPriceSalesPriceInput.module.scss';

interface Props {
  basePrice: Money;
  currentListPrice: Money;
  currentPrice: Money;
  id: string;
  isSaving: boolean;
  item: Item;
  onPriceChange: (listPrice: Money, salesPrice: Money) => void;
  timeBasedPricing?: boolean;
}

const ListPriceSalesPriceInput = ({
  id,
  item,
  basePrice,
  currentListPrice,
  currentPrice,
  onPriceChange,
  isSaving,
  timeBasedPricing,
}: Props) => {
  const { tk } = useTranslation();
  const product = assertDefined(item.product);
  const allowListPriceAdjustments = product.listPriceAdjustable;
  const { negativePriceSupport } = useFlags();

  const setPercentOff = (
    value: number,
    priceOverride: Money | undefined,
    unitPrice: Money
  ) => {
    const amount = calcDiscountedPrice(value, unitPrice.amount);
    const newPrice = {
      ...priceOverride,
      amount,
    };
    onPriceChange(unitPrice, newPrice);
  };

  const getHeaderLabel = (label: string) => {
    return timeBasedPricing ? null : (
      <div className={styles.headerLabel}>{tk(label)}</div>
    );
  };
  return (
    <div className={styles.listPriceSalesPriceInput} id={id}>
      <div>
        {getHeaderLabel('List price')}
        <MoneyInput
          dataTestId={`list-price-input-${id}`}
          defaultCurrency={basePrice.currency}
          defaultScale={basePrice.scale}
          hideCurrency={timeBasedPricing}
          isDisabled={!allowListPriceAdjustments || isSaving}
          negativeSupported={negativePriceSupport}
          onBlur={(value) => onPriceChange(value, currentPrice)}
          value={currentListPrice}
        />
      </div>
      <div>
        {getHeaderLabel('List adj.')}
        <NumberInput
          dataTestId={`discount-percent-input-${id}`}
          isDisabled={isSaving}
          key={`${currentListPrice.amount}:${currentPrice.amount}`}
          min={0}
          onBlur={(value) => {
            if (isNumber(value)) {
              setPercentOff(value, currentPrice, currentListPrice);
            }
          }}
          suffix="%"
          value={calcPriceDiscount(
            currentPrice.amount,
            currentListPrice.amount
          )}
        />
      </div>
      <div>
        {getHeaderLabel('Sales price')}
        <MoneyInput
          dataTestId={`discount-input-${id}`}
          defaultCurrency={basePrice.currency}
          defaultScale={basePrice.scale}
          hideCurrency={timeBasedPricing}
          isDisabled={isSaving}
          key={`${currentListPrice.amount}:${currentPrice.amount}`}
          negativeSupported={negativePriceSupport}
          onBlur={(value) => onPriceChange(currentListPrice, value)}
          value={currentPrice}
        />
      </div>
    </div>
  );
};

export default ListPriceSalesPriceInput;
