import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { ErrorFetching, Loading, NavigationIcon } from 'common';

import { Page } from 'app/src/components';
import { PAYMENTS } from 'app/src/core-utils/routes';
import { useGetRefund } from 'app/src/services/api/refunds/refunds';
import { useInvoice } from 'app/src/services/api/invoices/invoiceList';
import { useCompanyInfo } from 'app/src/services/api/branding/companyInfo';

import { RefundSummaryCards } from '../components/RefundSummaryCards';
import { RefundActionsHeader } from '../components/RefundActionsHeader';
import { useRefundActivityDrawer } from '../hooks/useRefundActivityDrawer';
import { PaymentInvoicesCard } from '../../Payments/Details/PaymentInvoicesCard';
import { RefundActivityDrawer } from '../components/RefundActivityDrawer/RefundActivityDrawer';

import styles from './RefundDetailsPage.module.scss';

export const RefundDetailsPage = () => {
  const { id: refundId } = useParams<{ id: string }>();

  const { data: companyInfo, isLoading: isCompanyInfoLoading } =
    useCompanyInfo();

  const { data: refundInfo, isLoading: isRefundInfoLoading } = useGetRefund(
    refundId ?? ''
  );

  const { data: invoice, isLoading: isInvoiceLoading } = useInvoice(
    refundInfo?.transfer.parentReferenceId ?? ''
  );

  const {
    refundEvents,
    isRefundActivityDrawerOpen,
    openRefundActivityDrawer,
    closeRefundActivityDrawer,
  } = useRefundActivityDrawer(refundInfo?.id ?? '');

  if (isCompanyInfoLoading || isRefundInfoLoading || isInvoiceLoading) {
    return <Loading />;
  }

  if (!companyInfo || !refundInfo || !invoice) {
    return <ErrorFetching />;
  }

  return (
    <Page
      leftWidget={
        <div className={styles.headerContainer}>
          <Link to={PAYMENTS}>
            <NavigationIcon.Refund />
          </Link>
          <div className={styles.rightHeader}>
            <div>{refundInfo.customer?.name}</div>
            <div
              className={styles.subtext}
            >{`Refund Id: ${refundInfo.id}`}</div>
          </div>
        </div>
      }
      rightWidget={
        <RefundActionsHeader
          invoice={invoice}
          openActivityDrawer={openRefundActivityDrawer}
          refund={refundInfo}
        />
      }
    >
      <RefundSummaryCards refund={refundInfo} />

      <div className={styles.transactionsTable}>
        <PaymentInvoicesCard invoices={[invoice]} />
      </div>

      <RefundActivityDrawer
        isOpen={isRefundActivityDrawerOpen}
        onClose={closeRefundActivityDrawer}
        refundEvents={refundEvents}
      />
    </Page>
  );
};
