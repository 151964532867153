import { useQueryClient } from '@tanstack/react-query';
import {
  arrayTop,
  Button,
  ButtonBar,
  Card,
  Config,
  ExternalFieldMapping,
  Flags,
  getErrorMessage,
  HubspotConfig,
  Icon,
  NavigationIcon,
  OrgConfig,
  PopOutMenu,
  PopOutMenuOption,
  SalesforceConfig,
  spreadIf,
  StageMapping,
  Tabs,
  useFlags,
  useToast,
  useTranslation,
} from 'common';
import React, { SVGProps, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENVIRONMENT_CONFIG } from '../../../../config/hosts';
import { useAuth } from '../../../Auth';
import { SandboxLabel } from '../../../components';

import { ConnectorTabs } from '../../../core-utils/enums/ConnectorTabs';
import { useIsSuperAdmin } from '../../../core-utils/helperFunctions/userServiceHelper';
import { INTEGRATIONS_AND_CONNECTORS } from '../../../core-utils/routes';
import {
  ConnectResult,
  disableConnectorConfig,
  useGetConnectionTest,
  useRefreshAuthConnector,
} from '../../../services/connectors';
import {
  useFindOrgDefault,
  useSetOrgConfigDefault,
} from '../../../services/orgDefaults';
import {
  AccountContactsConfig,
  ConnectorConfiguration,
  ConnectorInfoObject,
} from './ConnectorConstants/constants';
import ConnectorError from './ConnectorError';
import ConnectorStatus from './ConnectorStatus';

import AccountContacts from './ConnectorTabs/AccountContacts';
import ConnectorConfigurationEditor from './ConnectorTabs/ConnectorConfigurationEditor';
import ConnectorConnection from './ConnectorTabs/ConnectorConnection';
import ConnectorEvents from './ConnectorTabs/ConnectorEvents';
import ConnectorProposalEquivalent from './ConnectorTabs/ConnectorProposalEquivalent';
import ConnectorReconciliation from './ConnectorTabs/ConnectorReconciliation';
import './connectorStyles.css';
import LogoCardHeader from './LogoCardHeader';
import ProposalFieldMappings from './ProposalFieldMappings';
import CloseForm from '../Close/CloseForm';
import ConnectorLocation from './ConnectorLocation';

export interface ConnectorTab {
  flag?: keyof Flags;
  key: string;
  label: string;
  systemOnly: boolean;
}

interface Props {
  accountContactsConfig: AccountContactsConfig;
  configuration?: ConnectorConfiguration;
  connectorInfo: ConnectorInfoObject;
  logo?: React.ComponentType<SVGProps<SVGSVGElement>>;
  LogoElement?: React.ReactElement;
  tabs: ConnectorTab[];
}

const ConnectorSettings = ({
  connectorInfo,
  configuration,
  accountContactsConfig,
  tabs,
  logo,
  LogoElement,
}: Props) => {
  const navigate = useNavigate();
  const flags = useFlags();
  const [activeTab, setActiveTab] = useState<string>(
    arrayTop(tabs)?.key ?? ConnectorTabs.configuration
  );
  const [savableChanges, setSavableChanges] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [syncSubscriptionsDraft, setSyncSubscriptionsDraft] = useState(false);
  const [syncSignedDocumentDraft, setSyncSignedDocumentDraft] = useState(false);
  const [connectorStageMappings, setConnectorStageMappings] = useState<
    StageMapping[]
  >([]);
  const [proposalFieldMappings, setProposalFieldMappings] = useState<
    ExternalFieldMapping[]
  >([]);

  const [connectorConfigValue, setConnectorConfigValue] = useState<
    OrgConfig['configValue']
  >({});
  const [isConfigLoading, setIsConfigLoading] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<string | undefined>(undefined);
  const showToast = useToast();
  const queryClient = useQueryClient();
  const { tk } = useTranslation();
  const hasConnectionTab =
    tabs.filter((tab) => tab.key === ConnectorTabs.connection).length > 0;
  const {
    data: connectorData,
    isError,
    error,
  } = useFindOrgDefault(connectorInfo.configType);

  const { data: connectorTestResult } = useGetConnectionTest(
    connectorInfo.source
  );

  const { mutate: refreshAuth } = useRefreshAuthConnector(
    connectorInfo.source,
    () => {
      showToast.success(tk('Token refreshed'));
    },
    () => {
      showToast.error(tk('Failed to authenticate. Please try again!'));
    }
  );

  useEffect(() => {
    if (connectorData?.configKey === 'hubspotConfig') {
      const hsConfigValue: HubspotConfig =
        connectorData.configValue as HubspotConfig;

      if (
        hsConfigValue.proposalFieldMappings &&
        hsConfigValue.proposalFieldMappings.length > 0
      ) {
        setProposalFieldMappings(hsConfigValue.proposalFieldMappings);
      }
    }
  }, [connectorData?.configValue]);

  const auth = useAuth();
  const showSystemTab = useIsSuperAdmin() || !ENVIRONMENT_CONFIG.isProduction;

  useEffect(() => {
    if (connectorData) {
      const configValue: OrgConfig['configValue'] = connectorData.configValue;
      // Previously set, but now removed mappings should be excluded
      // TODO: remove the key check once the hubspot connector is g2g
      const stageMappings =
        configValue && 'stageMappings' in configValue
          ? configValue.stageMappings?.filter(
              (mapping: StageMapping) => !!mapping.toStage
            )
          : [];

      setConnectorStageMappings(stageMappings || []);

      let enabledConf = enabled;

      if (Object.getOwnPropertyDescriptor(configValue, 'enabled')) {
        enabledConf = !!(
          configValue &&
          'enabled' in configValue &&
          configValue.enabled
        );
        setEnabled(enabledConf);
      }
      if (enabledConf && connectorTestResult) {
        enabledConf =
          connectorTestResult.status === 'active' &&
          (!connectorTestResult.issues ||
            connectorTestResult.issues.length === 0);
        setEnabled(enabledConf);
      }
      if (!enabledConf) {
        hasConnectionTab && currentTab
          ? setActiveTab(currentTab)
          : hasConnectionTab
            ? setActiveTab(ConnectorTabs.connection)
            : setActiveTab(ConnectorTabs.configuration);
      }

      // @ts-ignore TODO: remove the ts-ignore once syncSubscriptions is not just on Salesforce
      setSyncSubscriptionsDraft(configValue?.syncSubscriptions || false);

      // @ts-ignore TODO: remove the ts-ignore once syncSignedDocument is not just on Salesforce
      setSyncSignedDocumentDraft(configValue?.syncSignedDocument || false);

      setConnectorConfigValue(configValue);
      setIsConfigLoading(false);
    }

    if (connectorData === null) {
      setIsConfigLoading(false);
    }
  }, [connectorData, connectorTestResult]);

  const getTabContent = () => {
    switch (activeTab) {
      case ConnectorTabs.configuration:
        return (
          <ConnectorConfigurationEditor
            connectorInfo={connectorInfo}
            configuration={configuration!}
            setSaveableChanges={setSavableChanges}
            connectorConfigValue={connectorConfigValue}
            setConnectorConfigValue={setConnectorConfigValue}
          />
        );
      case ConnectorTabs.proposalFieldMappings:
        return (
          <ProposalFieldMappings
            fieldMappings={proposalFieldMappings}
            setFieldMappings={setProposalFieldMappings}
            setSaveableChanges={setSavableChanges}
            connectorInfo={connectorInfo}
          />
        );
      case ConnectorTabs.opportunities:
      case ConnectorTabs.proposalStageMappings:
        return (
          <ConnectorProposalEquivalent
            setNewConnectorMapping={setConnectorStageMappings}
            newConnectorMapping={connectorStageMappings}
            setSaveableChanges={setSavableChanges}
            connectorInfo={connectorInfo}
            configuration={configuration}
            connectorConfigValue={connectorConfigValue as SalesforceConfig}
            setConnectorConfigValue={setConnectorConfigValue}
          />
        );
      case ConnectorTabs.accountContacts:
        return (
          <AccountContacts
            accountContactsConfig={accountContactsConfig}
            setConnectorConfigValue={setConnectorConfigValue}
            setSavableChanges={setSavableChanges}
            connectorConfigValue={connectorConfigValue}
          />
        );
      case ConnectorTabs.status:
        return <ConnectorStatus connectorName={connectorInfo.configType} />;
      case ConnectorTabs.events:
        return <ConnectorEvents connectorName={connectorInfo.source} />;
      case ConnectorTabs.locationCode:
        return (
          <ConnectorLocation
            setSavableChanges={setSavableChanges}
            connectorConfigValue={connectorConfigValue}
            setConnectorConfigValue={setConnectorConfigValue}
          />
        );
      case ConnectorTabs.reconciliation:
        return <ConnectorReconciliation connectorName={connectorInfo.source} />;
      case ConnectorTabs.connection:
        return (
          <ConnectorConnection
            isLoading={isConfigLoading}
            connectorName={connectorInfo.source}
            connectorConfigValue={connectorConfigValue}
            setSavableChanges={setSavableChanges}
            syncSubscriptions={
              // @ts-ignore TODO: remove the ts-ignore once the syncSubscriptions is universal
              connectorData?.configValue?.syncSubscriptions || false
            }
            syncSubscriptionsDraft={syncSubscriptionsDraft}
            setSyncSubscriptionsDraft={setSyncSubscriptionsDraft}
            setConnectorConfigValue={setConnectorConfigValue}
            syncSignedDocument={
              // @ts-ignore TODO: remove the ts-ignore once the syncSignedDocument is universal
              connectorData?.configValue?.syncSignedDocument || false
            }
            syncSignedDocumentDraft={syncSignedDocumentDraft}
            setSyncSignedDocumentDraft={setSyncSignedDocumentDraft}
          />
        );
      case ConnectorTabs.closeConfiguration:
        return (
          <CloseForm
            isLoading={isConfigLoading}
            setSaveableChanges={setSavableChanges}
            connectorConfigValue={connectorConfigValue}
            setConnectorConfigValue={setConnectorConfigValue}
          />
        );
      default:
        return null;
    }
  };

  const { mutate: updateConnectorConfig } = useSetOrgConfigDefault(
    connectorInfo.configType,
    () => {
      showToast.info(tk('Connector settings saved.'));
    },
    (err: unknown) => {
      const errorMessage = getErrorMessage(err);
      showToast.error(
        connectorInfo.configType === 'avalaraConfig'
          ? 'Invalid credentials! We are not able to authenticate the account in Avalara. Please enter valid credentials.'
          : tk(
              errorMessage ??
                'Failed to save Connector settings. Please try again.'
            )
      );
    },
    queryClient
  );

  const saveConnectorConfig = () => {
    const configValueObj: OrgConfig['configValue'] = {
      configType: connectorInfo.name as Config['configType'],
      ...connectorConfigValue,
    };

    updateConnectorConfig({ configValue: configValueObj });
    setSavableChanges(false);
  };

  const saveMapping = () => {
    const mapping: OrgConfig['configValue'] = {
      ...connectorConfigValue,
      configType: connectorInfo.configType as Config['configType'],
      enabled: configuration?.toggleSwitchForEnablement ? enabled : true,
      syncSubscriptions: syncSubscriptionsDraft,
      proposalFieldMappings: [...proposalFieldMappings],
      stageMappings: [...connectorStageMappings],
      ...spreadIf(connectorInfo.source === 'salesforce', {
        syncSignedDocument: syncSignedDocumentDraft,
      }),
    };

    updateConnectorConfig({ configValue: mapping });
    setSavableChanges(false);
  };

  return (
    <>
      {isError && <ConnectorError error={error || undefined} />}
      <Card>
        <LogoCardHeader
          logo={logo}
          LogoElement={LogoElement}
          name={connectorInfo.name}
        >
          <ButtonBar>
            {hasConnectionTab && (
              <PopOutMenu>
                <PopOutMenuOption
                  onClick={refreshAuth}
                  title={tk('Refresh Token')}
                  icon={Icon.Repeat}
                />

                <PopOutMenuOption
                  onClick={async () => {
                    if (auth.user === undefined || auth.user.id === undefined) {
                      return;
                    }

                    const onClose = (connectResult?: ConnectResult) => {
                      if (connectResult === 'disconnected') {
                        navigate(INTEGRATIONS_AND_CONNECTORS);
                      }
                    };

                    const onError = (e: unknown) => {
                      showToast.error(
                        getErrorMessage(e) ?? tk('There was an error')
                      );
                    };

                    await disableConnectorConfig(
                      connectorInfo.source,
                      auth.user.id,
                      onClose,
                      onError,
                      queryClient
                    );
                  }}
                  title={tk('Disconnect')}
                  icon={NavigationIcon.Account}
                />
              </PopOutMenu>
            )}
            {connectorInfo.showSaveButton && (
              <Button
                label={tk('Save')}
                onClick={() => {
                  activeTab === 'configuration' ||
                  activeTab === 'closeConfiguration'
                    ? saveConnectorConfig()
                    : saveMapping();
                }}
                isDisabled={!savableChanges}
              />
            )}
          </ButtonBar>
        </LogoCardHeader>

        {ENVIRONMENT_CONFIG.env === 'sandbox' ||
          (!ENVIRONMENT_CONFIG.isProduction && (
            <SandboxLabel
              name={tk(
                'Connecting account should be in a test or sandbox mode'
              )}
            />
          ))}

        <Tabs
          tabs={tabs.filter(
            (tab) =>
              (!tab.systemOnly && (!tab.flag || flags[tab.flag])) ||
              showSystemTab
          )}
          onChange={(tab) => {
            setActiveTab(tab);
            setCurrentTab(tab);
          }}
          defaultActiveTab={activeTab}
        />
        <section className="connector-tab-content">{getTabContent()}</section>
        {/* TODO: Implement using a solution here: https://github.com/remix-run/react-router/issues/8139 */}
        {/*<Prompt*/}
        {/*  when={saveableChanges}*/}
        {/*  message={'Are you sure you want to leave the page before saving?'}*/}
        {/*/>*/}
      </Card>
    </>
  );
};

export default ConnectorSettings;
