import './EventStyles.scss';
import {
  BillingScheduleSyncSummary,
  DefaultZeroState,
  ErrorCell,
  formatDateOrDefault,
  formatDatetimeOrDefault,
  QueryCell,
  QueryTable,
  SyncEventDetail,
} from 'common';
import React from 'react';
import { systemClient } from 'app/src/services/httpClients/systemclient';
import IDField from 'app/src/components/IDField';
import {
  getLatestEvent,
  reconciliationFilters,
} from '../../../services/reoconcile';
import StatusLabel from 'app/src/components/StatusLabel';
import { Link } from 'react-router-dom';
import { ENVIRONMENT_CONFIG } from 'app/config/hosts';

const getLink = (data: BillingScheduleSyncSummary) => {
  const event = getLatestEvent(data);
  if (event) {
    const href = !ENVIRONMENT_CONFIG.isProduction
      ? 'https://app.sandbox.qbo.intuit.com/app/invoice?txnId=' +
        event.externalId
      : 'https://app.qbo.intuit.com/app/invoice?txnId=' + event.externalId;
    return (
      <div className="underline text-blue">
        <Link to={href}>{event.externalId}</Link>
      </div>
    );
  }
  return null;
};

const SystemInvoiceReconciliation: React.FC = () => {
  const cells: QueryCell<BillingScheduleSyncSummary>[] = [
    {
      key: 'orgName',
      headerLabel: 'Org name',
      overflowCell: true,
      renderCell: (data: BillingScheduleSyncSummary) => (
        <div>{data.orgName}</div>
      ),
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) => (
        <div className="underline text-blue">
          <Link
            to={`/customers/${data.customerReference?.id}`}
            className=" block truncate"
          >
            {data.customerReference?.name}
          </Link>
        </div>
      ),
      key: 'contract.buyer.name',
      headerLabel: 'Customer name',
      sortable: true,
      overflowCell: true,
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) => {
        return (
          <div className="underline text-blue">
            <IDField documentID={data.invoiceReference?.name}>
              {data.invoiceReference?.name}
            </IDField>
          </div>
        );
      },
      key: 'invoice.invoiceNumber',
      headerLabel: 'Transaction no.',
      sortable: true,
      width: 350,
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) =>
        formatDateOrDefault(data.createdAt),
      key: 'createdAt',
      headerLabel: 'Created date',
      width: 170,
      sortable: true,
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) =>
        formatDateOrDefault(data.updatedAt),
      key: 'updatedAt',
      headerLabel: 'Updated date',
      width: 170,
      sortable: true,
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) => <>{getLink(data)}</>,
      key: 'externalLink',
      headerLabel: 'QuickBooks transaction no.',
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) => {
        const eventDetail = getLatestEvent(data);
        return (
          <div>
            <div className="p-1">
              {formatDatetimeOrDefault(eventDetail?.createdAt)}{' '}
            </div>
          </div>
        );
      },
      key: 'Lastsynced',
      headerLabel: 'Last sync date',
      allowWrap: true,
    },
    {
      renderCell: (data: BillingScheduleSyncSummary) => {
        const eventDetail: SyncEventDetail | undefined = getLatestEvent(data);
        if (eventDetail?.status === 'success') {
          return <StatusLabel status="success" feature="invoice" />;
        } else if (eventDetail?.status === 'error') {
          return (
            <ErrorCell
              error={eventDetail.error}
              statusLabel={<StatusLabel status="error" feature="invoice" />}
            />
          );
        }
        return 'Not synced';
      },
      key: 'lastEvent.status',
      headerLabel: 'Sync status',
      width: 180,
      align: 'center',
      sortable: true,
    },
  ];

  return (
    <QueryTable<BillingScheduleSyncSummary>
      queryKey="invoice-sync-summary"
      fetchList={systemClient.getSystemEventsByBillingSchedule}
      filters={reconciliationFilters(true, [
        {
          field: 'invoice.invoiceNumber',
          operation: 'contains',
        },
      ])}
      cells={cells}
      dataTestId="invoice-sync-table"
      minWidth="900px"
      zeroState={
        <DefaultZeroState
          dataTestId="no-invoice-sync"
          message="There are no invoice sync records."
        />
      }
      tooltipText="Search for Cacheflow invoice number"
    />
  );
};

export default SystemInvoiceReconciliation;
