import { Profile, Reference } from 'common';
import { AuthStateInterface, useAuth } from '../../Auth';

export const useIsSuperAdmin = (): boolean => {
  const auth = useAuth();
  return auth.isSystemAdmin;
};

export const useCurrentUserRole = (): Profile['role'] | undefined => {
  const auth = useAuth();
  return auth.role;
};

export const useCurrentUser = (): Profile['user'] => {
  const auth = useAuth();
  return auth.user;
};

export const useIsCurrentUserAdmin = (): boolean => {
  const auth = useAuth();
  return useIsSuperAdmin() || auth.role === 'admin';
};

export const useCanAccessSystemAdminRoutes = (): boolean => {
  const auth = useAuth();
  return auth.isSystemAdmin && !!auth.systemToken;
};

export const canAccessSystemAdminRoutesWithContext = (
  auth: AuthStateInterface
): boolean => {
  return auth.isSystemAdmin && !!auth.systemToken;
};

export const canAccessUserRoutes = (): boolean => {
  const auth = useAuth();
  return auth.isAuthenticated && !!auth.token;
};

export const isMultiOrgPath = (): boolean => {
  const auth = useAuth();
  return (
    auth.isAuthenticated &&
    !!auth.token &&
    auth.identity?.identityType === 'authidentity'
  );
};

export const useFlowDomain = (): Profile['flowDomain'] => {
  const auth = useAuth();
  return auth.flowDomain;
};

export const isAuthorized = (key?: string): boolean => {
  if (!key) {
    return true;
  }

  const auth = useAuth();

  if (auth.isSystemAdmin) {
    return true;
  }

  if (key !== FLAG_TYPES.UNKNOWN && auth.referenceSet) {
    return !!auth.referenceSet.references?.some(
      (r: Reference) => r.type === key
    );
  }

  return true;
};

export const isActionAllowed = (
  key: string,
  action: NonNullable<Reference['actions']>[number]
): boolean => {
  const auth = useAuth();

  if (auth.isSystemAdmin) {
    return true;
  }

  if (auth.referenceSet) {
    const ref = auth.referenceSet.references?.find(
      (r: Reference) => r.type === key
    );

    return (ref?.actions ?? []).includes(action);
  }

  return false;
};

export const isAuthorizedInRoles = (roles?: Profile['role'][]): boolean => {
  if (!roles) return true;

  const auth = useAuth();
  if (auth.isSystemAdmin) return true;

  return !!auth.role && roles.includes(auth.role);
};

export const FLAG_TYPES = {
  ACCOUNT: 'account',
  API_TOKEN: 'apitoken',
  API_WEBHOOK: 'apiwebhook',
  BILLING: 'billingschedule',
  CONTACT: 'contact',
  CONTRACT: 'contract',
  CUSTOMER: 'customer',
  SALESFORCE: 'salesforce',
  ATTACHMENT_CONFIG: 'attachmentconfig',
  SYNC_EVENTS: 'syncevents',
  INVITE: 'invite',
  ORGANIZATION: 'organization',
  PROPOSAL: 'proposal',
  PRODUCT: 'product',
  SYSTEM: 'system',
  TRANSFER: 'transfer',
  REFUND: 'refund',
  INVOICE: 'invoice',
  TRANSFER_EVENT: 'transferevent',
  UNKNOWN: 'unknown',
  USER: 'user',
};
