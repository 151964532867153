import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  BillingMethodRequest,
  BillingScheduleRequest,
  BillingSchedule,
  Contract,
  ContractEvent,
  ItemIntervalMetrics,
  ItemMetrics,
  Proposal,
  UpdateContractRequest,
} from 'common';
import { apiClient } from './httpClients/app';

interface ContractProposalCreationParams {
  externalId?: string;
  proposalType: Proposal['proposalType'];
  source?: Proposal['source'];
}

const CONTRACTS_PATH = '/api/latest/data/contracts';
const ACTIVE_USAGE_CONTRACTS_PATH = `${CONTRACTS_PATH}/usage`;
const URGENT_COUNT_PATH = `${CONTRACTS_PATH}/urgent`;
export const CONTRACTS_KEY = 'Contracts';

const getContractKey = (id: string) => `${CONTRACTS_KEY}${id}`;
const getUpdateContractKey = (id: string) => `ContractsUpdate${id}`;
const getContractPricingQueryKey = (id: string) =>
  `${CONTRACTS_PATH}_${id}_pricing`;
const getContractEventsQueryKey = (id: string) =>
  `${CONTRACTS_PATH}_${id}_events`;
const getContractItemMetricsQueryKey = (id: string) =>
  `${CONTRACTS_PATH}_${id}_itemMetrics`;
const getContractItemIntervalMetricsQueryKey = (id: string) =>
  `${CONTRACTS_PATH}_${id}_itemIntervalMetrics`;

interface BillingScheduleUpdate {
  id: string;
  request: BillingScheduleRequest;
}

export const useActiveUsageContracts = (): UseQueryResult<Contract[]> =>
  useQuery({
    queryKey: [ACTIVE_USAGE_CONTRACTS_PATH],
    queryFn: async () => {
      const { data } = await apiClient.listActiveUsageBasedContracts();
      return data;
    },
  });

export const useUrgentContractCount = (): UseQueryResult<number> =>
  useQuery({
    queryKey: [URGENT_COUNT_PATH],
    queryFn: async () => {
      const { data } = await apiClient.getUrgentCount();
      return data;
    },
  });

export const useContract = (
  id: string | undefined
): UseQueryResult<Contract | undefined> =>
  useQuery({
    queryKey: id ? [getContractKey(id)] : [],
    queryFn: async () => {
      if (!id) {
        return undefined;
      }
      const { data } = await apiClient.getContract(id);
      return data;
    },
    enabled: !!id,
  });

export const useBillingSchedules = (
  id: string
): UseQueryResult<BillingSchedule[]> =>
  useQuery({
    queryKey: [`${getContractKey(id)}/payments`],
    queryFn: async () => {
      const { data } = await apiClient.getContractPayments(id);
      return data;
    },
  });

export const useGetContractEvents = (
  id: string
): UseQueryResult<ContractEvent[]> =>
  useQuery({
    queryKey: [getContractEventsQueryKey(id)],
    queryFn: async () => {
      if (!id) throw Error;
      const { data } = await apiClient.getContractEvents(id);
      return data;
    },
  });

export const useContractProposals = (
  id: string
): UseQueryResult<Proposal[]> => {
  return useQuery({
    queryKey: ['apiClient.getContractProposals', id],
    queryFn: async () => {
      const { data } = await apiClient.getContractProposals(id);
      return data;
    },
  });
};

const updateContract = async (id: string, payload: UpdateContractRequest) => {
  const { data } = await apiClient.updateContract(id, payload);
  return data;
};

export const useUpdateContract = (
  contractId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getUpdateContractKey(contractId)],
    mutationFn: (data: UpdateContractRequest) =>
      updateContract(contractId, data),
    onSuccess: async () => {
      onSuccess();
      await qc.invalidateQueries();
    },
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [getContractPricingQueryKey(contractId)],
      });
      await qc.invalidateQueries({
        queryKey: [getContractEventsQueryKey(contractId)],
      });
      await invalidateContractLists(qc);
    },
  });

export const invalidateContractLists = async (qc: QueryClient) => {
  await qc.invalidateQueries({ queryKey: [ACTIVE_USAGE_CONTRACTS_PATH] });
  await qc.invalidateQueries({ queryKey: [CONTRACTS_KEY] });
};

export const invalidateContract = async (
  qc: QueryClient,
  contractId: string
) => {
  await qc.invalidateQueries({ queryKey: [getContractKey(contractId)] });
  await qc.invalidateQueries({
    queryKey: [getContractPricingQueryKey(contractId)],
  });
  await qc.invalidateQueries({
    queryKey: [`${getContractKey(contractId)}/payments`],
  });
};

const createContractProposal =
  (contractId: string, params: ContractProposalCreationParams) => async () => {
    const { data } = await apiClient.createContractProposal(contractId, {
      type: params.proposalType,
      ...(params.source && params.externalId
        ? {
            source: params.source,
            externalId: params.externalId,
          }
        : {}),
    });
    return data;
  };

export const useCreateContractProposal = (
  contractId: string,
  params: ContractProposalCreationParams,
  onSuccess: (data: any) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [`CreateContractProposal${contractId}`],
    mutationFn: createContractProposal(contractId, params),
    onSuccess: async (data) => {
      // TODO: could cache the proposal through a method in proposal.ts
      onSuccess(data);
      await qc.invalidateQueries({
        queryKey: [getContractEventsQueryKey(contractId)],
      });
    },
    onError,
  });

const updateBillingMethod = async (
  contractId: string,
  billingMethodRequest: BillingMethodRequest
) => {
  const { data } = await apiClient.updateBillingMethod(
    contractId,
    billingMethodRequest
  );
  return data;
};

export const useUpdateBillingMethod = (
  contractId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [`UpdateBillingMethod${contractId}`],
    mutationFn: (data: BillingMethodRequest) =>
      updateBillingMethod(contractId, data),
    onSuccess: (data) => {
      onSuccess();
      qc.setQueryData([getContractKey(contractId)], data);
    },
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [getContractKey(contractId)] });
      await invalidateContractLists(qc);
    },
  });

const syncInvoice = async (billingScheduleId: string) => {
  const { data } = await apiClient.syncBillingSchedule(billingScheduleId);
  return data;
};

const updateBilling = async (updateRequest: BillingScheduleUpdate) => {
  const { data } = await apiClient.updateBillingSchedule(
    updateRequest.id,
    updateRequest.request
  );
  return data;
};

export const useUpdateBillingSchedule = (
  contractId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [`BillingSchedules${contractId}`],
    mutationFn: (updateRequest: BillingScheduleUpdate) => {
      return updateBilling(updateRequest);
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [getContractKey(contractId)] });
      await invalidateContractLists(qc);
    },
  });

export const useSyncInvoice = (
  contractId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [`BillingSchedules${contractId}`],
    mutationFn: (billingScheduleId: string) => syncInvoice(billingScheduleId),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [getContractKey(contractId)] });
      await invalidateContractLists(qc);
    },
  });

const generateInvoice = async (billingScheduleId: string) => {
  const { data } = await apiClient.generateInvoice(billingScheduleId);
  return data;
};

export const useGenerateInvoice = (
  contractId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [`BillingSchedules${contractId}`],
    mutationFn: (billingScheduleId: string) =>
      generateInvoice(billingScheduleId),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [getContractKey(contractId)] });
      await invalidateContractLists(qc);
    },
  });

const updateTaxes = async (billingScheduleId: string) => {
  const { data } = await apiClient.updateTaxItemsForBillingSchedule(
    billingScheduleId,
    {}
  );
  return data;
};

export const useUpdateTaxes = (
  contractId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [`BillingSchedules${contractId}`],
    mutationFn: (billingScheduleId: string) => updateTaxes(billingScheduleId),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [getContractKey(contractId)] });
      await invalidateContractLists(qc);
    },
  });

// archive functions

const archiveSubscriptions = async (ids: string[]) => {
  const { data } = await apiClient.archiveSubscriptions({ ids });
  return data;
};

const restoreSubscriptions = async (ids: string[]) => {
  const { data } = await apiClient.restoreSubscriptions({ ids });
  return data;
};

export const useArchiveSubscription = (
  id: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getContractKey(id)],
    mutationFn: () => archiveSubscriptions([id]),
    onSuccess,
    onError,
    onSettled: async () => {
      await invalidateContractLists(qc);
    },
  });

export const useRestoreSubscription = (
  id: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getContractKey(id)],
    mutationFn: () => restoreSubscriptions([id]),
    onSuccess,
    onError,
    onSettled: async () => {
      await invalidateContractLists(qc);
    },
  });

const createSubscriptionMetrics = async (contractId: string) => {
  const { data } = await apiClient.createMetrics(contractId);
  return data;
};

export const useCreateSubscriptionMetrics = (
  contractId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationFn: createSubscriptionMetrics,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [getContractItemMetricsQueryKey(contractId)],
      });
      await qc.invalidateQueries({
        queryKey: [getContractItemIntervalMetricsQueryKey(contractId)],
      });
    },
  });

export const useGetItemMetrics = (
  contractId: string
): UseQueryResult<ItemMetrics[]> =>
  useQuery({
    queryKey: [getContractItemMetricsQueryKey(contractId)],
    queryFn: async () => {
      const { data } = await apiClient.getItemMetrics(contractId);
      return data;
    },
  });

export const useGetItemIntervalMetrics = (
  contractId: string
): UseQueryResult<ItemIntervalMetrics[]> =>
  useQuery({
    queryKey: [getContractItemIntervalMetricsQueryKey(contractId)],
    queryFn: async () => {
      const { data } = await apiClient.getItemIntervalMetrics(contractId);
      return data;
    },
  });
