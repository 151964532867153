import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  ErrorFetching,
  Loading,
  NavigationIcon,
  useFlags,
  useTranslation,
} from 'common';

import Page from 'app/src/components/Page';
import { INVOICES } from 'app/src/core-utils/routes';
import InvoiceHeader from './ActionBar/InvoiceHeader';
import InvoiceSummaryCard from './Details/InvoiceSummaryCard';
import InvoiceChargesCard from './Details/InvoiceChargesCard';
import { useInvoice } from 'app/src/services/api/invoices/invoiceList';
import InvoiceTransactionsCard from './Details/InvoiceTransactionsCard';
import InvoiceVoidConfirmationModal from './InvoiceVoidConfirmationModal';
import { useBillingSchedule } from 'app/src/services/billing';
import { InvoicePaymentUpdateDrawer } from '../Subscriptions/SubscriptionDetail/PaymentSchedule/PaymentUpdateForm/InvoicePaymentUpdateDrawer';
import { useIssueRefundDrawer } from '../Refunds/hooks/useIssueRefundDrawer';
import { IssueRefundDrawer } from '../Refunds/components/IssueRefundDrawer';

const InvoicePage: React.FC = () => {
  const { tk } = useTranslation();
  const { refunds } = useFlags();

  const { id: invoiceId } = useParams<{ id: string }>();

  const [isConfirmUnvoidModalOpen, setIsConfirmUnvoidModalOpen] =
    useState<boolean>(false);
  const [billingScheduleId, setBillingScheduleId] = useState<
    string | undefined
  >();

  const { data: schedule, isLoading } = useBillingSchedule(billingScheduleId);

  const {
    isIssueRefundDrawerOpen,
    selectedTransfer,
    openIssueRefundDrawer,
    closeIssueRefundDrawer,
  } = useIssueRefundDrawer();

  const { data: invoice, isPending, isError } = useInvoice(invoiceId!);

  if (isPending || isLoading) {
    return <Loading />;
  }

  if (isError || !invoiceId) {
    return <ErrorFetching />;
  }

  const nameComponent = (
    <div className="flex h-10 gap-2">
      <div className="flex flex-col justify-center">
        <Link to={INVOICES}>
          <NavigationIcon.Agreements />
        </Link>
      </div>
      <div className="flex flex-col justify-center">
        {invoice.subscription.name}
        <p className="text-xs">
          {tk('Invoice no.')} {invoice.header.invoiceNumber}{' '}
        </p>
      </div>
    </div>
  );

  return (
    <Page
      leftWidget={nameComponent}
      rightWidget={
        <InvoiceHeader
          invoice={invoice}
          setBillingScheduleId={setBillingScheduleId}
          setIsConfirmUnvoidModalOpen={setIsConfirmUnvoidModalOpen}
        />
      }
    >
      <InvoicePaymentUpdateDrawer
        isOpen={!!billingScheduleId && !isLoading}
        onClose={() => {
          setBillingScheduleId(undefined);
        }}
        selectedPayment={schedule}
        customerName={invoice?.header?.customer?.name}
      />
      <InvoiceVoidConfirmationModal
        isModalOpen={isConfirmUnvoidModalOpen}
        setIsModalOpen={setIsConfirmUnvoidModalOpen}
      />

      <div className="max-w-screen-lg">
        <InvoiceSummaryCard
          invoice={invoice.header}
          schedule={invoice.schedule}
          subscription={invoice.subscription}
        />

        <InvoiceChargesCard
          invoice={invoice.header}
          schedule={invoice.schedule}
          subscription={invoice.subscription}
        />

        <InvoiceTransactionsCard
          onIssueRefundClick={openIssueRefundDrawer}
          schedule={invoice.schedule}
        />
      </div>

      {refunds && isIssueRefundDrawerOpen && selectedTransfer && (
        <IssueRefundDrawer
          customerName={invoice.header.customer.name}
          isOpen={isIssueRefundDrawerOpen}
          onClose={closeIssueRefundDrawer}
          transfer={selectedTransfer}
        />
      )}
    </Page>
  );
};

export default InvoicePage;
