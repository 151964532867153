export enum ConnectorTabs {
  accountContacts = 'account-contacts',
  closeConfiguration = 'closeConfiguration',
  configuration = 'configuration',
  connection = 'connection',
  deals = 'deals',
  events = 'events',
  locationCode = 'locationCode',
  opportunities = 'opportunities',
  proposalEquivalent = 'proposal-equivalent',
  proposalFieldMappings = 'proposalFieldMappings',
  proposalStageMappings = 'proposalStageMappings',
  reconciliation = 'reconciliation',
  status = 'status',
}
