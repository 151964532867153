import React from 'react';
import clsx from 'clsx';
import {
  assertDefined,
  BillingPeriodsType,
  Checkbox,
  formatMoneyOrDefault,
  getEntry,
  getProductBillingPeriodOverrides,
  getProductBillingPeriodPrice,
  isNumber,
  Item,
  ItemLevelOverride,
  ItemOverride,
  Money,
  useTranslation,
} from 'common';
import { getItemOverrideWithLevelsDraft } from '../TimeBasedPricing/TimeBasedPricingTieredDrawer/utils';
import styles from './BillingPeriodPriceManagement.module.scss';
import ListPriceSalesPriceInput from '../ListPriceSalesPriceInput';

interface Props {
  billingPeriod?: BillingPeriodsType;
  currency: string;
  handleSetOverride: (arg: ItemOverride) => void;
  isSaving: boolean;
  item: Item;
  level?: number;
}

const PriceOverride = ({
  billingPeriod,
  currency,
  handleSetOverride,
  isSaving,
  item,
  level,
}: Props) => {
  const product = assertDefined(item.product);
  const isOneTime = product.recurrence === 'one_time';
  const billingPeriodPricing = isOneTime ? undefined : billingPeriod;
  const { tk } = useTranslation();
  const allowListPriceAdjustments = product.listPriceAdjustable;

  // gets the current override or creates a new one
  const itemOverride =
    level !== undefined
      ? getItemOverrideWithLevelsDraft(item, currency, billingPeriodPricing)
      : getProductBillingPeriodOverrides(item, billingPeriodPricing)[0] || {
          discount: false,
          billingPeriod: billingPeriodPricing,
        };

  const getPrices = (): {
    basePrice: Money;
    currentListPrice: Money;
    currentPrice: Money;
  } => {
    if (level === undefined) {
      const bPrice = assertDefined(
        getProductBillingPeriodPrice(product, currency, billingPeriodPricing)
      );

      const listPrice = itemOverride.listPrice || bPrice;
      const price = itemOverride.price || listPrice;
      return {
        basePrice: bPrice,
        currentListPrice: listPrice,
        currentPrice: price,
      };
    } else {
      const levelBasePrice = assertDefined(
        getEntry(product.entries, currency, billingPeriodPricing, level)?.price
      );

      const itemLevelOverride = itemOverride.levels?.find(
        (levelOverride) => levelOverride.level === level
      );
      const levelListPrice = itemLevelOverride?.listPrice || levelBasePrice;
      const levelPrice = itemLevelOverride?.price || levelListPrice;
      return {
        basePrice: levelBasePrice,
        currentListPrice: levelListPrice,
        currentPrice: levelPrice,
      };
    }
  };

  const { basePrice, currentListPrice, currentPrice } = getPrices();

  const onPriceChange = (newListPrice: Money, newPrice: Money) => {
    // don't allow discount if price is higher than list price
    const isDiscount =
      itemOverride.discount &&
      isPriceDiscount(newPrice.amount, newListPrice.amount);

    const overrideUpdate =
      level !== undefined
        ? {
            billingPeriod: billingPeriodPricing,
            levels: getLevelChanges(newListPrice, newPrice),
            discount: isDiscount,
          }
        : {
            billingPeriod: billingPeriodPricing,
            listPrice: newListPrice,
            price: newPrice,
            discount: isDiscount,
          };

    if (!allowListPriceAdjustments) {
      delete overrideUpdate.listPrice;
    }

    handleSetOverride(overrideUpdate);
  };

  const getLevelChanges = (
    newListPrice: Money,
    newPrice: Money
  ): undefined | ItemLevelOverride[] => {
    if (level === undefined) {
      return undefined;
    }

    const oldLevels = itemOverride.levels ?? [];
    const levelIndex = oldLevels.findIndex((l) => l.level === level);

    let newLevels: ItemLevelOverride[];
    if (levelIndex === -1) {
      newLevels = [
        ...oldLevels,
        { listPrice: newListPrice, price: newPrice, level },
      ];
    } else {
      newLevels = [
        ...oldLevels.slice(0, levelIndex),
        { listPrice: newListPrice, price: newPrice, level },
        ...oldLevels.slice(levelIndex + 1),
      ];
    }

    if (!allowListPriceAdjustments) {
      newLevels.forEach((l) => delete l.listPrice);
    }

    return newLevels;
  };

  const changeIsMarkedAsDiscount = (checked: boolean) => {
    const updatedOverridesDraft = {
      ...itemOverride,
      discount: checked,
    };

    if (!allowListPriceAdjustments) {
      delete updatedOverridesDraft.listPrice;
    }

    handleSetOverride(updatedOverridesDraft);
  };

  const isPriceDiscount = (
    overridePrice: number | undefined,
    listPrice: number | undefined
  ) => {
    if (!isNumber(overridePrice) || !isNumber(listPrice)) {
      return false;
    }
    return overridePrice <= listPrice;
  };

  const getId = () => {
    const bp = billingPeriodPricing ? billingPeriodPricing : 'one-time';
    return level !== undefined ? `${bp}-${level}` : bp;
  };

  return (
    <>
      <ListPriceSalesPriceInput
        id={getId()}
        basePrice={basePrice}
        currentListPrice={currentListPrice}
        currentPrice={currentPrice}
        isSaving={isSaving}
        item={item}
        onPriceChange={onPriceChange}
      />
      <div className={clsx(styles.previousPrice)}>
        {level === undefined && (
          <Checkbox
            name={'show-as-discount-checkbox-' + billingPeriodPricing}
            label={tk('Display as discount')}
            value={itemOverride.discount}
            onChange={(checked) => changeIsMarkedAsDiscount(checked)}
            dataTestId={'show-as-discount-' + billingPeriodPricing}
            isDisabled={
              !isPriceDiscount(
                itemOverride.price?.amount || 0,
                currentListPrice.amount || 0
              ) || isSaving
            }
          />
        )}
        {allowListPriceAdjustments && (
          <p>
            {tk('Base price: ') + formatMoneyOrDefault(basePrice).toString()}
          </p>
        )}
      </div>
    </>
  );
};

export default PriceOverride;
