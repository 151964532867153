import React, { SVGProps } from 'react';
import { CardHeader, useTranslation } from 'common';
import styles from './LogoCardHeader.module.scss';

interface Props {
  children?: React.ReactNode;
  logo?: React.ComponentType<SVGProps<SVGSVGElement>>;
  LogoElement?: React.ReactElement;
  name?: string;
}

const LogoCardHeader = ({
  logo: LogoComponent,
  LogoElement,
  name,
  children,
}: Props) => {
  const { tk } = useTranslation();
  return (
    <CardHeader
      name={
        <div className={styles.logoHeader}>
          {LogoComponent && <LogoComponent height={50} />}
          {LogoElement}
          {name && tk(name)}
        </div>
      }
    >
      {children}
    </CardHeader>
  );
};

export default LogoCardHeader;
