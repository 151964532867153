import React from 'react';
import { Navigate } from 'react-router-dom';
import { HighlightPill, Icon, pushIf, useFlags } from 'common';
import BankAccounts from './BankAccounts/BankAccounts';
import CompanyDetailsPage from './CompanyDetails/CompanyDetailsPage';
import ConnnectorAuthCallback from './Connectors/ConnectorAuthCallback';
import PaymentMethods from './PaymentMethods';
import BillingPeriods from './BillingPeriods';
import PaymentTerms from './PaymentTerms';
import Signing from './Docusign/Signing/Signing';
import SlackSettings from './Slack/SlackSettings';
import APISettings from './APISettings';
import SubscriptionsOnSigning from './SubscriptionManagement/SubscriptionsOnSigning';
import ChangeProposal from './ProposalManagement/ChangeProposal';
import {
  INTEGRATIONS_AND_CONNECTORS,
  SETTINGS_ADDRESS_VERIFICATION,
  SETTINGS_API,
  SETTINGS_APPROVALS,
  SETTINGS_AUTH_CALLBACK,
  SETTINGS_AUTO_RENEWALS,
  SETTINGS_AVALARA,
  SETTINGS_BANK_ACCOUNTS,
  SETTINGS_BILLING_PERIODS,
  SETTINGS_CHANGE_PROPOSAL,
  SETTINGS_CLOSE,
  SETTINGS_COMPANY,
  SETTINGS_CURRENCY,
  SETTINGS_HUBSPOT,
  SETTINGS_INVOICE_SETTINGS,
  SETTINGS_LOCATION_CODE_MAPPING,
  SETTINGS_PAYMENT_METHODS,
  SETTINGS_PAYMENT_TERMS,
  SETTINGS_PURCHASE_ORDER,
  SETTINGS_QUICKBOOKS,
  SETTINGS_SALESFORCE,
  SETTINGS_SIGNING,
  SETTINGS_SLACK,
  SETTINGS_STRIPE,
  SETTINGS_SUBSCRIPTIONS,
  SETTINGS_TAXATION,
  SETTINGS_USERS,
  SETTINGS_WARMLY,
  SETTINGS_ZAPIER,
} from '../../core-utils/routes';
import Users from './Users/Users';
import { ApprovalsPage } from './Approvals';
import DocuSignLogoSVG from '../../assets/integration-logos/docuSignLogoSVG';
import SlackLogoSVG from '../../assets/integration-logos/slackLogoSVG';
import SalesforceLogoSVG from '../../assets/integration-logos/salesforceLogoSVG';
import HubspotLogoSVG from '../../assets/integration-logos/hubspotSVG';
import QuickbooksLogoSVG from '../../assets/integration-logos/QuickbooksSVG';
import SalesforceSettings from './Salesforce/SalesforceSettings';
import HubspotSettings from './Hubspot/HubspotSettings';
import ZapierSettings from './Zapier/ZapierSettings';
import NavItem, { NavItemProps } from './NavItem';
import { useFindSlackWorkspace } from '../../services/slack';
import { useDocuSignAccount } from '../../services/api/docusign/docusign';
import { useIsConnectorEnabled } from '../../services/connectors';
import { useFindOrgDefault } from '../../services/orgDefaults';
import { SALESFORCE_CONFIG } from './Salesforce/SalesforceConstants';
import { HUBSPOT_CONFIG } from './Hubspot/constants';
import ApiLogoSVG from '../../assets/integration-logos/ApiLogoSVG';
import QuickbooksSettings from './Quickbooks/QuickbooksSettings';
import WarmlySettings from './Warmly/WarmlySettings';
import Page from '../../components/Page';
import RenderRoutes from '../../services/routes/RenderRoutes';
import { SETTINGS_ROUTE } from '../../services/routes/Routes';
import { useStripeAccount } from 'app/src/services/api/stripe/stripe';
import StripeLogoSVG from 'app/src/assets/integration-logos/StripeLogoSVG';
import StripeSettings from 'app/src/pages/Settings/Stripe/StripeSettings';
import AutoRenewals from './SubscriptionManagement/AutoRenewals';
import TaxationSettings from './Taxation/TaxationSettings';
import AddressVerificationSettings from './Taxation/AddressVerificationSettings';
import PurchaseOrderSettings from './Taxation/PurchaseOrderSettings';
import InvoiceSettings from './Billing/InvoiceSettings';
import AvalaraLogoSVG from '../../assets/integration-logos/AvalaraLogoSVG';
import AvalaraSettings from './Taxation/AvalaraSettings';
import CloseSettings from './Close/CloseSettings';
import CloseSVG from '../../assets/integration-logos/CloseSVG';
import CurrencySettings from './Currency/CurrencySettings';
import { CLOSE_CONFIG } from './Close/CloseConstants';
import LocationCodeMappingSettings from './CompanyDetails/LocationCodeMappingSettings';
import { ENVIRONMENT_CONFIG } from 'app/config/hosts';
import './styles.css';
import { useIsSuperAdmin } from '../../core-utils/helperFunctions/userServiceHelper';
import warmlyLogo from '../../assets/integration-logos/warmly.png';
import ZapierLogoSVG from '../../assets/integration-logos/ZapierLogoSVG';

interface TabCategory {
  category: string;
  tabs: NavItemProps[];
}

const Settings: React.FC = () => {
  const { taxyTurby, invoices, enhancedSpans, warmlyAi, zapierConnector } =
    useFlags();
  const { data: slackData } = useFindSlackWorkspace();
  const { data: docuSignData } = useDocuSignAccount();
  const { isError: isSalesforceError } = useFindOrgDefault(SALESFORCE_CONFIG);
  const { isError: isHubspotError } = useFindOrgDefault(HUBSPOT_CONFIG);
  const { isError: isCloseError } = useFindOrgDefault(CLOSE_CONFIG);
  const salesforceEnabled = useIsConnectorEnabled('salesforceConfig');
  const hubspotEnabled = useIsConnectorEnabled('hubspotConfig');
  const quickbooksEnabled = useIsConnectorEnabled('quickbooksConfig');
  const warmlyEnabled = useIsConnectorEnabled('warmlyConfig');
  const closeEnabled = useIsConnectorEnabled('closeConfig');
  const zapierEnabled = useIsConnectorEnabled('zapierConfig');
  const { data: stripeAccountData } = useStripeAccount();
  const isSuperAdmin = useIsSuperAdmin();

  const getTabs = (): TabCategory[] => {
    return [
      {
        category: 'Company',
        tabs: [
          {
            label: 'Company details',
            path: SETTINGS_COMPANY,
          },
          {
            label: 'Users',
            path: SETTINGS_USERS,
          },
          {
            label: 'Approvals',
            path: SETTINGS_APPROVALS,
          },
        ],
      },
      ...pushIf<TabCategory>(enhancedSpans, {
        category: 'Proposal Management',
        tabs: [
          {
            label: 'Change Proposal',
            path: SETTINGS_CHANGE_PROPOSAL,
          },
        ],
      }),
      {
        category: 'Subscription management',
        tabs: [
          {
            label: 'Subscriptions',
            path: SETTINGS_SUBSCRIPTIONS,
          },
          {
            label: 'Auto-renewals',
            path: SETTINGS_AUTO_RENEWALS,
          },
        ],
      },
      {
        category: 'Payments',
        tabs: [
          // only used for stripe
          ...pushIf<NavItemProps>(ENVIRONMENT_CONFIG.isLocal || isSuperAdmin, {
            label: 'Bank accounts',
            path: SETTINGS_BANK_ACCOUNTS,
            tag: <HighlightPill label="TEST ONLY" />,
          }),
          {
            label: 'Billing periods',
            path: SETTINGS_BILLING_PERIODS,
          },
          {
            label: 'Payment terms',
            path: SETTINGS_PAYMENT_TERMS,
          },
          {
            label: 'Payment methods',
            path: SETTINGS_PAYMENT_METHODS,
          },
        ],
      },
      {
        category: 'Billing',
        tabs: [
          ...pushIf(invoices, [
            {
              label: 'Invoice settings',
              path: SETTINGS_INVOICE_SETTINGS,
            },
          ]),
          {
            label: 'Purchase order',
            path: SETTINGS_PURCHASE_ORDER,
          },
          {
            label: 'Currency',
            path: SETTINGS_CURRENCY,
          },
          ...pushIf(taxyTurby, [
            {
              label: 'Tax',
              path: SETTINGS_TAXATION,
            },
            {
              label: 'Address verification',
              path: SETTINGS_ADDRESS_VERIFICATION,
            },
          ]),
        ],
      },
      {
        category: 'Integrations and connectors',
        tabs: [
          {
            label: 'API',
            path: SETTINGS_API,
            logo: <ApiLogoSVG height={24} width={24} />,
          },
          ...pushIf<NavItemProps>(!!stripeAccountData, {
            label: 'Stripe',
            path: SETTINGS_STRIPE,
            logo: <StripeLogoSVG height={24} width={24} />,
          }),
          ...pushIf<NavItemProps>(!!docuSignData, {
            label: 'DocuSign',
            path: SETTINGS_SIGNING,
            logo: <DocuSignLogoSVG height={24} width={24} />,
          }),
          ...pushIf<NavItemProps>(salesforceEnabled, {
            label: 'Salesforce',
            path: SETTINGS_SALESFORCE,
            logo: <SalesforceLogoSVG height={22} width={22} />,
            isError: isSalesforceError,
          }),
          ...pushIf<NavItemProps>(hubspotEnabled, {
            label: 'HubSpot',
            path: SETTINGS_HUBSPOT,
            logo: <HubspotLogoSVG height={22} width={22} />,
            isError: isHubspotError,
          }),
          ...pushIf<NavItemProps>(zapierConnector && zapierEnabled, {
            label: 'Zapier',
            path: SETTINGS_ZAPIER,
            logo: <ZapierLogoSVG height={22} width={22} />,
          }),
          ...pushIf<NavItemProps>(closeEnabled, {
            label: 'Close',
            path: SETTINGS_CLOSE,
            logo: <CloseSVG height={22} width={22} />,
            isError: isCloseError,
          }),
          ...pushIf<NavItemProps>(!!slackData, {
            label: 'Slack',
            path: SETTINGS_SLACK,
            logo: <SlackLogoSVG height={16} width={16} />,
          }),
          ...pushIf<NavItemProps>(quickbooksEnabled, {
            label: 'QuickBooks',
            path: SETTINGS_QUICKBOOKS,
            logo: <QuickbooksLogoSVG height={16} width={16} />,
          }),
          ...pushIf<NavItemProps>(taxyTurby, {
            label: 'Avalara',
            path: SETTINGS_AVALARA,
            logo: <AvalaraLogoSVG height={16} width={16} />,
          }),
          ...pushIf<NavItemProps>(warmlyAi && warmlyEnabled, {
            label: 'Warmly Chat',
            path: SETTINGS_WARMLY,
            logo: <img alt="Warmly" height={16} src={warmlyLogo} width={16} />,
          }),
          {
            label: 'Add',
            path: INTEGRATIONS_AND_CONNECTORS,
            logo: <Icon.Plus height={32} width={32} />,
          },
        ],
      },
    ];
  };

  return (
    <Page className="company-settings-container" leftWidget="Account settings">
      <section className="full-nav-list w-full">
        {getTabs().map(({ category, tabs }) => (
          <div className="mb-8 w-full" key={category}>
            <h3 className="text-slate-darker uppercase font-bold !mt-1">
              {category}
            </h3>
            <ul className="company-nav-list">
              {tabs.map((props) => (
                <NavItem {...props} key={props.path} />
              ))}
            </ul>
          </div>
        ))}
      </section>

      <div className="w-full justify-self-start">
        <RenderRoutes
          parentRoute={SETTINGS_ROUTE}
          routes={[
            { path: SETTINGS_COMPANY, component: CompanyDetailsPage },
            { path: SETTINGS_BANK_ACCOUNTS, component: BankAccounts },
            { path: SETTINGS_API, component: APISettings },
            { path: SETTINGS_PAYMENT_METHODS, component: PaymentMethods },
            { path: SETTINGS_PAYMENT_TERMS, component: PaymentTerms },
            { path: SETTINGS_BILLING_PERIODS, component: BillingPeriods },
            { path: SETTINGS_SIGNING, component: Signing },
            { path: SETTINGS_SLACK, component: SlackSettings },
            { path: SETTINGS_USERS, component: Users },
            {
              path: SETTINGS_APPROVALS,
              component: ApprovalsPage,
            },
            { path: SETTINGS_SALESFORCE, component: SalesforceSettings },
            { path: SETTINGS_HUBSPOT, component: HubspotSettings },
            { path: SETTINGS_ZAPIER, component: ZapierSettings },
            { path: SETTINGS_QUICKBOOKS, component: QuickbooksSettings },
            { path: SETTINGS_WARMLY, component: WarmlySettings },
            { path: SETTINGS_CHANGE_PROPOSAL, component: ChangeProposal },
            { path: SETTINGS_SUBSCRIPTIONS, component: SubscriptionsOnSigning },
            { path: SETTINGS_AUTO_RENEWALS, component: AutoRenewals },
            { path: SETTINGS_STRIPE, component: StripeSettings },
            { path: SETTINGS_TAXATION, component: TaxationSettings },
            { path: SETTINGS_AVALARA, component: AvalaraSettings },
            { path: SETTINGS_CLOSE, component: CloseSettings },
            { path: SETTINGS_CURRENCY, component: CurrencySettings },
            { path: SETTINGS_AUTH_CALLBACK, component: ConnnectorAuthCallback },
            {
              path: SETTINGS_ADDRESS_VERIFICATION,
              component: AddressVerificationSettings,
            },
            {
              path: SETTINGS_PURCHASE_ORDER,
              component: PurchaseOrderSettings,
            },
            {
              path: SETTINGS_INVOICE_SETTINGS,
              component: InvoiceSettings,
            },
            {
              path: SETTINGS_LOCATION_CODE_MAPPING,
              component: LocationCodeMappingSettings,
            },
            {
              path: '*',
              component: () => <Navigate replace to={SETTINGS_COMPANY} />,
            },
          ]}
        />
      </div>
    </Page>
  );
};

export default Settings;
