import { useState } from 'react';

import { Transfer } from 'common';

type IssueRefundDrawerType = {
  isIssueRefundDrawerOpen: false | true;
  selectedTransfer: Transfer | null;
};

export const useIssueRefundDrawer = () => {
  const [state, setState] = useState<IssueRefundDrawerType>({
    isIssueRefundDrawerOpen: false,
    selectedTransfer: null,
  });

  const openIssueRefundDrawer = (transfer: Transfer) => {
    setState({
      isIssueRefundDrawerOpen: true,
      selectedTransfer: transfer,
    });
  };

  const closeIssueRefundDrawer = () => {
    setState({
      isIssueRefundDrawerOpen: false,
      selectedTransfer: null,
    });
  };

  return {
    openIssueRefundDrawer,
    closeIssueRefundDrawer,
    selectedTransfer: state.selectedTransfer,
    isIssueRefundDrawerOpen: state.isIssueRefundDrawerOpen,
  };
};
