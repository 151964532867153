import React from 'react';
import StatusLabel from 'app/src/components/StatusLabel';
import { InvoiceDetail } from 'common';
import SubscriptionButton from 'app/src/components/ActionBarButtons/SubscriptionButton';
import PdfButton from 'app/src/components/ActionBarButtons/PdfButton';
import ActionBarButton from 'app/src/components/ActionBarButton';
import ActionBar from 'app/src/components/ActionBar';
import { useCompanyInfo } from 'app/src/services/api/branding/companyInfo';
import { ssrAxios } from '../../../services/httpClients/app';
import { useIsCurrentUserAdmin } from '../../../core-utils/helperFunctions/userServiceHelper';

import InvoiceOptionsButton from './InvoiceOptionsButton';
import ExternalEntityLink from 'app/src/components/ExternalEntityLink';
import { ConnectableEntity } from 'app/src/core-utils/helperFunctions/types';
import { useIsConnectorEnabled } from 'app/src/services/connectors';
import { useFindOrgDefault } from 'app/src/services/orgDefaults';

interface Props {
  invoice: InvoiceDetail;
  setIsConfirmUnvoidModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setBillingScheduleId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

const InvoiceHeader: React.FC<Props> = ({
  invoice,
  setIsConfirmUnvoidModalOpen,
  setBillingScheduleId,
}) => {
  const { data: companyInfo } = useCompanyInfo();
  const isAdminRole = useIsCurrentUserAdmin();

  const quickBooksConnectionStatus = useIsConnectorEnabled('quickbooksConfig');

  const { data: configData } = useFindOrgDefault('invoiceConfig');

  return (
    <div className="box-border flex flex-row items-center justify-between bg-white border-b border-solid border-gray-lines h-[72px]">
      {quickBooksConnectionStatus && (
        <div className="mr-3">
          <ExternalEntityLink
            entity={
              {
                externalId: invoice.schedule.sourceDisplayId,
                externalLink: invoice.schedule.externalLink,
                source: invoice.schedule.source,
              } as ConnectableEntity
            }
            lastSyncEvent={invoice.schedule.lastSyncEvent}
          />
        </div>
      )}
      <div className="flex items-center gap-4">
        <StatusLabel feature="invoice" status={invoice.header.status} />
        <ActionBar>
          {invoice.subscription.id && (
            <SubscriptionButton
              contractId={invoice.subscription.id}
              isDisabled={false}
            />
          )}
          <PdfButton
            fetchFunction={async () => {
              return await ssrAxios.post<Blob>(
                '/render-pdf',
                {
                  sellerName: companyInfo?.name,
                  address: companyInfo?.billingAddress,
                  tin: companyInfo?.tin,
                  logoUrl:
                    companyInfo?.logo?.latestVersion?.url ||
                    companyInfo?.logoUrl,
                  data: {
                    invoiceDetail: invoice,
                    type: 'invoice_pdf',
                    invoiceConfig: configData?.configValue,
                  },
                },
                {
                  responseType: 'blob',
                }
              );
            }}
            fileName={`${invoice.header.invoiceNumber}.pdf`}
            isDisabled={false}
            objectName="Invoice"
          />
          {isAdminRole && (
            <ActionBarButton>
              <InvoiceOptionsButton
                borderless
                invoice={invoice.header}
                scheduleId={invoice.schedule.id}
                sourceId={invoice.schedule.sourceDisplayId}
                subscriptionId={invoice.subscription.id}
                setBillingScheduleId={setBillingScheduleId}
                setIsConfirmUnvoidModalOpen={setIsConfirmUnvoidModalOpen}
              />
            </ActionBarButton>
          )}
        </ActionBar>
      </div>
    </div>
  );
};

export default InvoiceHeader;
