import React from 'react';
import {
  BillingSchedule,
  Card,
  CardHeader,
  Cell,
  DefaultZeroState,
  formatDateUTC,
  formatHumanReadable,
  formatNullableFormattedAmount,
  isDefined,
  pushIf,
  SimpleTable,
  Transfer,
  useFlags,
} from 'common';

import StatusLabel from 'app/src/components/StatusLabel';
import { TransferOptionsButton } from './TransferOptionsButton';
import { Link } from 'react-router-dom';
import styles from './InvoiceTransactionsCard.module.scss';

interface Props {
  schedule: BillingSchedule;
  onIssueRefundClick: (transfer: Transfer) => void;
}

const InvoiceTransactionsCard: React.FC<Props> = ({
  schedule,
  onIssueRefundClick,
}) => {
  const { refunds, paymentsSideNav } = useFlags();

  const renderTransactionCell = (transfer: Transfer) => (
    <div className="font-semibold truncate">
      {formatHumanReadable(transfer.category)}
    </div>
  );

  const renderDateCell = (transfer: Transfer) =>
    transfer.processedAt ? formatDateUTC(transfer.processedAt) : '-';

  const renderAmountCell = (transfer: Transfer) => (
    <>{formatNullableFormattedAmount(transfer.paidAmountFormatted)}</>
  );

  const cells: Cell<Transfer>[] = [
    {
      key: 'name',
      headerLabel: 'Transaction Type',
      allowWrap: true,
      renderCell: renderTransactionCell,
    },
    ...pushIf(paymentsSideNav, {
      key: 'transaction',
      headerLabel: 'Transaction',
      renderCell: (transfer: Transfer) => (
        <Link className={styles.link} to={`/payments/${transfer.id}`}>
          {transfer.id?.substring(0, 8) ?? '-'}
        </Link>
      ),
    }),
    {
      key: 'date',
      headerLabel: 'Date',
      width: '30%',
      allowWrap: true,
      renderCell: renderDateCell,
    },
    {
      key: 'amount',
      headerLabel: 'Amount',
      width: '10%',
      renderCell: renderAmountCell,
    },
    {
      key: 'status',
      headerLabel: 'Status',
      width: '10%',
      renderCell: (transfer: Transfer) => (
        <StatusLabel feature="transaction" status={transfer.status} />
      ),
    },
    ...pushIf(refunds, {
      key: 'options',
      headerLabel: 'Options',
      clickable: true,
      width: 64,
      renderCell: (transfer: Transfer) => {
        return (
          <TransferOptionsButton
            isIssueRefundDisabled={transfer.status !== 'posted'}
            onIssueRefundClick={() => onIssueRefundClick(transfer)}
          />
        );
      },
    }),
  ];

  const getTransfers = (): Transfer[] => {
    return [schedule.transfer].filter(isDefined);
  };

  return (
    <Card>
      <CardHeader name="Transactions" />
      <SimpleTable
        cells={cells}
        disableSearch
        disableShadow
        rows={getTransfers()}
        zeroState={
          <DefaultZeroState message="There are currently no payments for transactions." />
        }
      />
    </Card>
  );
};

export default InvoiceTransactionsCard;
