import StatusLabel from '../../../components/StatusLabel';
import ActionBar from '../../../components/ActionBar';
import React from 'react';
import { Icon, Transfer } from 'common';
import styles from './PaymentActionsHeader.module.scss';
import ActionBarButton from '../../../components/ActionBarButton';

interface Props {
  payment?: Transfer;
  openDialog?: () => void;
}

export const PaymentActionsHeader: React.FC<Props> = ({
  payment,
  openDialog,
}) => {
  return (
    <div className={styles.headerContainer}>
      <StatusLabel feature="transaction" status={payment?.status ?? ''} />
      <ActionBar>
        <ActionBarButton
          icon={Icon.Activity}
          onClick={openDialog}
          tooltip="Activity"
        />
      </ActionBar>
    </div>
  );
};
