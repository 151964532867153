import React from 'react';
import { OrgConfig, Select, SelectOption } from 'common';

type LocationISOOptions = 'ISO2' | 'ISO3' | 'NAME';
const LOCATION_ISO_OPTIONS: SelectOption<LocationISOOptions>[] = [
  {
    value: 'ISO2',
    name: 'ISO2 - US',
  },
  {
    value: 'ISO3',
    name: 'ISO3 - USA',
  },
  {
    value: 'NAME',
    name: 'NAME - United States',
  },
];

interface Props {
  connectorConfigValue: OrgConfig['configValue'];
  setConnectorConfigValue: React.Dispatch<
    React.SetStateAction<OrgConfig['configValue']>
  >;
  setSavableChanges: (arg: boolean) => void;
}

const ConnectorLocation = ({
  setConnectorConfigValue,
  connectorConfigValue,
  setSavableChanges,
}: Props) => {
  const handleSelectChange = (field: string, value: string) => {
    setConnectorConfigValue((prev) => ({
      ...prev,
      [field]: value,
    }));
    setSavableChanges(true);
  };
  const locationComponent = (value: any) => {
    return (
      <div className="w-80 h-16 bg-gray-200">
        <h3>Address type</h3>
        <Select
          dataTestId="address_type"
          white
          onChange={(newValue: LocationISOOptions) => {
            handleSelectChange('locationCodeType', newValue);
          }}
          value={value}
          options={LOCATION_ISO_OPTIONS}
        />
      </div>
    );
  };
  if (
    !!connectorConfigValue &&
    connectorConfigValue.configType &&
    [
      'hubspotConfig',
      'salesforceConfig',
      'closeConfig',
      'avalaraConfig',
      'quickbooksConfig',
    ].includes(connectorConfigValue.configType) &&
    'locationCodeType' in connectorConfigValue
  ) {
    return locationComponent(connectorConfigValue.locationCodeType);
  } else {
    return null;
  }
};

export default ConnectorLocation;
