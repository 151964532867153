import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Button,
  Card,
  CardHeader,
  Loading,
  OrgConfig,
  ToggleSwitch,
  useToast,
  useTranslation,
} from 'common';

import {
  useFindOrgDefault,
  useSetOrgConfigDefault,
} from '../../../services/orgDefaults';

const CONFIG_KEY = 'acceptOnSigning' as OrgConfig['configKey'];

const SubscriptionsOnSigning: React.FC = () => {
  const showToast = useToast();
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const { data, isLoading: isLoadingEnabled } = useFindOrgDefault(CONFIG_KEY);

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [toggleSwitchValue, setToggleSwitchValue] = useState<boolean>(
    !!data?.intValue
  );

  useEffect(() => {
    setToggleSwitchValue(!!data?.intValue);
  }, [data?.intValue]);

  const handleSaveOnSuccess = () => {
    showToast.success(tk('Setting saved.'));
    setSaveButtonDisabled(!saveButtonDisabled);
  };

  const handleSaveOnError = () => {
    showToast.error(tk('Failed to save setting. Please try again.'));
  };

  const handleChangeToggleSwitch = (checked: boolean) => {
    setSaveButtonDisabled((prev) => !prev);
    setToggleSwitchValue(checked);
  };

  const handleSave = () => {
    mutate({ intValue: toggleSwitchValue ? 1 : 0 });
  };

  const { mutate } = useSetOrgConfigDefault(
    CONFIG_KEY,
    handleSaveOnSuccess,
    handleSaveOnError,
    queryClient
  );

  if (isLoadingEnabled || data === undefined) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader name="Subscriptions">
        <Button
          onClick={handleSave}
          isDisabled={saveButtonDisabled}
          label="Save"
        />
      </CardHeader>
      <div className="flex justify-between mt-6">
        <div className="w-2/5">
          {tk(
            'Create a subscription when a customer signs the contract and before they complete payment.'
          )}
        </div>
        <div>
          <ToggleSwitch
            dataTestId="accept-requires-payment-method"
            label={tk('Enable')}
            onChange={handleChangeToggleSwitch}
            value={toggleSwitchValue}
            name="accept"
          />
        </div>
      </div>
    </Card>
  );
};

export default SubscriptionsOnSigning;
