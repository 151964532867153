import { useState, useCallback, useEffect } from 'react';

import { ActivityEvent } from 'common/src';

import { useRefundEvents } from 'app/src/services/transfer';

type RefundActivityDrawerType = {
  isRefundActivityDrawerOpen: false | true;
  events: ActivityEvent[] | null;
  isLoading: boolean;
  error: string | null;
};

export const useRefundActivityDrawer = (refundId: string) => {
  const [state, setState] = useState<RefundActivityDrawerType>({
    isRefundActivityDrawerOpen: false,
    events: null,
    isLoading: false,
    error: null,
  });

  const {
    isLoading,
    error,
    isError,
    data: refundEvents,
    refetch,
  } = useRefundEvents(refundId);

  const openRefundActivityDrawer = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isRefundActivityDrawerOpen: true,
      isLoading: true,
      error: null,
    }));
  }, [refetch]);

  const closeRefundActivityDrawer = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isRefundActivityDrawerOpen: false,
      events: [],
    }));
  }, []);

  useEffect(() => {
    if (!isLoading && state.isRefundActivityDrawerOpen) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        events: refundEvents ?? null,
        error: isError ? error.message : null,
      }));
    }
  }, [isLoading, refundEvents, isError, state.isRefundActivityDrawerOpen]);

  return {
    refundEvents: state.events,
    openRefundActivityDrawer,
    closeRefundActivityDrawer,
    isRefundActivityDrawerOpen: state.isRefundActivityDrawerOpen,
    isRefundActivityDrawerLoading: state.isLoading,
    refundActivityDrawerError: state.error,
  };
};
