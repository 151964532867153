import React, { useEffect, useState } from 'react';
import {
  assertDefined,
  Checkbox,
  Item,
  ScheduleLine,
  useTranslation,
} from 'common';
import styles from './TimeBasedPricing.module.scss';

const TimeBasedSubBar = ({
  item,
  scheduleLines,
  onUpdateSchedules,
}: {
  item: Item;
  onUpdateSchedules: (schedules: ScheduleLine[]) => void;
  scheduleLines: ScheduleLine[];
}) => {
  const { tk } = useTranslation();
  const basePrice = scheduleLines[0]?.basePrice;
  // we only want to enable the show-as-discount checkbox if all schedule lines are discounts
  const isAllDiscounts = scheduleLines.every(
    (scheduleLine) =>
      (scheduleLine.listPrice.amount || 0) >= (scheduleLine.price.amount || 0)
  );
  const [showAsDiscount, setShowAsDiscount] = useState(false);
  const product = assertDefined(item.product);
  const allowListPriceAdjustments = product.listPriceAdjustable;
  const isFixedPricing = product.pricing === 'fixed';

  const setDiscount = (discount: boolean) => {
    setShowAsDiscount(discount);
    scheduleLines.forEach((scheduleLine) => {
      scheduleLine.discount = discount;
    });
    onUpdateSchedules([...scheduleLines]);
  };

  useEffect(() => {
    setDiscount(isAllDiscounts && !!scheduleLines[0]?.discount);
  }, [scheduleLines[0]?.discount]);

  return (
    <div className={styles.timeBasedSubBar}>
      <Checkbox
        isDisabled={!isAllDiscounts}
        value={showAsDiscount}
        onChange={() => setDiscount(!showAsDiscount)}
        name="Show-as-discount"
        dataTestId="schedule-show-as-discount"
        label={tk('Display price(s) as discount')}
        className={styles.showAsDiscount}
      />
      {allowListPriceAdjustments && basePrice && isFixedPricing && (
        <div className={styles.basePrice}>
          Base price: {basePrice.formattedAmount}
        </div>
      )}
    </div>
  );
};

export default TimeBasedSubBar;
