import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  pushIf,
  QueryCell,
  QueryTable,
  SelectOption,
  Transaction,
  TransferSummaryRest,
  useFlags,
  useTranslation,
} from 'common';
import { getPaymentTypeString } from '../../core-utils/helperFunctions/paymentUtils';
import DateTimeCell from '../../components/DateTimeCell';
import { TransferStatus } from '../../services/transfer';
import TransactionDialog from './TransactionDialog';
import StatusLabel from '../../components/StatusLabel';
import TransferOptionsButton from 'app/src/components/Transfer/TransferOptionsButton';
import PaymentForm from 'app/src/components/Transfer/PaymentForm';
import Page from '../../components/Page';
import {
  getCreditAmount,
  getDebitAmount,
  getTransactionDetailLink,
} from '../../services/api/transactions/transactionUtils';
import { apiClient } from '../../services/httpClients/app';

const TransactionsPage = () => {
  const { tk } = useTranslation();
  const { invoices } = useFlags();
  const [transactionDialogState, setTransactionDialogState] = useState(false);
  const [transactionId, setTransactionId] = useState<string | undefined>();
  const [detailLink, setDetailLink] = useState<string | undefined>();
  const [transactionEditing, setTransactionEditing] = useState<
    Transaction | undefined
  >();

  const options: SelectOption<string>[] = Object.keys(TransferStatus).map(
    (key) => ({
      value: key,
      name: TransferStatus[key as keyof typeof TransferStatus].valueOf(),
    })
  );

  const cells: QueryCell<TransferSummaryRest>[] = [
    {
      key: 'processedAt',
      headerLabel: tk('Processed at'),
      width: '120px',
      sortable: true,
      renderCell: (transaction: TransferSummaryRest) => (
        <DateTimeCell date={transaction.processedAt} />
      ),
    },
    {
      key: 'invoice.schedule.invoiceDate',
      headerLabel: tk('Statement date'),
      width: 130,
      sortable: true,
      renderCell: (transaction: TransferSummaryRest) => (
        <DateTimeCell date={transaction.statementDate} showTime={false} />
      ),
    },
    {
      key: 'invoice.customer.name',
      headerLabel: tk('To / From'),
      width: '20%',
      overflowCell: true,
      sortable: true,
      renderCell: (transaction: TransferSummaryRest) => (
        <div>
          <div className="text-ellipsis overflow-hidden">
            {transaction.companyName || '-'}
          </div>
        </div>
      ),
    },
    {
      key: 'paymentMethod.paymentType',
      headerLabel: tk('Pay. method'),
      width: 110,
      sortable: true,
      renderCell: (transaction: TransferSummaryRest) =>
        transaction.paymentMethod?.name || '-',
    },
    ...pushIf(invoices, {
      key: 'referenceType',
      headerLabel: tk('Invoice'),
      width: '20%',
      overflowCell: true,
      sortable: true,
      renderCell: (transaction: TransferSummaryRest) => {
        const transactionDetailLink = getTransactionDetailLink(transaction);

        if (transactionDetailLink)
          return (
            <div>
              <div className="underline text-blue-darkest">
                <Link to={transactionDetailLink}>
                  {getPaymentTypeString(transaction)}
                </Link>
              </div>
            </div>
          );
        return <div>{getPaymentTypeString(transaction)}</div>;
      },
    }),
    {
      key: 'credit',
      sortKey: 'amount',
      headerLabel: tk('Credit'),
      align: 'right',
      width: '120px',
      sortable: true,
      renderCell: (data: TransferSummaryRest) => (
        <div className="text-right">{getCreditAmount(data) || ''}</div>
      ),
    },
    {
      key: 'debit',
      sortKey: 'amount',
      headerLabel: tk('Debit'),
      align: 'right',
      width: '120px',
      sortable: true,
      renderCell: (transaction: TransferSummaryRest) => (
        <div className="text-right">{getDebitAmount(transaction) || ''}</div>
      ),
    },
    {
      key: 'status',
      headerLabel: tk('Status'),
      width: '120px',
      sortable: true,
      renderCell: (transaction: TransferSummaryRest) => {
        return (
          <div
            className="text-center"
            onClick={() => {
              setDetailLink(getTransactionDetailLink(transaction) || 'na');
              setTransactionId(transaction.id);
              setTransactionDialogState(true);
            }}
          >
            <StatusLabel
              status={transaction.status || ''}
              feature="transaction"
            />
          </div>
        );
      },
    },
    {
      key: 'options',
      headerLabel: tk('Options'),
      align: 'center',
      width: 64,
      renderCell: (transaction: TransferSummaryRest) => (
        <div className="flex gap-2 items-center">
          <TransferOptionsButton
            referenceId={transaction.referenceId}
            referenceType={transaction.referenceType || ''}
            transfer={transaction}
            onClickViewActivity={() => {
              setTransactionId(transaction.id);
              setTransactionDialogState(true);
            }}
            onClickEvent={() => {
              setTransactionEditing(transaction);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <Page leftWidget="Transactions">
      <PaymentForm
        transfer={transactionEditing}
        isOpen={!!transactionEditing}
        onCloseForm={() => {
          setTransactionEditing(undefined);
        }}
        detailLink={detailLink || 'na'}
      />
      <QueryTable<TransferSummaryRest>
        filters={[
          {
            type: 'search',
            key: 'search',
            searchFields: ['invoice.customer.name'],
          },
          {
            type: 'dropdown',
            key: 'status',
            humanReadableName: 'Status',
            fieldName: 'status',
            options,
          },
        ]}
        fetchList={apiClient.listTransactions}
        queryKey="apiClient.listTransactions"
        cells={cells}
      />
      {transactionDialogState && (
        <TransactionDialog
          isOpen={transactionDialogState}
          setOpenState={setTransactionDialogState}
          transactionId={transactionId}
          detailLink={detailLink || 'na'}
        />
      )}
    </Page>
  );
};

export default TransactionsPage;
