import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Transaction, Transfer } from 'common';
import { apiClient } from '../../httpClients/app';

const TRANSACTION_PATH = '/api/latest/data/transactions';

const transactionPath = (id: string) => {
  return `${TRANSACTION_PATH}/${id}`;
};

export const useTransaction = (id: string): UseQueryResult<Transfer> => {
  return useQuery({
    queryKey: [transactionPath(id)],
    queryFn: async () => {
      const { data } = await apiClient.getTransaction(id);
      return data;
    },
  });
};

export const useTransactionChildren = (
  id: string
): UseQueryResult<Transaction[]> => {
  return useQuery({
    queryKey: ['apiClient.getTransactionChildren'],
    queryFn: async () => {
      const { data } = await apiClient.getTransactionChildren(id);
      return data;
    },
  });
};
