import React, { useEffect, useState } from 'react';
import { useCompanyDetailsForm, useDeleteCompanyDetails } from './hook';
import {
  AddressForm,
  alphaNumericValidator,
  Button,
  CardHeader,
  CompanyInfo,
  ConfirmDeleteModal,
  FileInput,
  FormField,
  getCountry,
  Input,
  TaxCountryRulesConfig,
  useFlags,
  useFormValidation,
  useTranslation,
} from 'common';
import CheckoutBranding from './CheckoutBranding';
import { useCountries, useRegion } from 'app/src/services/customer';
import { useFindOrgDefault } from '../../../services/orgDefaults';
import TooltipPill from 'app/src/components/TooltipPill';

interface Props {
  companyInfo: CompanyInfo;
  isDefault: boolean;
  setDefaultCompanyInfo: (companyInfo: CompanyInfo) => void;
  setCompanyInfo: (companyInfo: CompanyInfo) => void;
  removeCompanyInfo: (companyInfo: CompanyInfo) => void;
}

const CompanyEditForm: React.FC<Props> = ({
  companyInfo,
  isDefault,
  setDefaultCompanyInfo,
  removeCompanyInfo,
  setCompanyInfo,
}: Props) => {
  const { multiOrg, enableTaxId, shipFrom } = useFlags();
  const { tk } = useTranslation();
  const { submitHandler } = useCompanyDetailsForm({
    onSuccess: setCompanyInfo,
  });
  const { deleteHandler } = useDeleteCompanyDetails();
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [logo, setLogo] = useState<string | undefined>();
  const [logoData, setLogoData] = useState<File | undefined>();
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const { data: taxCountryRulesConfigData } = useFindOrgDefault(
    'taxCountryRulesConfig'
  );
  const isCollectTaxIdEnabled = !!(
    taxCountryRulesConfigData?.configValue as TaxCountryRulesConfig | undefined
  )?.collectTaxIdEnabled;

  const { isFormValid, getErrorToShow } = useFormValidation(
    [
      {
        fieldName: 'name',
        isRequired: true,
        humanReadableName: 'Name',
        validator: alphaNumericValidator,
      },
      {
        fieldName: 'internalName',
        isRequired: false,
        humanReadableName: 'Internal name',
        validator: alphaNumericValidator,
      },
    ],
    companyInfo
  );

  const { data: countries = [] } = useCountries();
  const { data: regions = [] } = useRegion(
    getCountry(companyInfo.billingAddress, countries)
  );
  useEffect(() => {
    if (logo) {
      setLogo(undefined);
    }
  }, [companyInfo]);

  const handleChange = (
    field: keyof CompanyInfo,
    value: CompanyInfo[keyof CompanyInfo]
  ) => {
    const companyInfoUpdate = {
      ...companyInfo,
      [field]: value,
    };
    setCompanyInfo(companyInfoUpdate);

    if (field === 'defaultInfo' && value) {
      setDefaultCompanyInfo(companyInfo);
    }

    setFormHasChanged(true);
  };

  const submitForm = async () => {
    if (isFormValid) {
      const isNew = isNewCompanyInfo();
      await submitHandler(companyInfo, logoData || null);
      setFormHasChanged(false);
      if (isNew) setCompanyInfo(companyInfo);
      setLogo(undefined);
      setLogoData(undefined);
    }
  };

  const submitDelete = async () => {
    if (isNewCompanyInfo()) {
      removeCompanyInfo(companyInfo);
      return;
    }

    await deleteHandler(companyInfo.id);
    removeCompanyInfo(companyInfo);
    setIsDeleteConfirmationOpen(false);
  };

  const isNewCompanyInfo = () => {
    return companyInfo.id === undefined;
  };

  return (
    <>
      <CardHeader name="Company details">
        <div className="flex flex-row gap-8 items-center">
          {shipFrom && (
            <>
              {!isDefault ? (
                <Button
                  dataTestId="company-info-make-default"
                  label={tk('Make default')}
                  onClick={() => handleChange('defaultInfo', true)}
                  type="link"
                />
              ) : (
                <TooltipPill label={tk('Default')} dataTestId="is-default" />
              )}
              {!isNewCompanyInfo() && !isDefault && (
                <Button
                  onClick={() => setIsDeleteConfirmationOpen(true)}
                  isDangerous
                  label="Delete"
                />
              )}
            </>
          )}
          <Button
            isDisabled={!formHasChanged || !isFormValid}
            onClick={submitForm}
            label={isNewCompanyInfo() ? 'Create' : 'Save'}
          />
        </div>
      </CardHeader>
      <div className="flex flex-col">
        <div className="flex flex-row gap-4">
          <FormField
            label="Legal company name"
            errorToShow={getErrorToShow('name')}
          >
            <Input
              dataTestId="legal-company-name"
              placeholder="Enter company name"
              value={companyInfo.name}
              onChange={(value) => handleChange('name', value)}
            />
          </FormField>

          {multiOrg && (
            <FormField
              label="Internal name"
              errorToShow={getErrorToShow('internalName')}
            >
              <Input
                id="company-internalname-input"
                placeholder="Enter internal name"
                onChange={(value) => handleChange('internalName', value)}
                value={companyInfo.internalName}
              />
            </FormField>
          )}
        </div>
        <div>
          <AddressForm
            addressData={companyInfo.billingAddress}
            onChange={(value) => handleChange('billingAddress', value)}
            countries={countries}
            regions={regions}
          />
          {((enableTaxId && isCollectTaxIdEnabled) || companyInfo.tin) && (
            <FormField
              label={tk('Tax ID')}
              infoTooltip={tk(
                'Enter the Tax Registration ID that you would like displayed on your proposals and invoices'
              )}
            >
              <Input
                id="tin"
                value={companyInfo.tin}
                onChange={(tin: string) => {
                  handleChange('tin', tin);
                }}
              />
            </FormField>
          )}
        </div>
      </div>
      <CardHeader name="Company branding" />
      <div className="flex flex-row gap-8">
        {(!!logo || !!companyInfo.logo) && (
          <div className="grid place-content-center p-2 border-[1px] border-gray-lines bg-slate-lightest">
            <img
              width="150px"
              src={logo || companyInfo.logo?.latestVersion?.url}
              alt="logo"
            />
          </div>
        )}
        <FormField label="Company logo">
          <FileInput
            placeholder="Please add your company logo"
            selectedFileName={companyInfo.logo?.fileName}
            onChange={(value) => {
              if (value) {
                setLogo(URL.createObjectURL(value));
                setLogoData(value);
                setFormHasChanged(true);
              }
            }}
          />
        </FormField>
      </div>
      <div className="pt-8">
        <CheckoutBranding
          companyInfo={companyInfo}
          onBrandingEnabledChange={(brandingEnabled) => {
            handleChange('brandingEnabled', brandingEnabled);
          }}
          onBrandingChange={(branding) => {
            handleChange('branding', branding);
          }}
        />
      </div>

      <ConfirmDeleteModal
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        onConfirm={() => submitDelete()}
        typeName="company"
      />
    </>
  );
};

export default CompanyEditForm;
