import React from 'react';
import {
  ConnectorInfo,
  Icon,
  Loading,
  OrgConfig,
  useTranslation,
} from 'common';
import { ConnectionStatus } from '../../../../components';
import SalesforceConnectionExtras from '../../Salesforce/SalesforceConnectionExtras';
import { useGetConnectionTest } from '../../../../services/connectors';

interface Props {
  connectorConfigValue?: any;
  connectorName: ConnectorInfo['source'];
  isLoading: boolean;
  setConnectorConfigValue: React.Dispatch<
    React.SetStateAction<OrgConfig['configValue']>
  >;
  setSavableChanges: (value: boolean) => void;
  setSyncSignedDocumentDraft: (value: boolean) => void;
  setSyncSubscriptionsDraft: (value: boolean) => void;
  syncSignedDocument: boolean;
  syncSignedDocumentDraft: boolean;
  syncSubscriptions: boolean;
  syncSubscriptionsDraft: boolean;
}

const ConnectorConnection: React.FC<Props> = ({
  isLoading,
  connectorConfigValue,
  setConnectorConfigValue,
  setSavableChanges,
  setSyncSubscriptionsDraft,
  syncSubscriptions,
  syncSubscriptionsDraft,
  connectorName,
  syncSignedDocument,
  syncSignedDocumentDraft,
  setSyncSignedDocumentDraft,
}) => {
  const { tk } = useTranslation();

  const {
    data: connectionResult,
    error,
    isLoading: isConnectionTestLoading,
  } = useGetConnectionTest(connectorName);

  if (isLoading || isConnectionTestLoading) {
    return <Loading />;
  }

  const hasissues = (connectionResult?.issues?.length ?? 0) > 0;

  const isError = !!error;

  const isConnectionInactive = connectionResult?.status !== 'active';

  const hasConnectionIssues = isError || hasissues || isConnectionInactive;

  const issueResults = () => {
    return connectionResult?.issues?.map((issue) => {
      return (
        <div
          key={issue.issueType}
          className="text-lg text-red-darker error-message"
        >
          <Icon.Exclamationmark width="32" height="32" />
          {tk(`issues.${issue.sourceType}.${issue.issueType}`)}
        </div>
      );
    });
  };

  return (
    <div className="connector-settings-tab">
      {hasissues && issueResults()}
      <ConnectionStatus
        isConnected={!hasConnectionIssues}
        message={hasConnectionIssues ? 'Not connected' : 'Connected'}
      />
      {connectionResult &&
        'salesforceUser' in connectionResult &&
        !!connectionResult.salesforceUser && (
          <SalesforceConnectionExtras
            connectorConfigValue={connectorConfigValue}
            setSavableChanges={setSavableChanges}
            setConnectorConfigValue={setConnectorConfigValue}
            syncSubscriptions={syncSubscriptions}
            syncSubscriptionsDraft={syncSubscriptionsDraft}
            setSyncSubscriptionsDraft={setSyncSubscriptionsDraft}
            syncSignedDocumentDraft={syncSignedDocumentDraft}
            syncSignedDocument={syncSignedDocument}
            setSyncSignedDocumentDraft={setSyncSignedDocumentDraft}
            salesforceUser={connectionResult.salesforceUser}
          />
        )}
    </div>
  );
};

export default ConnectorConnection;
