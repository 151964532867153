import React from 'react';
import { NavLink } from 'react-router-dom';
import IACActivated from './IACActivated';
import IACConnectButton from './IACConnectButton';

interface Props {
  description: string;
  gatewayType?: 'cacheflow' | 'paragon' | undefined;
  handleActivate: () => void;
  isConnected: () => boolean;
  isDisabled?: () => boolean;
  isLoading?: boolean;
  logo?: React.ReactElement;
  name: string;
  path: string;
}

const IACListItem = ({
  logo,
  name,
  description,
  gatewayType,
  isConnected,
  isLoading,
  handleActivate,
  isDisabled,
  path,
}: Props) => {
  return (
    <div className="iac-list-item" data-testid={name}>
      <section className="top">
        <div className="iac-logo-wrapper">{logo}</div>
        <div className="iac-text-wrapper">
          <h3>
            {' '}
            {isConnected() ? (
              <NavLink
                to={path}
                key={path}
                data-testid={`${name}-connector-link`}
              >
                {name}
              </NavLink>
            ) : (
              name
            )}
          </h3>
          <p>{description}</p>
        </div>
      </section>
      <section className="bottom">
        {isConnected() && <IACActivated />}
        <section className="right-section">
          <IACConnectButton
            isProcessing={isLoading}
            isConnected={isConnected()}
            isDisabled={isDisabled && isDisabled()}
            handleActivate={handleActivate}
            gatewayType={gatewayType}
          />
        </section>
      </section>
    </div>
  );
};

export default IACListItem;
