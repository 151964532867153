import React, { useEffect, useState } from 'react';
import {
  AddressDisplay,
  Card,
  CardHeader,
  CardRowValue,
  Contract,
  getBillingPeriodLabelAdjective,
  getPersonFullName,
  PURCHASE_ORDER_UPDATE_ERROR_MESSEGE,
  PURCHASE_ORDER_UPDATE_SUCCESS_MESSEGE,
  useToast,
} from 'common';
import POInputBox from 'app/src/components/POInputBox';

import { InvoiceInAdvanceBillingSection } from './InvoiceInAdvanceBillingSection/InvoiceInAdvanceBillingSection';
import { apiClient } from 'app/src/services/httpClients/app';
import POLabelWithValue from 'app/src/components/POLabelWithValue';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';

interface Props {
  contract: Contract;
}

const BillingDetailsCard = ({ contract }: Props) => {
  const canUpdate = isActionAllowed(FLAG_TYPES.CONTRACT, 'update');
  const billTo = contract.billTo || contract.buyer;

  const [isEditingPO, setIsEditingPO] = useState<boolean>(false);

  const [poDisplayValue, setPoDisplayValue] = useState<string>(
    contract.purchaseOrderNumber!
  );

  const showToast = useToast();

  const updatePO = async (poNumber: string) => {
    if (poNumber !== contract.purchaseOrderNumber) {
      await apiClient
        .updateContract(contract.id, {
          purchaseOrderNumber: poNumber,
        })
        .then(() => {
          showToast.success(PURCHASE_ORDER_UPDATE_SUCCESS_MESSEGE);
        })
        .catch(() => {
          showToast.error(PURCHASE_ORDER_UPDATE_ERROR_MESSEGE);
        });
      setIsEditingPO(false);
    } else {
      setIsEditingPO(false);
    }
  };

  useEffect(() => {
    setPoDisplayValue(contract.purchaseOrderNumber!);
  }, [contract]);

  const isPoEditable =
    contract.status === 'active' || contract.status === 'paused';

  const renderPO = () => {
    if (
      !isEditingPO &&
      (contract.purchaseOrderNumber !== undefined || isPoEditable)
    ) {
      return (
        <div>
          <POLabelWithValue
            label="PO Number"
            poDisplayValue={poDisplayValue}
            setEditingPO={setIsEditingPO}
            isEditDisabled={!isPoEditable || !canUpdate}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Card throughline>
      <CardHeader name="Billing details" throughline />
      <CardRowValue label="Payment frequency">
        <div className="pb-4">
          {getBillingPeriodLabelAdjective(contract.billingPeriod)}
        </div>
      </CardRowValue>
      {contract.billTo && (
        <CardRowValue label="Bill to">
          <div className="pb-4">{billTo.name}</div>
        </CardRowValue>
      )}
      <CardRowValue label="Billing address">
        <AddressDisplay address={billTo.billingAddress} className="pb-4" />
      </CardRowValue>

      <CardRowValue label="Billing contact person">
        <div>{getPersonFullName(billTo.billingContact)}</div>
      </CardRowValue>

      <CardRowValue label="Billing email">
        <div>{billTo.billingContact?.email}</div>
      </CardRowValue>
      <>
        {renderPO()}

        {isEditingPO && (
          <div className="w-full">
            <POInputBox
              label="PO Number"
              value={poDisplayValue}
              onChange={(e) => {
                setPoDisplayValue(e);
              }}
              onCancel={() => setIsEditingPO(false)}
              onSubmit={() => {
                updatePO(poDisplayValue).catch((error) => {
                  throw error;
                });
              }}
            />
          </div>
        )}
      </>

      <InvoiceInAdvanceBillingSection contract={contract} />
    </Card>
  );
};

export default BillingDetailsCard;
