import React from 'react';
import { SALESFORCE_INFO, salesforceTabs as tabs } from './SalesforceConstants';
import { accountContactsConfig } from './SalesforceConstants/AccountContactsConfig';
import ConnectorSettings from '../Connectors/ConnectorSettings';
import SalesforceLogoSVG from '../../../assets/integration-logos/salesforceLogoSVG';

const SalesforceSettings = () => {
  return (
    <ConnectorSettings
      accountContactsConfig={accountContactsConfig}
      connectorInfo={SALESFORCE_INFO}
      tabs={tabs}
      logo={SalesforceLogoSVG}
    />
  );
};

export default SalesforceSettings;
