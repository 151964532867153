import React from 'react';

import {
  ActivityEvent,
  Drawer,
  Icon,
  TransactionActivityDetails,
} from 'common';

import EventDisplay, {
  EventDisplayProps,
} from 'app/src/components/events/EventDisplay';
import {
  clusterByDate,
  getEventsPropsClusters,
  sortByDateAsc,
} from 'app/src/core-utils/helperFunctions/eventUtils';
import StatusLabel from 'app/src/components/StatusLabel';
import EventClusters from 'app/src/components/events/EventClusters';
import EventCluster from 'app/src/components/events/EventCluster';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refundEvents: ActivityEvent[] | null;
};

export const RefundActivityDrawer = ({
  isOpen,
  onClose,
  refundEvents,
}: Props) => {
  if (!refundEvents) return null;

  const getEventElements = (event: ActivityEvent) => {
    if (event.details?.type !== 'transaction') {
      return null;
    }

    const transactionActivity = event.details as TransactionActivityDetails;
    const { status } = transactionActivity;

    const statusPill = (
      <StatusLabel
        classNames="!p-2 !rounded !h-[18px]"
        feature="refund"
        status={status}
      />
    );

    switch (status) {
      case 'pending':
      case 'in_progress':
        return {
          label: 'Refund',
          icon: Icon.Hourglass,
          statusPill,
        };
      case 'posted':
        return {
          label: 'Refund',
          icon: Icon.Checkmark,
          statusPill,
        };
      case 'cancelled':
      case 'failed':
      case 'declined':
      case 'reversed':
        return {
          label: 'Refund',
          icon: Icon.Close,
          statusPill,
        };

      default:
        return null;
    }
  };

  const eventToEventDisplayPropsConverter = (
    event: ActivityEvent,
    _previousEvent?: ActivityEvent
  ): EventDisplayProps | null => {
    const eventElements = getEventElements(event);

    if (eventElements === null || !event.id || !event.createdAt) {
      return null;
    }

    return {
      id: event.id,
      createdAt: event.createdAt,
      person: event.actor?.name,
      ...eventElements,
    };
  };

  const eventsSortedByDate = sortByDateAsc(refundEvents);

  const eventsClusteredByDate =
    clusterByDate<ActivityEvent>(eventsSortedByDate);

  const eventPropsClusteredByDate = getEventsPropsClusters(
    eventsClusteredByDate,
    eventToEventDisplayPropsConverter
  );

  return (
    <Drawer header="Activity" isOpen={isOpen} onClose={onClose}>
      <EventClusters>
        {eventPropsClusteredByDate.map((cluster) => (
          <EventCluster date={cluster.date} key={cluster.date}>
            {cluster.propObjects.map((props) => (
              <EventDisplay key={props.id} {...props} />
            ))}
          </EventCluster>
        ))}
      </EventClusters>
    </Drawer>
  );
};
