import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isContractManaged } from './contractUtils';
import {
  ContractSummary,
  Filter,
  formatDateOrDefault,
  formatNullableFormattedAmount,
  getBillingPeriodLabelAdverb,
  getIconCell,
  Icon,
  IconWithTooltip,
  ItemIcon,
  pushIf,
  QueryCell,
  QueryTable,
  useFlags,
} from 'common';
import StatusLabel from '../../components/StatusLabel';
import { getPaymentMethodName } from './SubscriptionDetail/hook';
import Page from '../../components/Page';
import { getContractDisplayStatus } from '../../services/contractView';
import { apiClient } from '../../services/httpClients/app';
import { CONTRACTS_KEY } from '../../services/contract';
import { usePaymentMethods } from 'app/src/services/paymentMethods';
import SubscriptionOptionsButton from './SubscriptionDetail/SubscriptionBar/SubscriptionOptionsButton';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';

const ContractsListPage = () => {
  const navigate = useNavigate();
  const { pauseSubscriptions } = useFlags();
  const canUpdate = isActionAllowed(FLAG_TYPES.CONTRACT, 'update');

  const tableRowOnClick = (selectedRow: ContractSummary) => {
    navigate(`/subscriptions/${selectedRow.id}`);
  };

  const cells: QueryCell<ContractSummary>[] = [
    getIconCell(null, {
      renderCell: () => (
        <IconWithTooltip
          icon={ItemIcon.Subscription}
          tooltipText="Subscription"
        />
      ),
    }),
    {
      key: 'buyer.name',
      headerLabel: ['Customer', 'Subscription no.'],
      sortable: true,
      overflowCell: true,
      renderCell: (data: ContractSummary) => (
        <div>
          <div className="text-ellipsis overflow-hidden">
            {data.buyer.name || ''}
          </div>
          <div className="text-xs">{data.contractNumber || '-'}</div>
        </div>
      ),
    },
    {
      key: 'startDate',
      headerLabel: ['Start'],
      sortable: true,
      renderCell: (data: ContractSummary) =>
        formatDateOrDefault(data.startDate),
      overflowCell: true,
      width: 110,
    },
    {
      key: 'endDate',
      headerLabel: ['End'],
      sortable: true,
      renderCell: (data: ContractSummary) => formatDateOrDefault(data.endDate),
      overflowCell: true,
      width: 110,
    },
    {
      key: 'totalAmount',
      headerLabel: 'TCV',
      sortable: true,
      align: 'right',
      renderCell: (data: ContractSummary) =>
        formatNullableFormattedAmount(data.totalAmountFormatted),
    },
    {
      key: 'nextPayment',
      headerLabel: 'Next payment',
      renderCell: (data: ContractSummary) =>
        isContractManaged(data) && (
          <div>
            <div className="flex justify-start">
              {formatDateOrDefault(data.nextPaymentDue?.scheduleDate)}
            </div>
            <div className="flex justify-start text-xs opacity-75">
              {formatNullableFormattedAmount(
                data.nextPaymentDue?.amountDueFormatted
              )}
            </div>
          </div>
        ),
    },
    {
      key: 'billingPeriod',
      headerLabel: ['Bill. frequency', 'Method'],
      overflowCell: true,
      renderCell: (data: ContractSummary) => (
        <div>
          <div>{getBillingPeriodLabelAdverb(data.billingPeriod)}</div>
          <div>{getPaymentMethodName(data)}</div>
        </div>
      ),
      sortable: true,
    },
    {
      key: 'renewalConfigurationId',
      headerLabel: 'Auto-renewal',
      width: 60,
      renderCell: (data: ContractSummary) => {
        if (data.renewalType === 'autoRenewal') {
          return <Icon.RenewalAuto />;
        }

        return null;
      },
      sortable: true,
    },
    {
      key: 'status',
      headerLabel: 'Status',
      width: 110,
      renderCell: (data: ContractSummary) => (
        <div className="text-center">
          <StatusLabel
            status={getContractDisplayStatus(data)}
            feature="contract"
          />
        </div>
      ),
      sortable: true,
    },
    ...pushIf(canUpdate, {
      key: 'options',
      headerLabel: 'Options',
      clickable: true,
      width: 64,
      renderCell: (data: ContractSummary) => (
        <SubscriptionOptionsButton borderless={false} contract={data} />
      ),
    }),
  ];

  enum BillingFrequencies {
    month = 'Monthly',
    quarter = 'Quarterly',
    halfyear = 'Semi-annually',
    year = 'Annually',
  }

  const { data: paymentMethods } = usePaymentMethods();

  enum ContractDisplayStatuses {
    active = 'Active',
    ended = 'Ended',
    paused = 'Paused',
    cancelled = 'Cancelled',
  }

  const filterOptions: Filter[] = [
    {
      type: 'search',
      key: 'search',
      searchFields: ['buyer.name'],
    },
    {
      type: 'dropdown',
      key: 'status',
      humanReadableName: 'Status',
      fieldName: 'status',
      options: Object.keys(ContractDisplayStatuses)
        .filter((key) => {
          return key !== 'paused' || pauseSubscriptions;
        })
        .map((key) => ({
          value: key,
          name: ContractDisplayStatuses[
            key as keyof typeof ContractDisplayStatuses
          ].valueOf(),
        })),
    },
    {
      type: 'dropdown',
      key: 'billingPeriod',
      humanReadableName: 'Billing frequency',
      fieldName: 'billingPeriod',
      options: Object.keys(BillingFrequencies).map((key) => ({
        value: key,
        name: BillingFrequencies[
          key as keyof typeof BillingFrequencies
        ].valueOf(),
      })),
    },
    {
      type: 'dropdown',
      key: 'paymentMethod',
      humanReadableName: 'Payment method',
      fieldName: 'billingMethod.paymentMethod.id',
      options:
        paymentMethods?.map((key) => ({
          value: key.id!,
          name: key.name!,
        })) || [],
    },
    {
      type: 'valueRadio',
      key: 'totalAmount',
      humanReadableName: 'Value',
      fieldName: 'totalAmount',
      prefix: '$',
    },
    {
      type: 'dateRadio',
      key: 'startDate',
      humanReadableName: 'Start date',
      fieldName: 'startDate',
      optionsType: 'futureAndPast',
    },
    {
      type: 'dateRadio',
      key: 'endDate',
      humanReadableName: 'End date',
      fieldName: 'endDate',
      optionsType: 'futureAndPast',
    },
    {
      type: 'toggle',
      key: 'include_archived',
      humanReadableName: 'Include archived',
      fieldName: 'include_archived',
      separateQueryParam: true,
      value: 'true',
    },
  ];

  return (
    <Page leftWidget="Subscriptions">
      <QueryTable<ContractSummary>
        cells={cells}
        fetchList={apiClient.listContracts}
        filters={filterOptions}
        minWidth="900px"
        onRowClick={tableRowOnClick}
        queryKey={CONTRACTS_KEY}
        zeroStateMessage="There are no subscriptions to display."
      />
    </Page>
  );
};

export default ContractsListPage;
