import React, { useEffect, useState } from 'react';
import { Button, Card, CompanyInfo, isArray, Tabs, useFlags } from 'common';
import CompanyTimeZone from './CompanyTimeZone';
import PaymentErrorContact from './PaymentErrorContact';
import ReferenceNumberPrefix from './ReferenceNumberPrefix';
import { useAllCompanyInfo } from 'app/src/services/api/branding/companyInfo';
import ErrorHandler from 'app/src/components/ErrorHandler';
import CompanyEditForm from './CompanyEditForm';

interface Props {}

const CompanyDetailsForm: React.FC<Props> = () => {
  const { shipFrom } = useFlags();
  const { data: companyInfos, error, isLoading } = useAllCompanyInfo();
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({});
  const [defaultCompanyInfo, setDefaultCompanyInfo] = useState<CompanyInfo>({});

  const defaultCompany = () => {
    return isArray(companyInfos)
      ? companyInfos.find((i: CompanyInfo) => i.defaultInfo) || {}
      : {};
  };

  const sortCompanyInfos = (a: CompanyInfo, b: CompanyInfo): number => {
    if (a.defaultInfo === b.defaultInfo) {
      return 0;
    }

    return a.defaultInfo ? -1 : 1;
  };

  let sortedCompanies = companyInfos;

  useEffect(() => {
    if (companyInfos) {
      const info = defaultCompany();
      if (companyInfo.id === undefined) setCompanyInfo(info);
      setDefaultCompanyInfo(info);
      sortedCompanies = companyInfos.sort(sortCompanyInfos);
    }
  }, [companyInfos]);

  if (error || (!companyInfos && !isLoading)) {
    return <ErrorHandler error={error} />;
  }

  const isNewCompanyInfo = () => companyInfo.id === undefined;

  return (
    <>
      {shipFrom && (
        <div className="flex">
          <Button
            className="ml-auto mb-4"
            onClick={() => {
              isNewCompanyInfo()
                ? setCompanyInfo(defaultCompany())
                : setCompanyInfo({ name: '', internalName: '' });
            }}
            label={isNewCompanyInfo() ? 'Cancel' : 'Add company'}
          />
        </div>
      )}
      <Card>
        {shipFrom && (
          <div className="flex flex-row gap-4 justify-between">
            {sortedCompanies &&
            sortedCompanies.length > 1 &&
            !isNewCompanyInfo() ? (
              <Tabs
                tabs={sortedCompanies.map((info) => ({
                  label: info.internalName || info.name || '',
                  key: info.id!,
                }))}
                onChange={(tab) => {
                  const info = companyInfos?.find((i) => i.id === tab);
                  if (info) setCompanyInfo(info);
                }}
                defaultActiveTab={companyInfo.id}
              />
            ) : (
              <div />
            )}
          </div>
        )}
        <CompanyEditForm
          companyInfo={companyInfo}
          isDefault={companyInfo.id === defaultCompanyInfo.id}
          setDefaultCompanyInfo={setDefaultCompanyInfo}
          removeCompanyInfo={() => setCompanyInfo(defaultCompanyInfo)}
          setCompanyInfo={setCompanyInfo}
        />
      </Card>

      <Card header="Organization settings">
        <CompanyTimeZone />
        <PaymentErrorContact />
        <ReferenceNumberPrefix />
      </Card>
    </>
  );
};

export default CompanyDetailsForm;
