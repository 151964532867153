import React from 'react';
import { Button, CardRowValue, Input } from 'common';

interface Props {
  label: string;
  onCancel: () => void;
  onChange: (value: string) => void;
  onSubmit: () => void;
  value: string;
}

const POInputBox: React.FC<Props> = ({
  onCancel,
  label,
  value,
  onChange,
  onSubmit,
}) => {
  return (
    <div>
      <CardRowValue alignLabel="center" label={label}>
        <div className="flex flex-row gap-2">
          <Input value={value} onChange={onChange} maxLength={20} />
          <Button type="secondary" label="Cancel" onClick={onCancel} />
          <Button label="Submit" onClick={onSubmit} />
        </div>
      </CardRowValue>
    </div>
  );
};

export default POInputBox;
