import React from 'react';

import {
  Card,
  CardHeader,
  CardRowValue,
  getPaymentTypeFormatted,
  Refund,
  Type,
} from 'common';

import IDField from 'app/src/components/IDField';
import DateTimeCell from 'app/src/components/DateTimeCell';

import styles from './RefundSummaryCards.module.scss';

interface Props {
  refund: Refund;
}

export const RefundSummaryCards: React.FC<Props> = ({ refund }) => {
  return (
    <div className={styles.refundSummaryCards}>
      <div className={styles.side}>
        <Card bottomMargin grow throughline>
          <CardHeader name="Refund" throughline />
          {refund.id && (
            <CardRowValue label="Id">
              <IDField documentID={refund.id}>
                <div>{refund.id.substring(0, 8)}</div>
              </IDField>
            </CardRowValue>
          )}
          <CardRowValue label="Customer name">
            <Type bold>{refund.customer?.name}</Type>
          </CardRowValue>
          <CardRowValue label="Currency">
            {refund.transfer.currency}
          </CardRowValue>
          <CardRowValue label="Notes">{refund.note}</CardRowValue>
        </Card>
      </div>

      <div className={styles.side}>
        <Card bottomMargin grow throughline>
          <CardRowValue label="Refund date">
            <DateTimeCell date={refund.createdAt} showTime={false} />
          </CardRowValue>
          <CardRowValue label="Processed date">
            <DateTimeCell date={refund.processDate} showTime={false} />
          </CardRowValue>
          <CardRowValue label="Payment method">
            {getPaymentTypeFormatted(
              refund.transfer.paymentMethod?.paymentType
            )}
          </CardRowValue>
          <CardRowValue label="Reference number">-</CardRowValue>
          <CardRowValue label="Amount">{refund.amountFormatted}</CardRowValue>
        </Card>
      </div>
    </div>
  );
};
