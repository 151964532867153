import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getIconCell,
  Icon,
  IconWithTooltip,
  NavigationIcon,
  PopOutMenu,
  PopOutMenuOption,
  QueryTable,
  Refund,
} from 'common';

import { Page } from 'app/src/components';
import { refundStatusOptions } from '../refundUtils';
import StatusLabel from 'app/src/components/StatusLabel';
import { apiClient } from 'app/src/services/httpClients/app';
import { useRefundActivityDrawer } from '../hooks/useRefundActivityDrawer';
import { RefundActivityDrawer } from '../components/RefundActivityDrawer/RefundActivityDrawer';

export const RefundsPage = () => {
  const navigate = useNavigate();
  const [selectedRefund, setSelectedRefund] = useState<Refund | null>(null);

  const {
    refundEvents,
    isRefundActivityDrawerOpen,
    openRefundActivityDrawer,
    closeRefundActivityDrawer,
  } = useRefundActivityDrawer(selectedRefund?.id ?? '');

  const cells = [
    getIconCell(null, {
      renderCell: () => (
        <IconWithTooltip icon={NavigationIcon.Refund} tooltipText="Refund" />
      ),
    }),
    {
      key: 'no',
      headerLabel: 'No.',
      renderCell: (refund: Refund) => {
        return refund.id;
      },
    },
    {
      key: 'customer',
      headerLabel: 'Customer',
      renderCell: (refund: Refund) => {
        return refund.customer?.name;
      },
    },
    {
      key: 'date',
      headerLabel: 'Date',
      renderCell: (refund: Refund) => {
        return refund.processDate;
      },
    },
    {
      key: 'amount',
      headerLabel: 'Amount',
      renderCell: (refund: Refund) => {
        return `${refund.amountFormatted} ${refund.transfer.currency}`;
      },
    },
    {
      key: 'status',
      headerLabel: 'Status',
      renderCell: (refund: Refund) => {
        return <StatusLabel feature="refund" status={refund.status} />;
      },
    },
    {
      key: 'options',
      headerLabel: 'Option',
      width: 64,
      clickable: true,
      renderCell: (refund: Refund) => (
        <PopOutMenu>
          <PopOutMenuOption
            icon={Icon.Activity}
            onClick={() => {
              setSelectedRefund(refund);
              openRefundActivityDrawer();
            }}
            title="Refund activity"
          />
        </PopOutMenu>
      ),
    },
  ];

  const handleTableRowClick = (clickedRefund: Refund) => {
    navigate(`/refunds/${clickedRefund.id}`);
  };

  return (
    <Page leftWidget="Refunds">
      <QueryTable<Refund>
        cells={cells}
        fetchList={apiClient.listRefunds}
        filters={[
          {
            type: 'search',
            key: 'search',
            searchFields: ['refund.customer.name'],
          },
          {
            type: 'dropdown',
            key: 'status',
            humanReadableName: 'Status',
            fieldName: 'status',
            options: refundStatusOptions(),
          },
          {
            type: 'valueRadio',
            key: 'amount',
            humanReadableName: 'Amount',
            fieldName: 'amount',
            prefix: '$',
          },
          {
            type: 'dateRadio',
            key: 'processDate',
            humanReadableName: 'Refund date',
            fieldName: 'processDate',
            optionsType: 'futureAndPast',
          },
        ]}
        isLoading={false}
        minWidth="900px"
        onRowClick={handleTableRowClick}
        queryKey="apiClient.listRefunds"
        zeroStateMessage="There are no refund records to display."
      />

      <RefundActivityDrawer
        isOpen={isRefundActivityDrawerOpen}
        onClose={closeRefundActivityDrawer}
        refundEvents={refundEvents}
      />
    </Page>
  );
};
