import {
  QueryClient,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  AcceptRequest,
  ApprovalAction,
  ApprovalRequest,
  ApprovalRequirements,
  captureError,
  CoverLetterRequest,
  CreateProposalRequest,
  getErrorMessage,
  getErrorStatus,
  getMilliseconds,
  InvoiceConfiguration,
  InvoiceConfigurationRequest,
  isString,
  Item,
  ItemDeltaSpansResponse,
  ItemSchedule,
  ItemScheduleRequest,
  Nullable,
  ProductUpgrade,
  Proposal,
  ProposalApprovalRequest,
  ProposalEvent,
  ProposalItemRequest,
  RenewalConfiguration,
  RenewalConfigurationRequest,
  useToast,
} from 'common';
import { useEffect, useState } from 'react';
import { useAuth } from '../Auth';
import {
  FLAG_TYPES,
  isActionAllowed,
  useIsCurrentUserAdmin,
} from '../core-utils/helperFunctions/userServiceHelper';
import { apiClient } from './httpClients/app';
import { PROPOSALS_TEMPLATES_PATH } from './proposalTemplate';

export const GET_ALL_PROPOSALS_KEY = 'allProposals';
const GET_SPEC_PROPOSAL_PATH = '/api/latest/proposals/';
const CREATE_PROPOSAL_PATH = '/api/latest/proposals';
const DOCUSIGN_PROPOSAL_KEY = 'useDocusignProposalKey';
const GET_PROPOSAL_EVENTS = 'proposalEvents';
const COPY_PROPOSAL_KEY = 'copyProposal';

const GET_PROPOSAL_APPROVAL_REQUIREMENTS_KEY = 'proposalApprovalRequirements';

const getErrorToast = (prefix: string, error: unknown) =>
  [prefix, getErrorMessage(error)].filter(isString).join(': ');

const getProposalApprovalRequirementsQueryKey = (id: string) => {
  return `${GET_PROPOSAL_APPROVAL_REQUIREMENTS_KEY}_${id}`;
};

const getProposalQueryKey = (id: string) => {
  return `${GET_SPEC_PROPOSAL_PATH}/${id}`;
};

const getProposalEventsQueryKey = (id: string) => {
  return `${getProposalQueryKey(id)}/events`;
};

const getProposalDeltaSpansQueryKey = (id: string) => {
  return `${getProposalQueryKey(id)}/delta-spans`;
};

const getProposalCurrentApprovalRequestQueryKey = (id: string) => {
  return `${getProposalQueryKey(id)}/approval_requests`;
};

/**
 * Clear all request cache information regarding a particular proposal.
 */
export const clearProposalCache = async (
  proposalId: string,
  qc: QueryClient
) => {
  await Promise.all([
    qc.invalidateQueries({
      queryKey: [GET_ALL_PROPOSALS_KEY],
      refetchType: 'all',
    }),
    qc.invalidateQueries({
      queryKey: [getProposalQueryKey(proposalId)],
      refetchType: 'all',
    }),
    qc.invalidateQueries({
      queryKey: [getProposalEventsQueryKey(proposalId)],
      refetchType: 'all',
    }),
    qc.invalidateQueries({
      queryKey: [getProposalApprovalRequirementsQueryKey(proposalId)],
      refetchType: 'all',
    }),
    qc.invalidateQueries({
      queryKey: [getProposalDeltaSpansQueryKey(proposalId)],
      refetchType: 'all',
    }),
  ]);
};

const getProposal = async (proposalId: string) => {
  const { data } = await apiClient.getProposal(proposalId);
  return data;
};

export const useGetProposal = (id: string): UseQueryResult<Proposal> =>
  useQuery({
    queryKey: [getProposalQueryKey(id)],
    queryFn: () => getProposal(id),
  });

export const getProposalByExternalId = async (externalId: string) => {
  try {
    const { data } = await apiClient.getProposalByExternalId(externalId);
    return data;
  } catch (err: unknown) {
    if (getErrorStatus(err) === 404) {
      return null;
    } else {
      throw err;
    }
  }
};

export const useIsProposalApprover = (
  approvalRequest?: ApprovalRequest | null
): boolean => {
  const auth = useAuth();
  if (useIsCurrentUserAdmin()) {
    return true;
  }

  if (!approvalRequest) {
    return false;
  }

  return !!approvalRequest.approvers?.find((approverConfig) => {
    if (approverConfig.userId === auth.user?.id) {
      return true;
    }

    return auth.groups?.find((group) => group.id === approverConfig.groupId);
  });
};

export const useIsProposalEditable = (
  proposal: Proposal,
  approvalRequest?: ApprovalRequest | null
): boolean => {
  const isReadOnly =
    !isActionAllowed(FLAG_TYPES.PROPOSAL, 'create') &&
    !isActionAllowed(FLAG_TYPES.PROPOSAL, 'update');
  if (isReadOnly) {
    return false;
  }
  const isApprover = useIsProposalApprover(approvalRequest);
  if (proposal.status === 'draft') {
    return true;
  }

  if (proposal.status === 'pending_approval') {
    return isApprover;
  }
  return false;
};

export const useGetProposalEvents = (
  id: string
): UseQueryResult<ProposalEvent[]> =>
  useQuery({
    queryKey: [getProposalEventsQueryKey(id)],
    queryFn: async () => {
      if (!id) throw new Error();
      const { data } = await apiClient.getProposalEvents(id);
      return data;
    },
  });

export const useGetProposalUpgrades = (
  id: string,
  enabled?: boolean
): UseQueryResult<ProductUpgrade[]> =>
  useQuery({
    queryKey: ['getAvailableProposalUpgrades', id],
    queryFn: async () => {
      if (!id) throw new Error();
      const { data } = await apiClient.getAvailableProposalUpgrades(id);
      return data;
    },
    enabled,
  });

const updateSigningDocument = async (
  proposalId: string,
  isPolling: boolean = true
): Promise<Proposal> => {
  const { data } = await apiClient.updateSigningDocument(proposalId, {
    poll: isPolling,
  });
  return data;
};

export const useUpdateSigningDocument = (
  id: string,
  onSuccess: (data: Proposal) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<Proposal, void, { isPolling: boolean }>({
    mutationKey: [DOCUSIGN_PROPOSAL_KEY],
    mutationFn: (data: { isPolling: boolean }) =>
      updateSigningDocument(id, data.isPolling),
    onSuccess,
    onError,
    onSettled: async (data) => {
      await clearProposalCache(data?.id!, qc);
      qc.setQueryData([getProposalQueryKey(data?.id!)], data);
    },
  });

const updateProposal = async (
  id: string | undefined,
  body: Nullable<CreateProposalRequest>
): Promise<Proposal> => {
  const { data } = await apiClient.updateProposal(
    id!,
    body as CreateProposalRequest
  );

  return data;
};

export const useUpdateProposal = (
  id: string | undefined,
  args?: Parameters<
    typeof useMutation<Proposal, unknown, Nullable<CreateProposalRequest>>
  >[0]
) => {
  const qc = useQueryClient();
  const showToast = useToast();

  return useMutation<Proposal, unknown, Nullable<CreateProposalRequest>>({
    onError: (err) =>
      showToast.error(getErrorToast('Proposal update failed', err)),
    ...args,
    mutationKey: [getProposalQueryKey(id ?? 'new')],
    mutationFn: (data: Nullable<CreateProposalRequest>) =>
      updateProposal(id, data),

    onSuccess: (data, ...passDownArgs) => {
      args?.onSuccess?.(data, ...passDownArgs);
      qc.setQueryData([getProposalQueryKey(data.id)], data);
    },
    onSettled: async (data, ...passDownArgs) => {
      await qc.invalidateQueries({
        queryKey: [GET_ALL_PROPOSALS_KEY],
      });
      // this matches by list prefix.
      if (data) {
        await qc.invalidateQueries({
          queryKey: [getProposalEventsQueryKey(data.id)],
        });
        await qc.invalidateQueries({
          queryKey: [getProposalApprovalRequirementsQueryKey(data.id)],
        });
      }
      args?.onSettled?.(data, ...passDownArgs);
    },
  });
};

const addProposalItem = async (
  id: string,
  body: ProposalItemRequest
): Promise<Item> => {
  const { data } = await apiClient.addProposalItem(id, body);

  return data;
};

export const useAddProposalItem = (
  id: string,
  args?: Parameters<typeof useMutation<Item, unknown, ProposalItemRequest>>
): UseMutationResult<Item, unknown, ProposalItemRequest> => {
  const queryClient = useQueryClient();
  const showToast = useToast();

  return useMutation<Item, unknown, ProposalItemRequest>({
    mutationKey: ['add-proposal-item'],
    mutationFn: (data) => addProposalItem(id, data),
    onSettled: async () => {
      await clearProposalCache(id, queryClient);
    },
    onError: (error) =>
      showToast.error(getErrorToast('Item add failed', error)),
    ...args,
  });
};

interface updateArgs {
  body: ProposalItemRequest;
  id: string;
}

interface batchUpdateArgs {
  body: ProposalItemRequest[];
  id: string;
}

interface addItemScheduleArgs {
  body: ItemScheduleRequest;
  itemId: string;
}

const addItemSchedule = async (
  input: addItemScheduleArgs
): Promise<ItemSchedule> => {
  const { data } = await apiClient.addItemSchedule(input.itemId, input.body);
  return data;
};

export const useAddItemSchedule = (
  proposalId: string | undefined,
  onSuccess: (() => void) | null,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<ItemSchedule, unknown, addItemScheduleArgs>({
    mutationKey: ['add-item-schedule'],
    mutationFn: (data) => addItemSchedule(data),
    onSuccess: onSuccess ? onSuccess : undefined,
    onError,
    onSettled: async () => {
      if (proposalId) await clearProposalCache(proposalId, qc);
    },
  });

const deleteItemSchedule = async (id: string): Promise<void> => {
  await apiClient.deleteItemSchedule(id);
};

export const useDeleteItemSchedule = (
  proposalId: string | undefined,
  onSuccess: (() => void) | null,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<unknown, unknown, string>({
    mutationKey: ['delete-item-schedule'],
    mutationFn: (data) => deleteItemSchedule(data),
    onSuccess: onSuccess ? onSuccess : undefined,
    onError,
    onSettled: async () => {
      if (proposalId) await clearProposalCache(proposalId, qc);
    },
  });

interface updateItemScheduleArgs {
  body: ItemScheduleRequest;
  itemScheduleId: string;
}

const updateItemSchedule = async (
  input: updateItemScheduleArgs
): Promise<ItemSchedule> => {
  const { data } = await apiClient.updateItemSchedule(
    input.itemScheduleId,
    input.body
  );
  return data;
};

export const useUpdateItemSchedule = (
  proposalId?: string | undefined,
  args?: Parameters<
    typeof useMutation<ItemSchedule, unknown, updateItemScheduleArgs>
  >[0]
) => {
  const queryClient = useQueryClient();
  const showToast = useToast();

  return useMutation<ItemSchedule, unknown, updateItemScheduleArgs>({
    mutationKey: ['update-item-schedule'],
    mutationFn: (data) => updateItemSchedule(data),
    onError: (error) =>
      showToast.error(getErrorToast('Item schedule update failed', error)),
    onSettled: async () => {
      if (proposalId) await clearProposalCache(proposalId, queryClient);
    },
    ...args,
  });
};

const updateProposalItem = async ({ body, id }: updateArgs): Promise<Item> => {
  const { data } = await apiClient.updateProposalItem(id, body);

  return data;
};

export const useUpdateProposalItem = (
  proposalId?: string | undefined,
  args?: Parameters<typeof useMutation<Item, unknown, updateArgs>>[0]
): UseMutationResult<Item, unknown, updateArgs> => {
  const queryClient = useQueryClient();
  const showToast = useToast();

  return useMutation<Item, unknown, updateArgs>({
    mutationKey: ['update-proposal-item'],
    mutationFn: (data) => updateProposalItem(data),
    onSettled: async () => {
      if (proposalId) await clearProposalCache(proposalId, queryClient);
    },
    onError: (error) =>
      showToast.error(getErrorToast('Proposal item update failed', error)),
    ...args,
  });
};

const updateBatchProposalItems = async ({
  body,
  id,
}: batchUpdateArgs): Promise<Item[]> => {
  const { data } = await apiClient.updateProposalItems(id, body);

  return data;
};

export const useBatchUpdateProposalItems = (
  proposalId?: string,
  args?: Parameters<typeof useMutation<Item[], unknown, batchUpdateArgs>>[0]
) => {
  const queryClient = useQueryClient();

  return useMutation<Item[], unknown, batchUpdateArgs>({
    mutationKey: ['update-proposal-item'],
    mutationFn: (data) => updateBatchProposalItems(data),
    onSettled: async (...passDownArgs) => {
      await args?.onSettled?.(...passDownArgs);
      if (proposalId) await clearProposalCache(proposalId, queryClient);
    },
    ...args,
  });
};

const deleteProposalItem = async (id: string): Promise<void> => {
  const { data } = await apiClient.deleteProposalItem(id);
  return data;
};

export const useDeleteProposalItem = (
  proposalId: string | undefined,
  onSuccess: (() => void) | null,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<unknown, unknown, string>({
    mutationKey: ['delete-proposal-item'],
    mutationFn: (data) => deleteProposalItem(data),
    onSuccess: onSuccess ? onSuccess : undefined,
    onError,
    onSettled: async () => {
      if (proposalId) await clearProposalCache(proposalId, qc);
    },
  });

export type CopyProposalStrategy = NonNullable<
  Parameters<typeof apiClient.copyProposal>[1]
>['strategy'];

interface copyArgs {
  id: string;
  strategy?: CopyProposalStrategy;
}

const copyProposal = async ({ id, strategy }: copyArgs): Promise<Proposal> => {
  const { data } = await apiClient.copyProposal(
    id,
    strategy ? { strategy } : {}
  );
  return data;
};

export const useCopyProposal = (
  onSuccess: (data: Proposal) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [COPY_PROPOSAL_KEY],
    mutationFn: copyProposal,
    onSuccess: async (data) => {
      onSuccess(data);
      await Promise.all([
        qc.invalidateQueries({
          queryKey: [GET_ALL_PROPOSALS_KEY],
        }),
        qc.invalidateQueries({
          queryKey: [PROPOSALS_TEMPLATES_PATH],
        }),
      ]);
    },
    onError,
  });

const updateCoverLetter = async (
  proposalId: string,
  params: CoverLetterRequest
) => {
  const { data } = await apiClient.updateCoverLetter(proposalId, params);
  return data;
};

export const useUpdateCoverLetter = (
  proposalId: string,
  onSuccess: (data: Proposal) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [CREATE_PROPOSAL_PATH],
    mutationFn: async (params: CoverLetterRequest) => {
      return updateCoverLetter(proposalId, params);
    },
    onSuccess,
    onError,
    onSettled: async () => {
      if (proposalId) await clearProposalCache(proposalId, qc);
    },
  });

const submitProposal = async (params: CreateProposalRequest) => {
  const { data } = await apiClient.createProposal(params);
  return data;
};

export const useSubmitProposal = (
  onSuccess: (data: Proposal) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [CREATE_PROPOSAL_PATH],
    mutationFn: submitProposal,
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [GET_ALL_PROPOSALS_KEY],
      });
      await qc.invalidateQueries({
        queryKey: [PROPOSALS_TEMPLATES_PATH],
      });
    },
  });

const acceptProposal = async (id: string, acceptRequest?: AcceptRequest) => {
  const { data } = await apiClient.acceptProposal(id, acceptRequest || {});
  return data;
};

export const useAcceptProposal = (
  id: string,
  onSuccess: (data: Proposal) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getProposalQueryKey(id)],
    mutationFn: (acceptRequest?: AcceptRequest) =>
      acceptProposal(id, acceptRequest),
    onSuccess,
    onError,
    onSettled: async () => {
      await clearProposalCache(id, qc);
    },
  });

const archiveProposals = async (ids: string[]) => {
  const { data } = await apiClient.archiveProposals({ ids });
  return data;
};

const restoreProposals = async (ids: string[]) => {
  const { data } = await apiClient.restoreProposals({ ids });
  return data;
};

export const useArchiveProposal = (
  id: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getProposalQueryKey(id)],
    mutationFn: () => archiveProposals([id]),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [GET_ALL_PROPOSALS_KEY],
      });
      await qc.invalidateQueries({
        queryKey: [PROPOSALS_TEMPLATES_PATH],
      });
    },
  });

export const useRestoreProposal = (
  id: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getProposalQueryKey(id)],
    mutationFn: () => restoreProposals([id]),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [GET_ALL_PROPOSALS_KEY],
      });
      await qc.invalidateQueries({
        queryKey: [PROPOSALS_TEMPLATES_PATH],
      });
    },
  });

const deleteProposal = async (id: string) => {
  const { data } = await apiClient.deleteProposal(id);
  return data;
};

export const useDeleteProposal = (
  id: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getProposalQueryKey(id)],
    mutationFn: () => deleteProposal(id),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [GET_ALL_PROPOSALS_KEY],
      });
      await qc.invalidateQueries({
        queryKey: [PROPOSALS_TEMPLATES_PATH],
      });
    },
  });

const cancelProposal = async (id: string) => {
  const { data } = await apiClient.cancelProposal(id);
  return data;
};

export const useCancelProposal = (
  id: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getProposalQueryKey(id)],
    mutationFn: () => cancelProposal(id),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [GET_ALL_PROPOSALS_KEY],
      });
    },
  });

export const useGetAllProposalApprovalRequests = (
  proposalId: string
): UseQueryResult<ApprovalRequest[]> => {
  return useQuery({
    queryKey: ['getApprovalRequests', proposalId],
    queryFn: async () => {
      const { data } = await apiClient.getApprovalRequests(proposalId);
      return data;
    },
    enabled: !!proposalId,
  });
};

export const useGetProposalApprovalRequests = async (id: string) => {
  const { data } = await apiClient.getApprovalRequests(id);
  return data;
};

export const useGetAllPendingProposalApprovalRequests = (
  id: string
): UseQueryResult<ApprovalRequest[] | null> => {
  return useQuery({
    queryKey: [getProposalCurrentApprovalRequestQueryKey(id), 'getAllRequests'],
    queryFn: async () => {
      const { data } = await apiClient.getApprovalRequests(id);
      return data.filter((ar) => ar.status === 'pending');
    },
    refetchInterval: getMilliseconds({ minutes: 1 }),
    refetchOnWindowFocus: true,
  });
};

export const useGetPendingProposalApprovalRequest = (
  id: string
): UseQueryResult<ApprovalRequest | null> => {
  const [lastApprovalRequestStatus, setLastApprovalRequestStatus] =
    useState<ApprovalRequest['status']>();
  const qc = useQueryClient();
  const queryResult = useQuery({
    queryKey: [getProposalCurrentApprovalRequestQueryKey(id)],
    queryFn: async () => {
      const { data } = await apiClient.getApprovalRequests(id);
      return data.find((ar) => ar.status === 'pending') || null;
    },
    refetchInterval: getMilliseconds({ minutes: 1 }),
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (!queryResult.data) {
      return;
    }

    if (queryResult.data.status !== lastApprovalRequestStatus) {
      qc.invalidateQueries({
        queryKey: [getProposalQueryKey(id)],
      }).catch(captureError);
    }

    setLastApprovalRequestStatus(queryResult.data.status);
  }, [queryResult.data]);

  return queryResult;
};

const createProposalApprovalRequest = async (
  id: string,
  approvalRequest: ProposalApprovalRequest
) => {
  const { data } = await apiClient.requestApproval(id, approvalRequest);
  return data;
};

export const useCreateProposalApprovalRequest = (
  id: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [GET_SPEC_PROPOSAL_PATH],
    mutationFn: (data: ProposalApprovalRequest) =>
      createProposalApprovalRequest(id, data),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [getProposalCurrentApprovalRequestQueryKey(id)],
      });
      await clearProposalCache(id, qc);
    },
  });

const performBatchApprovalActions = async (
  approvalRequestIds: string[],
  action: ApprovalAction['action'],
  message: string
): Promise<void> => {
  const payload = {
    action,
    message,
  };

  // This has to run sequantially due to database locking exceptions
  for (const id of approvalRequestIds) {
    await apiClient.createApprovalAction(id, payload);
  }
};

type BatchApprovalActionParams = {
  action: ApprovalAction['action'];
  approvalRequestIds: string[];
  message: string;
};

export const usePerformBatchApprovalActions = (
  proposalId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      approvalRequestIds,
      action,
      message,
    }: BatchApprovalActionParams) =>
      performBatchApprovalActions(approvalRequestIds, action, message),
    onSuccess,
    onError,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [getProposalCurrentApprovalRequestQueryKey(proposalId)],
      });
      await clearProposalCache(proposalId, queryClient);
    },
  });
};

const createProposalApprovalAction = async (
  id: string,
  action: ApprovalAction
) => {
  const { data } = await apiClient.createApprovalAction(id, action);
  return data;
};

export const useCreateProposalApprovalAction = (
  proposalId: string,
  approvalRequestId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [GET_SPEC_PROPOSAL_PATH],
    mutationFn: (data: ApprovalAction) =>
      createProposalApprovalAction(approvalRequestId, data),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [getProposalCurrentApprovalRequestQueryKey(proposalId)],
      });
      await clearProposalCache(proposalId, qc);
    },
  });

export const createProposalEvent = async (
  qc: QueryClient,
  proposalId: string,
  event: ProposalEvent
): Promise<ProposalEvent> => {
  const { data } = await apiClient.addProposalEvent(proposalId, event);
  await qc.invalidateQueries({
    queryKey: [GET_PROPOSAL_EVENTS],
  });
  return data;
};

export const useGetProposalApprovalRequirements = (
  proposalId: string
): UseQueryResult<ApprovalRequirements> =>
  useQuery({
    queryKey: [getProposalApprovalRequirementsQueryKey(proposalId)],
    queryFn: async () => {
      const { data } = await apiClient.getApprovalRequirements(proposalId);
      return data;
    },
  });

export const useApprovalRequiredForProposal = (proposalId: string) => {
  const { data: approvalRequirements } =
    useGetProposalApprovalRequirements(proposalId);
  const [approvalRequired, setApprovalRequired] = useState(false);
  useEffect(() => {
    if (approvalRequirements) {
      setApprovalRequired(approvalRequirements.requiresApproval!);
    }
  }, [approvalRequirements]);

  return approvalRequired;
};

const postRenewalConfig = async (
  proposalId: string,
  body: RenewalConfigurationRequest = {}
): Promise<RenewalConfiguration> => {
  const { data } = await apiClient.createRenewalConfiguration(proposalId, body);

  return data;
};

export const usePostRenewalConfig = (
  proposalId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<RenewalConfiguration, Error, RenewalConfigurationRequest | {}>({
    mutationFn: (body) => postRenewalConfig(proposalId, body),
    onSuccess,
    onError,
    onSettled: async () => {
      await clearProposalCache(proposalId, qc);
    },
  });

const deleteRenewalConfig = async (proposalId: string): Promise<void> => {
  await apiClient.deleteRenewalConfiguration(proposalId);
};

export const useDeleteRenewalConfig = (
  proposalId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<void, Error, Proposal['id']>({
    mutationFn: () => deleteRenewalConfig(proposalId),
    onSuccess,
    onError,
    onSettled: async () => {
      await clearProposalCache(proposalId, qc);
    },
  });

const patchRenewalConfig = async (
  renewalId: string,
  body: RenewalConfigurationRequest = {}
): Promise<RenewalConfiguration> => {
  const { data } = await apiClient.patchRenewalConfiguration(renewalId, body);

  return data;
};

export const usePatchRenewalConfig = (
  renewalId: string,
  proposalId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<RenewalConfiguration, Error, RenewalConfigurationRequest>({
    mutationFn: (body) => patchRenewalConfig(renewalId, body),
    onSuccess,
    onError,
    onSettled: async () => {
      await clearProposalCache(proposalId, qc);
    },
  });

const postInvoiceConfig = async (
  proposalId: string,
  body: InvoiceConfigurationRequest = {}
): Promise<InvoiceConfiguration> => {
  const { data } = await apiClient.createInvoiceConfiguration(proposalId, body);

  return data;
};

export const usePostInvoiceConfig = (
  proposalId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<InvoiceConfiguration, Error, InvoiceConfigurationRequest | {}>({
    mutationFn: (body) => postInvoiceConfig(proposalId, body),
    onSuccess,
    onError,
    onSettled: async () => {
      await clearProposalCache(proposalId, qc);
    },
  });

const deleteInvoiceConfig = async (proposalId: string): Promise<void> => {
  await apiClient.deleteInvoiceConfiguration(proposalId);
};

export const useDeleteInvoiceConfig = (
  proposalId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<void, Error, Proposal['id']>({
    mutationFn: () => deleteInvoiceConfig(proposalId),
    onSuccess,
    onError,
    onSettled: async () => {
      await clearProposalCache(proposalId, qc);
    },
  });

const patchInvoiceConfig = async (
  renewalId: string,
  body: InvoiceConfigurationRequest = {}
): Promise<InvoiceConfiguration> => {
  const { data } = await apiClient.patchInvoiceConfiguration(renewalId, body);

  return data;
};

export const usePatchInvoiceConfig = (
  invoiceConfigId: string,
  proposalId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<InvoiceConfiguration, Error, InvoiceConfigurationRequest>({
    mutationFn: (body) => patchInvoiceConfig(invoiceConfigId, body),
    onSuccess,
    onError,
    onSettled: async () => {
      await clearProposalCache(proposalId, qc);
    },
  });

const updateProposalPricing = async (id: string) => {
  const { data } = await apiClient.updateProposalsPricing(id);
  return data[0];
};

export const useUpdateProposalPricing = (
  proposalId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: ['update-proposal-pricing', proposalId],
    mutationFn: () => updateProposalPricing(proposalId),
    onSuccess,
    onError,
    onSettled: async () => {
      await clearProposalCache(proposalId, qc);
    },
  });

const syncProposalToCrm = async (proposalId: string): Promise<void> => {
  await apiClient.syncProposalToCrm(proposalId);
};

export const useSyncProposalToCRM = (
  proposalId: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation<void, Error, Proposal['id']>({
    mutationFn: () => syncProposalToCrm(proposalId),
    onSuccess,
    onError,
    onSettled: async () => {
      await clearProposalCache(proposalId, qc);
    },
  });

const getProposalDeltas = async (proposalId: string) => {
  const { data } = await apiClient.getProposalDeltaSpans(proposalId);
  return data;
};

export const useGetProposalDeltas = (id: string) =>
  useQuery<ItemDeltaSpansResponse[]>({
    queryKey: [getProposalDeltaSpansQueryKey(id)],
    queryFn: () => getProposalDeltas(id),
  });
