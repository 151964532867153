import {
  BillingPeriodsType,
  Contract,
  getDecimalScaleForCurrency,
  hasEntry,
  isMatchingEntryRequest,
  Money,
  Nullable,
  PricebookEntryRequest,
  Product,
  ProductRequest,
  ProductSummary,
} from 'common';

const convertMoneyScale = (
  money: Money | undefined | null,
  extendedDecimals: boolean
): Money | undefined => {
  if (!money) {
    return undefined;
  }

  const scale = getDecimalScaleForCurrency(money.currency!, extendedDecimals);
  const scaleDiff = scale - (money.scale || 0);
  return {
    ...money,
    amount: Math.round((money.amount || 0) * 10 ** scaleDiff),
    scale,
    formattedAmount: undefined,
  };
};

export const convertProductPricesScale = (
  p: Nullable<ProductRequest>,
  extendedDecimals: boolean
): Nullable<ProductRequest> => {
  return {
    ...p,
    entries: (p.entries ?? []).map((entry) => ({
      ...entry,
      price: convertMoneyScale(entry.price, extendedDecimals) ?? entry.price,
    })),
  };
};

const getPricingDescription = (product: Product | ProductSummary): string => {
  return product.pricing || '';
};

export const getProductPricingSummary = (product: Product | ProductSummary) => {
  let typeDescription;
  if (product.recurrence === 'one_time') {
    typeDescription = 'One time';
  } else if (product.recurrence === 'recurring') {
    typeDescription = 'Recurring';
    if (product.consumption === 'multiple') {
      typeDescription += ' quantity';
    }
  } else if (product.recurrence === 'usage') {
    typeDescription = 'Usage';
  }

  return typeDescription + ' ' + getPricingDescription(product);
};

export const getEntriesWithUpdate = (
  entries: PricebookEntryRequest[],
  price: Money,
  billingPeriod?: Contract['billingPeriod'],
  level?: number
): PricebookEntryRequest[] => {
  if (hasEntry(entries, price.currency, billingPeriod, level)) {
    return entries.map((e) =>
      isMatchingEntryRequest(e, price.currency, billingPeriod, level)
        ? { ...e, price }
        : e
    );
  } else {
    return [...entries, { billingPeriod, level, price }];
  }
};

export const isBillingPeriodEnabled = (
  product: Nullable<ProductRequest>,
  billingPeriod: BillingPeriodsType
) =>
  product.billingPeriods &&
  !!product.billingPeriods.find((bp) => bp === billingPeriod);
