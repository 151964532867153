import React, { useEffect } from 'react';
import {
  areAllLevelsEqual,
  BillingPeriodsType,
  Drawer,
  getBillingPeriodLabelAdverb,
  getProductBillingPeriodPrice,
  getZeroMoney,
  Item,
  ItemOverride,
  mergeLinesToOverrides,
  Proposal,
  QuantityOverride,
  ScheduleLine,
  splitLinesFromBillingPeriod,
  splitMergedToLines,
  useTranslation,
} from 'common';

import TimeBasedPricingTieredFooter from './TimeBasedPricingTieredFooter';
import TimeBasedTieredSchedule from './TimeBasedTieredSchedule';
import { getLevelPrice } from './utils';
import styles from './TBDTier.module.scss';

interface Props {
  billingPeriod: BillingPeriodsType;
  currentOverrideSchedule?: ScheduleLine[];
  handleUpdateOverrideSchedule: (
    bp: BillingPeriodsType,
    scheduleDraft?: ItemOverride[],
    quantityScheduleDraft?: QuantityOverride[]
  ) => void;
  isOpen: boolean;
  item: Item;
  onClose: () => void;
  overrideItem?: ItemOverride;
  proposal: Proposal;
  resetSavedScheduleDraft: (
    bp: BillingPeriodsType,
    scheduleDraft?: ItemOverride[]
  ) => void;
}

export function TimeBasedPricingTieredDrawer({
  isOpen,
  item,
  onClose,
  handleUpdateOverrideSchedule,
  resetSavedScheduleDraft,
  billingPeriod,
  currentOverrideSchedule,
  proposal,
  overrideItem,
}: Props): React.ReactNode {
  const { tk } = useTranslation();

  const [draftSchedule, setDraftSchedule] = React.useState<
    ScheduleLine[] | undefined
  >(undefined);
  // if the item has non-schedule overrides, we need to find the price for the current billing period
  const existingOverride = item.overrides?.find(
    (override) => override.billingPeriod === billingPeriod && !override.duration
  );
  const { product } = item;

  const overrideRange = proposal.overrideRanges.find(
    (range) => range.billingPeriod === billingPeriod
  );

  const basePrice = getProductBillingPeriodPrice(
    product,
    proposal.currency,
    billingPeriod
  );

  const resetSchedule = () => {
    onClose();
    setDraftSchedule(undefined);
    resetSavedScheduleDraft(billingPeriod);
  };
  const baseLevelPrices = item.levels?.map((_level, index) =>
    getLevelPrice(
      product.entries,
      proposal.currency,
      billingPeriod,
      index,
      overrideItem?.levels
    )
  );

  const submit = () => {
    if (draftSchedule) {
      const {
        overrides: mergedDraft,
        quantityOverrides: mergedQuantityOverrides,
      } = mergeLinesToOverrides(draftSchedule);
      const baseLevels = baseLevelPrices?.map((baseLevelPrice, index) => ({
        price:
          baseLevelPrice.billingPeriodMoney || getZeroMoney(proposal.currency),
        level: index,
      }));
      if (
        mergedDraft.length > 1 ||
        (mergedDraft[0]?.levels &&
          baseLevels &&
          !areAllLevelsEqual(mergedDraft[0]?.levels, baseLevels)) ||
        mergedQuantityOverrides.length > 1 ||
        mergedQuantityOverrides[0]?.quantity !== item.quantity
      ) {
        handleUpdateOverrideSchedule(
          billingPeriod,
          mergedDraft,
          mergedQuantityOverrides
        );
        onClose();
      } else {
        resetSchedule();
      }
    }
  };

  useEffect(() => {
    const levelPrices = baseLevelPrices?.map((baseLevelPrice, index) => ({
      price: baseLevelPrice.initialPrice || getZeroMoney(proposal.currency),
      level: index,
    }));
    let draft: ScheduleLine[] = currentOverrideSchedule
      ? splitMergedToLines(
          proposal.termQty,
          proposal.termType,
          basePrice,
          currentOverrideSchedule,
          proposal.freeMonths,
          item.quantity,
          levelPrices
        )
      : splitLinesFromBillingPeriod(
          billingPeriod,
          proposal.termQty,
          proposal.termType,
          basePrice,
          existingOverride
            ? existingOverride.listPrice || basePrice
            : basePrice,
          existingOverride ? existingOverride.price || basePrice : basePrice,
          proposal.freeMonths,
          item.quantity,
          levelPrices
        );

    if (overrideRange && overrideRange.startIndex && overrideRange.endIndex) {
      draft = draft.slice(overrideRange.startIndex, overrideRange.endIndex + 1);
    }
    setDraftSchedule(draft);
  }, [
    currentOverrideSchedule,
    billingPeriod,
    proposal.startDate,
    overrideItem,
  ]);

  return (
    <Drawer
      onClose={onClose}
      isOpen={isOpen}
      className={styles.timeBasedPricingDrawer}
      header={
        tk('Customize pricing') +
        ` ${getBillingPeriodLabelAdverb(billingPeriod)}`
      }
      width={800}
      footer={
        <TimeBasedPricingTieredFooter
          draftSchedule={draftSchedule}
          proposal={proposal}
          item={item}
          onClose={onClose}
          submit={submit}
          billingPeriod={billingPeriod}
          resetSchedule={resetSchedule}
          overrideItem={overrideItem}
        />
      }
    >
      <div className={styles.drawerContent}>
        <h3 className={styles.productName}>{item.name}</h3>
        <div className={styles.scheduleSection}>
          {draftSchedule &&
            item.product.levels &&
            item.product.levels.length > 0 && (
              <TimeBasedTieredSchedule
                item={item}
                scheduleRows={draftSchedule}
                quantity={item.quantity}
                setDraftSchedule={setDraftSchedule}
                currency={proposal.currency}
                startIndex={overrideRange?.startIndex}
                tiers={item.product.levels}
                entries={item.product.entries}
                billingPeriod={billingPeriod}
                pricing={item.product.pricing}
              />
            )}
        </div>
      </div>
    </Drawer>
  );
}
