import React from 'react';
import { pushIf, useFlags } from 'common';
import ConnectorSettings, {
  ConnectorTab,
} from '../Connectors/ConnectorSettings';
import { accountContactsConfig, QUICKBOOKS_INFO } from './constants';
import QuickbooksSVG from '../../../assets/integration-logos/QuickbooksSVG';
import { ConnectorTabs } from '../../../core-utils/enums/ConnectorTabs';
import { useGetAllAccounts } from '../../../services/quickbooks';
import { useVisibleProducts } from '../../../services/api/products/products';
import { ConnectorConfiguration } from '../Connectors/ConnectorConstants/constants';
import { useIsSuperAdmin } from '../../../core-utils/helperFunctions/userServiceHelper';
import { ENVIRONMENT_CONFIG } from '../../../../config/hosts';

const QuickbooksSettings = () => {
  const { data: accounts } = useGetAllAccounts();
  const { data: products } = useVisibleProducts();
  const { productBundles, quickbooksReconciliationTab } = useFlags();
  const isSuperOrDevEnv = useIsSuperAdmin() || !ENVIRONMENT_CONFIG.isProduction;
  const isSuperAdmin = useIsSuperAdmin();

  const tabs: ConnectorTab[] = [
    {
      key: ConnectorTabs.configuration,
      label: 'Configuration',
      systemOnly: false,
    },
    {
      key: ConnectorTabs.accountContacts,
      label: 'Data mapping',
      systemOnly: false,
    },
    { key: ConnectorTabs.status, label: 'Status', systemOnly: true },
    {
      key: ConnectorTabs.connection,
      label: 'Connection',
      systemOnly: false,
    },

    { key: ConnectorTabs.events, label: 'Sync logs', systemOnly: false },
    ...pushIf(isSuperAdmin || quickbooksReconciliationTab, {
      key: ConnectorTabs.reconciliation,
      label: 'Reconciliation',
      systemOnly: false,
    }),
  ];

  const configuration: ConnectorConfiguration = {
    name: 'accountingConfig',
    lines: [
      {
        fieldName: 'productAccountCode',
        type: 'select',
        inputProps: {
          options: (accounts ?? [])
            .filter((a) => {
              return a.AccountType === 'Income';
            })
            .map((record) => ({
              name: record.Name,
              value: record.Id,
            })),
        },
      },
      {
        fieldName: 'depositAccountCode',
        type: 'select',
        inputProps: {
          options: (accounts ?? [])
            .filter((a) => {
              return (
                a.AccountType === 'Other Current Asset' ||
                a.AccountType === 'Bank'
              );
            })
            .map((record) => ({
              name: record.Name,
              value: record.Id,
            })),
        },
      },
      {
        fieldName: 'taxAccountCode',
        type: 'select',
        inputProps: {
          options: (accounts ?? [])
            .filter((a) => {
              return a.AccountType === 'Other Current Liability';
            })
            .map((record) => ({
              name: record.Name,
              value: record.Id,
            })),
        },
      },
      {
        fieldName: 'defaultProductId',
        type: 'select',
        include: isSuperOrDevEnv,
        inputProps: {
          options: (products ?? []).map((record) => ({
            name: record.name ?? record.internalName,
            value: record.id,
          })),
        },
      },
      {
        fieldName: 'lineMappingClass',
        type: 'string',
        include: isSuperOrDevEnv,
      },
      {
        fieldName: 'ignoreCountry',
        type: 'string',
        include: isSuperOrDevEnv,
      },
      {
        fieldName: 'autoSendInvoice',
        type: 'toggle',
      },
      {
        fieldName: 'invoiceNote',
        type: 'toggle',
      },
      {
        fieldName: 'syncToEstimate',
        type: 'toggle',
        include: isSuperOrDevEnv,
      },
      {
        fieldName: 'sendEmailWithoutPaymentMethod',
        type: 'toggle',
      },
      {
        fieldName: 'splitBundleItems',
        type: 'toggle',
        include: productBundles,
      },
    ],
  };

  return (
    <ConnectorSettings
      accountContactsConfig={accountContactsConfig}
      configuration={configuration}
      connectorInfo={QUICKBOOKS_INFO}
      logo={QuickbooksSVG}
      tabs={tabs}
    />
  );
};

export default QuickbooksSettings;
