import React from 'react';
import {
  Icon,
  PopOutMenu,
  PopOutMenuOption,
  Transaction,
  Transfer,
} from 'common';
import { useIsSuperAdmin } from '../../core-utils/helperFunctions/userServiceHelper';
import { getDetailLink } from 'app/src/services/routes/routeUtils';
import TriggerDueAdmin from './TriggerDueAdmin';
import { TransferReferenceType } from '../../services/transfer';

interface Props {
  children?: React.ReactNode;
  onClickEvent: () => void;
  onClickViewActivity: () => void;
  referenceId?: string;
  referenceType: string;
  externalInvoiced?: boolean;
  transfer?: Transfer | Transaction;
  borderless?: boolean;
}

const TransferOptionsButton: React.FC<Props> = ({
  referenceType,
  referenceId,
  externalInvoiced,
  transfer,
  children,
  onClickViewActivity,
  onClickEvent,
  borderless,
}) => {
  const isSuperAdmin = useIsSuperAdmin();
  const detailLink = getDetailLink(referenceType, referenceId || '');

  const adminOptions = () => {
    if (isSuperAdmin && !externalInvoiced) {
      return (
        <>
          {!transfer && (
            <TriggerDueAdmin
              id={referenceId!}
              detailLink={detailLink!}
              transferReferenceType={referenceType as TransferReferenceType}
            />
          )}
          {transfer && (
            <PopOutMenuOption
              icon={Icon.Edit}
              isSuper
              onClick={onClickEvent}
              title="Update status"
            />
          )}
        </>
      );
    }
    return null;
  };

  return (
    <PopOutMenu borderless={borderless}>
      {children}
      <PopOutMenuOption
        icon={Icon.Activity}
        onClick={onClickViewActivity}
        title="Payment activity"
      />
      {adminOptions()}
    </PopOutMenu>
  );
};

export default TransferOptionsButton;
