import React, { useEffect, useRef, useState } from 'react';
import CustomerContacts from './CustomerContacts';
import { useQueryClient } from '@tanstack/react-query';
import { useContacts } from '../../../services/contacts';
import {
  useCountries,
  useCreateCustomer,
  useDeleteCustomer,
  useRegion,
  useUpdateCustomerAPI,
} from '../../../services/customer';
import {
  Address,
  AddressForm,
  areAddressesTheSame,
  Button,
  ButtonBar,
  ConfirmDeleteModal,
  Contact,
  CurrencySearchSelect,
  Customer,
  CustomerRequest,
  FormField,
  getCountry,
  getErrorMessage,
  Icon,
  Input,
  InputRef,
  isEmpty,
  pushIf,
  Select,
  Tab,
  Tabs,
  TaxCountryRulesConfig,
  ToggleSwitch,
  useFlags,
  useFormValidation,
  useToast,
  useTranslation,
} from 'common';
import CustomerBillingDetails from './CustomerBillingDetails';
import ExternalEntityLink from '../../../components/ExternalEntityLink';
import { useAuth } from '../../../Auth';
import { useTaxExemptCodes } from '../../../services/taxes';
import {
  useAllCurrencies,
  useOrgCurrencies,
} from '../../../services/api/currency/currency';
import { useOrgDefaults } from '../../../services/orgDefaults';
import styles from './ContactForm.module.scss';

interface SubscriptionAddressValid {
  isBillingAddressValid?: boolean;
  isShippingAddressValid?: boolean;
  isBillingContactValid?: boolean;
  hasCustomerName?: boolean;
  subscriptionContacts?: Contact[];
}
interface Props {
  customerDraft: Partial<Customer>;
  isBeingViewed: boolean;
  isInProposalEditor?: boolean;
  isInline?: boolean;
  onCancel?: () => void;
  onClickAddContact: () => void;
  onClickEditContact: (contact: Contact) => void;
  onDeleteSuccess: () => void;
  onSaveSuccess: (customer: Customer) => void;
  setCustomerDraft: React.Dispatch<React.SetStateAction<Partial<Customer>>>;
  isSubscriptionAddressValid?: SubscriptionAddressValid;
  subscrptionFlowShippingErros?: (
    fieldName: number | string | symbol
  ) => string | undefined;
  subscriptionFlowBillingErrors?: (
    fieldName: number | string | symbol
  ) => string | undefined;
}

const EditCustomer: React.FC<Props> = ({
  customerDraft,
  isBeingViewed,
  isInProposalEditor,
  isInline,
  onClickAddContact,
  onClickEditContact,
  onDeleteSuccess,
  onSaveSuccess,
  onCancel,
  setCustomerDraft,
  isSubscriptionAddressValid,
  subscrptionFlowShippingErros,
  subscriptionFlowBillingErrors,
}) => {
  const { tk } = useTranslation();
  const { taxyTurby, enableTaxId } = useFlags();
  const profile = useAuth();
  const { data: currencies } = useAllCurrencies();
  const { data: orgCurrencies } = useOrgCurrencies();
  const { data: exemptCodes } = useTaxExemptCodes({ enabled: taxyTurby });
  const { data: countries = [] } = useCountries();
  const { data: regions = [] } = useRegion(
    getCountry(customerDraft.shippingAddress, countries)
  );

  const showToast = useToast();
  const queryClient = useQueryClient();
  const inputRef = useRef<InputRef>(null);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  const { isFormValid, getErrorToShow, showAllErrors } =
    useFormValidation<Customer>(
      [
        {
          fieldName: 'name',
          isRequired: true,
          humanReadableName: 'Name',
        },
        {
          fieldName: 'partner',
          isRequired: false,
          validator: (isPartner, customer) => {
            if (
              isPartner &&
              [customer.billingAddress, customer.billingContact].some(isEmpty)
            ) {
              return 'Bill to address and contact are required to set as partner';
            }
            return null;
          },
        },
      ],
      customerDraft
    );

  const {
    isFormValid: areAddressFieldsValid,
    showAllErrors: showCountryAndStateErrors,
    getErrorToShow: errorForCountryAndState,
  } = useFormValidation(
    [
      {
        fieldName: 'streetAddress',
        isRequired: true,
        humanReadableName: 'Street',
      },
      {
        fieldName: 'country',
        isRequired: true,
        humanReadableName: 'Country',
      },
    ],
    customerDraft.shippingAddress ?? {}
  );

  useEffect(() => {
    // timeout to not mess up the sliding effect of drawer
    setTimeout(() => {
      if (isBeingViewed && inputRef.current) {
        inputRef.current.select();
      }
    }, 100);
  }, [isBeingViewed]);

  useEffect(() => {
    if (!customerDraft.defaultCurrency) {
      customerDraft.defaultCurrency = profile.organizationDefaultCurrency!;
      setCustomerDraft(customerDraft);
    }
  }, [customerDraft]);

  const handleSaveSuccess = (data: Customer) => {
    if (!isInline) {
      showToast.info(tk('Customer saved'));
    }
    onSaveSuccess(data);
  };

  const handleSaveError = () => showToast.error(tk('Could not save customer'));

  const { mutate: updateCustomer, isPending: isUpdating } =
    useUpdateCustomerAPI(handleSaveSuccess, handleSaveError, queryClient);

  const { mutate: createCustomer, isPending: isCreating } = useCreateCustomer(
    handleSaveSuccess,
    handleSaveError,
    queryClient
  );

  const handleDeleteSuccess = () => {
    showToast.info(tk('Deleted customer'));
    onDeleteSuccess();
    setIsDeleteConfirmationOpen(false);
  };

  const handleDeleteError = (error: unknown) => {
    const errorMessage = getErrorMessage(error);
    if (errorMessage) {
      showToast.error(tk(errorMessage));
    } else {
      showToast.error(tk('Could not delete customer'));
    }
    setIsDeleteConfirmationOpen(false);
  };

  const { mutate: deleteCustomer, isPending: isDeleting } = useDeleteCustomer(
    handleDeleteSuccess,
    handleDeleteError,
    queryClient
  );

  const { data: customerContacts } = useContacts(customerDraft.id);

  const handleNameChange = (newName: string): void => {
    setCustomerDraft((prev) => ({
      ...prev,
      name: newName,
    }));
  };

  const handleCurrencyChange = (currency: string): void => {
    setCustomerDraft((prev) => ({
      ...prev,
      defaultCurrency: currency,
    }));
  };

  const handleUseCodeChange = (taxUseCode: Customer['taxUseCode']): void => {
    setCustomerDraft((prev) => ({
      ...prev,
      taxUseCode,
    }));
  };

  const useCodeOptions =
    exemptCodes?.map((c) => {
      const label = tk(`exemptCodes.${c}`);
      return {
        value: c,
        name: `${c} - ${label}`,
      };
    }) || [];

  const isSavedCustomer = (c: Partial<Customer>): c is Customer =>
    isFormValid && !!c.id;

  const hasShippingAddressBeenFilled: boolean =
    !!customerDraft.shippingAddress &&
    Object.values(customerDraft.shippingAddress).some((value) => !!value);

  const isValidRegion = (address: Address) => {
    const currentRegion = address.region;
    if (!currentRegion) return true;
    return regions.some((region) => region.iso === currentRegion);
  };

  // Validates the shipping and billing addresses
  function validateAddresses() {
    if (!hasShippingAddressBeenFilled || areAddressFieldsValid) {
      return true;
    } else {
      showAllErrors();
      showCountryAndStateErrors();
      return false;
    }
  }

  // Updates the customer draft based on the region validation
  function updateCustomerDraftBasedOnRegion() {
    const updatedCustomerDraft = { ...customerDraft };

    if (
      customerDraft.shippingAddress &&
      !isValidRegion(customerDraft.shippingAddress)
    ) {
      delete updatedCustomerDraft.shippingAddress?.region;
    }
    if (
      customerDraft.billingAddress &&
      !isValidRegion(customerDraft.billingAddress)
    ) {
      delete updatedCustomerDraft.billingAddress?.region;
    }

    return updatedCustomerDraft;
  }

  const submitIfValid = () => {
    if (!isFormValid) {
      showAllErrors();
      return;
    }

    if (!validateAddresses()) {
      return;
    }

    const updatedCustomerDraft = updateCustomerDraftBasedOnRegion();

    if (isSavedCustomer(updatedCustomerDraft) && updatedCustomerDraft.id) {
      updateCustomer(updatedCustomerDraft);
    } else {
      const createCustomerBody = { ...updatedCustomerDraft };
      if (updatedCustomerDraft.shippingAddress?.streetAddress === '') {
        delete createCustomerBody.shippingAddress;
      }
      createCustomer(createCustomerBody as CustomerRequest);
    }
  };

  const { data: orgConfig } = useOrgDefaults();

  const isCollectTaxIdEnabled = !!(
    orgConfig?.get('taxCountryRulesConfig')?.configValue as
      | TaxCountryRulesConfig
      | undefined
  )?.collectTaxIdEnabled;

  const tabs: Tab[] = [
    {
      key: 'ship-to',
      label: (
        <div className={styles.iconContainer}>
          {tk('Ship to')}
          {isSubscriptionAddressValid &&
            !isSubscriptionAddressValid.isShippingAddressValid && (
              <Icon.Alert className={styles.icon} width={27} />
            )}
        </div>
      ),
      dataTestId: 'ship-to-tab',
      component: (
        <AddressForm
          addressData={customerDraft.shippingAddress}
          onChange={(address) => {
            setCustomerDraft((prev) => ({
              ...prev,
              shippingAddress: { ...address },
            }));
          }}
          onPlaceSelected={(address) => {
            setCustomerDraft((prev) => ({
              ...prev,
              billingAddress: { ...address },
            }));
          }}
          {...(hasShippingAddressBeenFilled
            ? { getErrorToShow: errorForCountryAndState }
            : undefined)}
          countries={countries}
          regions={regions}
          {...(subscrptionFlowShippingErros
            ? { getErrorToShow: subscrptionFlowShippingErros }
            : undefined)}
        />
      ),
    },
    ...pushIf<Tab>(!!customerDraft.id, {
      key: 'bill-to',
      label: (
        <div className={styles.iconContainer}>
          {tk('Bill to')}
          {isSubscriptionAddressValid &&
            (!isSubscriptionAddressValid.isBillingAddressValid ||
              !isSubscriptionAddressValid.isBillingContactValid) && (
              <Icon.Alert className={styles.icon} width={27} />
            )}
        </div>
      ),
      dataTestId: 'bill-to-tab',
      component: (
        <CustomerBillingDetails
          address={customerDraft.billingAddress}
          billingContact={customerDraft.billingContact}
          contacts={customerContacts || []}
          hasShippingAddressBeenSet={hasShippingAddressBeenFilled}
          isBillingAddressSameAsShipping={areAddressesTheSame(
            customerDraft.billingAddress,
            customerDraft.shippingAddress
          )}
          isSubscriptionBillingContactValid={
            isSubscriptionAddressValid?.isBillingContactValid
          }
          onAddressChange={(address) =>
            setCustomerDraft((prev) => ({ ...prev, billingAddress: address }))
          }
          onChangeBillingContact={(contact) => {
            setCustomerDraft((prev) => ({
              ...prev,
              billingContact: contact,
            }));
          }}
          onToggleBillingSameAsShipping={(isTheSame: boolean) => {
            if (isTheSame) {
              setCustomerDraft((prev) => ({
                ...prev,
                billingAddress: customerDraft.shippingAddress,
              }));
            } else {
              setCustomerDraft((prev) => ({
                ...prev,
                billingAddress: null!,
              }));
            }
          }}
          subscriptionFlowBillingErrors={subscriptionFlowBillingErrors}
        />
      ),
    }),
    ...pushIf<Tab>(!!customerDraft.id, {
      key: 'contacts',
      label: (
        <div className={styles.iconContainer}>
          Contacts
          {isSubscriptionAddressValid &&
            !isSubscriptionAddressValid.subscriptionContacts?.length && (
              <Icon.Alert className={styles.icon} width={27} />
            )}
        </div>
      ),
      dataTestId: 'customer-contacts-tab',
      component: (
        <CustomerContacts
          contacts={customerContacts || []}
          onClickAddContact={onClickAddContact}
          onClickEditContact={onClickEditContact}
        />
      ),
    }),
  ];

  const areSubscriptionAddressValid =
    isSubscriptionAddressValid?.isBillingAddressValid &&
    isSubscriptionAddressValid.isShippingAddressValid &&
    isSubscriptionAddressValid.isBillingContactValid &&
    isSubscriptionAddressValid.hasCustomerName;

  return (
    <>
      <div className="relative">
        {isSavedCustomer(customerDraft) && (
          <div className="mb-2 flex justify-end">
            <ExternalEntityLink entity={customerDraft} showId />
          </div>
        )}
        <FormField
          label={tk('Legal company name')}
          errorToShow={getErrorToShow('name')}
        >
          <Input
            id="customer_name_input"
            onChange={handleNameChange}
            value={customerDraft.name}
            ref={inputRef}
            onEnter={submitIfValid}
          />
        </FormField>

        <FormField label={tk('Default customer currency')}>
          <CurrencySearchSelect
            onSelectCurrency={handleCurrencyChange}
            dataTestId="currency-select"
            currencies={currencies}
            orgCurrencies={orgCurrencies}
            defaultSelectedCurrency={customerDraft.defaultCurrency || 'USD'}
          />
        </FormField>

        {((enableTaxId && isCollectTaxIdEnabled) || customerDraft.tin) && (
          <FormField
            label={tk('Tax ID')}
            infoTooltip={tk(
              'Enter the Tax Registration ID that you would like displayed on your proposals and invoices'
            )}
          >
            <Input
              id="tin"
              value={customerDraft.tin}
              onChange={(tin: string) => {
                setCustomerDraft((prev) => ({
                  ...prev,
                  tin,
                }));
              }}
            />
          </FormField>
        )}

        <div className="flex flex-row gap-4 pb-2">
          {taxyTurby && (
            <ToggleSwitch
              label={tk('Tax exempt')}
              alignLabel="right"
              name="taxExempt"
              value={customerDraft.taxExempt}
              onChange={(checked) => {
                setCustomerDraft((prev) => ({
                  ...prev,
                  taxExempt: checked,
                }));
              }}
            />
          )}
          {!!customerDraft.id && (
            <ToggleSwitch
              label={tk('Partner')}
              alignLabel="right"
              name="partner"
              value={customerDraft.partner}
              onChange={(checked) => {
                setCustomerDraft((prev) => ({
                  ...prev,
                  partner: checked,
                }));
              }}
            />
          )}
        </div>

        <FormField errorToShow={getErrorToShow('partner')} />

        {customerDraft.taxExempt && (
          <>
            {!enableTaxId && (
              <FormField label={tk('Tax ID')}>
                <Input
                  id="tin"
                  value={customerDraft.tin}
                  onChange={(tin) => {
                    setCustomerDraft((prev) => ({
                      ...prev,
                      tin,
                    }));
                  }}
                />
              </FormField>
            )}
            <FormField label={tk('Tax exempt use code')}>
              <Select<Customer['taxUseCode']>
                className="focus:relative rounded-l-none w-2/3"
                dataTestId="input-use-code"
                onChange={handleUseCodeChange}
                value={customerDraft.taxUseCode}
                options={useCodeOptions}
              />
            </FormField>
          </>
        )}

        <Tabs tabs={tabs} />

        <div className="border border-gray-lines my-4" />

        <ButtonBar
          left={
            <>
              {!isInProposalEditor && (
                <Button
                  isDangerous={!!customerDraft.id}
                  type={customerDraft.id ? 'link' : 'secondary'}
                  dataTestId="customer_delete_button"
                  onClick={() => {
                    customerDraft.id
                      ? setIsDeleteConfirmationOpen(true)
                      : onDeleteSuccess();
                  }}
                  label={customerDraft.id ? tk('Delete') : tk('Cancel')}
                />
              )}

              {!isInline && (
                <Button
                  isDisabled={isCreating || isUpdating || isDeleting}
                  dataTestId="customer_save_button"
                  label={customerDraft.id ? tk('Save') : tk('Create')}
                  onClick={submitIfValid}
                />
              )}
            </>
          }
        />

        <ConfirmDeleteModal
          isOpen={isDeleteConfirmationOpen}
          onClose={() => setIsDeleteConfirmationOpen(false)}
          onConfirm={() => deleteCustomer(customerDraft.id!)}
          typeName="customer"
        />
      </div>

      {isInline && (
        <ButtonBar>
          {onCancel && (
            <Button
              type="secondary"
              label="Cancel"
              onClick={onCancel}
              dataTestId="confirm-cancel-button"
            />
          )}
          <Button
            isDisabled={
              isCreating ||
              isUpdating ||
              isDeleting ||
              !areSubscriptionAddressValid
            }
            dataTestId="customer_save_button"
            label={customerDraft.id ? tk('Save') : tk('Create')}
            onClick={submitIfValid}
          />
        </ButtonBar>
      )}
    </>
  );
};

export default EditCustomer;
