import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Icon, isStringContent, Loading, log } from 'common';
import CacheflowLogo from '../../assets/CacheflowLogo';
import LoginForm from './LoginForm';
import { useAuthState } from '../../Auth';
import { PROPOSALS, SYSTEM_DASHBOARD_ROUTE } from '../../core-utils/routes';
import { isNonEntryPath } from '../../services/routes/routeUtils';

import {
  canAccessUserRoutes,
  useCanAccessSystemAdminRoutes,
} from '../../core-utils/helperFunctions/userServiceHelper';
import { useSsoRedirect } from '../../services/api/profile/user';
import LoginLayout from './LoginLayout';
import styles from './LoginPage.module.scss';

import SmallPrint from './SmallPrint/SmallPrint';

interface Props {
  isSystem?: boolean;
}

const LoginPage: React.FC<Props> = ({ isSystem = false }) => {
  const { auth, setAuth } = useAuthState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const errorMessage = queryParams.get('errorCode') ?? undefined;
  const ssoSource = queryParams.get('sso_source') ?? undefined;
  const ssoError = queryParams.get('kind') ?? undefined;

  useEffect(() => {
    // if we have a token reset the context
    const queryToken = queryParams.get('token');

    if (queryToken) {
      const orgId = queryParams.get('orgId');

      let context = auth;

      if (isSystem && !orgId) {
        context = {
          ...context,
          isSystemAdmin: true,
          isAuthenticated: false,
          systemToken: queryToken,
        };
        log.setLevel('trace');
      } else if (!isSystem || !!orgId) {
        context = {
          ...context,
          isAuthenticated: true,
          token: queryToken,
        };
      }
      setAuth(context);
    }
    // use token as a guard
  }, [queryParams]);

  const defaultLandingPage: string =
    isSystem && !canAccessUserRoutes() ? SYSTEM_DASHBOARD_ROUTE : PROPOSALS;

  const previousPath: string | undefined = [
    queryParams.get('prevPath'),
    auth.prevPath,
  ]
    .filter(isStringContent)
    .find((path) => isNonEntryPath(path));

  const usePrevPath: boolean = Boolean(previousPath && !isSystem);
  const searchSuffix: string | undefined =
    queryParams.get('searchSuffix') || auth.searchSuffix;
  const landing: string =
    previousPath && usePrevPath ? previousPath : defaultLandingPage;
  const shouldRedirect: boolean = isSystem
    ? useCanAccessSystemAdminRoutes()
    : canAccessUserRoutes();

  if (ssoError) {
    log.warn('Login issue occurred, ssoError', ssoError);
  }

  if (shouldRedirect && ssoSource && !ssoError) {
    const { data: ssoRedirect, isLoading: isSsoRedirectLoading } =
      useSsoRedirect(
        !!ssoSource,
        ssoSource || '',
        queryParams.get('return_to') || ''
      );

    if (isSsoRedirectLoading) {
      return <Loading />;
    }

    if (ssoRedirect) {
      window.location.href = ssoRedirect.redirectUrl;
      return null;
    }
  }

  if (shouldRedirect && (!ssoSource || ssoError)) {
    log.info('using query params ', searchSuffix);
    log.info('using path ', landing);
    return (
      <Navigate replace to={landing + (searchSuffix ? searchSuffix : '')} />
    );
  }

  return (
    <LoginLayout
      bigIcon={isSystem && <Icon.Code width={104} height={104} />}
      isSystem={isSystem}
    >
      <div className={styles.logo}>
        <CacheflowLogo />
      </div>
      <LoginForm
        isSystem={isSystem}
        errorMessage={errorMessage}
        prevPath={landing}
        searchSuffix={
          searchSuffix ? encodeURIComponent(searchSuffix) : undefined
        }
      />

      {!isSystem && <SmallPrint />}
    </LoginLayout>
  );
};

export default LoginPage;
