import React from 'react';
import {
  Icon,
  Item,
  PopOutMenu,
  PopOutMenuOption,
  Proposal,
  ProposalItemRequest,
  useTranslation,
} from 'common';
import CustomizeDateButton from './CustomizeDateButton';

interface Props {
  dataTestId?: string;
  isDisabled: boolean;
  isRemovable: boolean;
  item: Item;
  onClickCustomize: () => void;
  onClickEditDiscount: () => void;
  onClickEditTimeline?: () => void;
  onClickRemoveDiscount: () => void;
  onClickRemoveItem: () => void;
  onUpdateItem: (item: ProposalItemRequest) => void;
  proposal: Proposal;
}

const ProductOptionsButton: React.FC<Props> = ({
  dataTestId,
  isDisabled,
  isRemovable,
  item,
  onClickCustomize,
  onClickEditDiscount,
  onClickEditTimeline,
  onClickRemoveDiscount,
  onClickRemoveItem,
  onUpdateItem,
  proposal,
}) => {
  const { tk } = useTranslation();
  const isDiscountable = Boolean(item.product.discountable);
  const isCustomizable = Boolean(
    item.product.nameCustomizable || item.product.descriptionCustomizable
  );
  const hasDiscounts = Boolean(
    item.overrides?.some((override) => override.price ?? override.levels) ||
      item.quantityOverrides?.length
  );

  return (
    <PopOutMenu dataTestId={dataTestId} isDisabled={isDisabled}>
      <div data-testid={`product-options-${item.name}`}>
        {isRemovable && (
          <PopOutMenuOption
            dataTestId={`remove-proposal-item-${item.name}`}
            icon={Icon.Close}
            onClick={onClickRemoveItem}
            title={tk('Remove item')}
          />
        )}

        {isCustomizable && (
          <PopOutMenuOption
            dataTestId={`custom-product-${item.name}`}
            icon={Icon.Edit}
            onClick={onClickCustomize}
            title={tk('Customize product')}
          />
        )}

        {onClickEditTimeline && (
          <PopOutMenuOption
            dataTestId={`edit-timeline-${item.name}`}
            icon={Icon.Discount}
            onClick={onClickEditTimeline}
            title={tk('Edit timeline')}
          />
        )}

        <CustomizeDateButton
          item={item}
          onUpdateItem={onUpdateItem}
          proposal={proposal}
        />

        {isDiscountable && (
          <PopOutMenuOption
            dataTestId={`add-discount-${item.name}`}
            icon={Icon.Discount}
            onClick={onClickEditDiscount}
            title={tk('Customize price')}
          />
        )}

        {hasDiscounts && isDiscountable && (
          <PopOutMenuOption
            dataTestId={`remove-discount-${item.name}`}
            icon={Icon.DiscountRemove}
            onClick={onClickRemoveDiscount}
            title={tk('Remove customized price')}
          />
        )}
      </div>
    </PopOutMenu>
  );
};

export default ProductOptionsButton;
