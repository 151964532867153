import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  Button,
  Card,
  CardHeader,
  Loading,
  OrgConfig,
  ProposalConfig as ProposalConfigValue,
  ToggleSwitch,
  useToast,
  useTranslation,
} from 'common';

import {
  useFindOrgDefault,
  useSetOrgConfigDefault,
} from '../../../services/orgDefaults';

const PROPOSAL_CONFIG_KEY: OrgConfig['configKey'] = 'proposalConfig';

const ChangeProposal: React.FC = () => {
  const [draft, setDraft] = useState<ProposalConfigValue>({});
  const showToast = useToast();
  const queryClient = useQueryClient();
  const { tk } = useTranslation();
  const { data: proposalConfig, isLoading: isProposalConfigLoading } =
    useFindOrgDefault(PROPOSAL_CONFIG_KEY);

  const { mutate, isPending: isSaving } = useSetOrgConfigDefault(
    PROPOSAL_CONFIG_KEY,
    () => showToast.info(tk('Setting saved.')),
    () => showToast.error(tk('Failed to save setting. Please try again.')),
    queryClient
  );

  useEffect(() => {
    if (proposalConfig) {
      const configValueIn: ProposalConfigValue | undefined =
        proposalConfig.configValue;

      if (configValueIn) {
        setDraft(configValueIn);
      }
    }
  }, [proposalConfig]);

  const saveConfig = () => {
    mutate({
      configValue: {
        ...draft,
        configType: PROPOSAL_CONFIG_KEY,
      },
    });
  };

  if (isProposalConfigLoading || !proposalConfig) {
    return <Loading />;
  }

  return (
    <Card>
      <CardHeader name="Change Proposal">
        <Button isLoading={isSaving} label="Save" onClick={saveConfig} />
      </CardHeader>
      <ToggleSwitch
        dataTestId="changes-only-view-default"
        infoText="Displays only change values on signing and PDF download for change proposals."
        label={tk('Set changes only view as default')}
        name="changesOnlyViewDefault"
        onChange={(newValue) =>
          setDraft((prev) => ({
            ...prev,
            changesOnlyViewAsDefault: newValue,
          }))
        }
        value={draft.changesOnlyViewAsDefault}
      />
    </Card>
  );
};

export default ChangeProposal;
