import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getOrgDomain } from 'common';
import { authAxios } from '../../httpClients/auth';

export interface Provider {
  id?: string;
  path?: string;
}

export const useSsoProviders = (): UseQueryResult<Provider[]> =>
  useQuery({
    queryKey: ['ssoproviders'],
    queryFn: async () => {
      const config = { headers: {} } as AxiosRequestConfig;
      const subDomain = getOrgDomain();
      if (subDomain && config.headers) {
        config.headers.CF_ORG_SUBDOMAIN = subDomain;
      }
      const { data } = await authAxios.get('/sso/providers', config);
      return data as Provider[];
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retryDelay: (attempt) => attempt * 1000,
    retry: 3,
    networkMode: 'offlineFirst',
  });
