import {
  Icon,
  Item,
  ItemSchedule,
  useRowItem,
  useRowItemSchedule,
  PopOutMenuOption,
} from 'common';
import React from 'react';

export const CustomizePriceAction = ({
  onShowPriceModalClick,
}: {
  onShowPriceModalClick: (selectedSchedule: ItemSchedule, item: Item) => void;
}) => {
  const schedule = useRowItemSchedule();
  const item = useRowItem();

  return (
    <PopOutMenuOption
      icon={Icon.CurrencyUsd}
      onClick={() => onShowPriceModalClick(schedule, item)}
      title="Customize price"
    />
  );
};
