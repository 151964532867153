import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { InvoiceDetail, UpdateInvoiceRequest } from 'common';
import { apiClient } from 'app/src/services/httpClients/app';

export const ALL_INVOICES_KEY = 'invoices';

export interface InvoiceRequest {
  updateRequest: UpdateInvoiceRequest;
  id: string;
}

const getInvoiceKey = (id: string) => {
  return ALL_INVOICES_KEY + '/' + id;
};

export const useInvoice = (id: string): UseQueryResult<InvoiceDetail> =>
  useQuery({
    queryKey: [ALL_INVOICES_KEY + '/' + id],
    queryFn: async () => {
      const { data } = await apiClient.getInvoice(id);
      return data;
    },
  });

// archive functions

const archiveInvoices = async (ids: string[]) => {
  const { data } = await apiClient.archiveInvoices({ ids });
  return data;
};

const restoreInvoices = async (ids: string[]) => {
  const { data } = await apiClient.restoreInvoices({ ids });
  return data;
};

const updateInvoiceStatus = async (request: InvoiceRequest) => {
  const { data } = await apiClient.updateInvoice(
    request.id,
    request.updateRequest
  );
  return data;
};

export const useArchiveInvoice = (
  id: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getInvoiceKey(id)],
    mutationFn: () => archiveInvoices([id]),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [ALL_INVOICES_KEY],
      });
    },
  });

export const useRestoreInvoice = (
  id: string,
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) =>
  useMutation({
    mutationKey: [getInvoiceKey(id)],
    mutationFn: () => restoreInvoices([id]),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [ALL_INVOICES_KEY],
      });
    },
  });

export const useUpdateInvoiceStatus = (
  qc: QueryClient,
  onSuccess?: () => void,
  onError?: (error: unknown) => void
) =>
  useMutation({
    mutationKey: ['updateInvoiceStatus'],
    mutationFn: (updateInvoiceInfo: InvoiceRequest) =>
      updateInvoiceStatus(updateInvoiceInfo),
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({
        queryKey: [ALL_INVOICES_KEY],
      });
    },
  });
