import {
  QueryClient,
  useMutation,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { apiClient } from './httpClients/app';
import { OrgConfig } from 'common';
import { useEffect, useState } from 'react';

export const ORG_DEFAULTS_PATH = 'api/latest/settings/org/defaults';

/**
 * return the org default for a given config key
 *  undefined if not loaded yet
 *  null if not found
 */
export const useFindOrgDefault = (configKey: OrgConfig['configKey']) => {
  const { data, isLoading, isError, error } = useOrgDefaults();
  const [orgDefault, setOrgDefault] = useState<OrgConfig | null>();
  useEffect(() => {
    if (data) {
      setOrgDefault(data.get(configKey) || null);
    }
  }, [data]);

  return {
    data: orgDefault,
    isLoading,
    isError,
    error,
  };
};

/**
 * Get and cache all org defaults
 */
export const useOrgDefaults = (): UseQueryResult<
  Map<OrgConfig['configKey'], OrgConfig>
> =>
  useQuery<Map<OrgConfig['configKey'], OrgConfig>>({
    queryKey: [ORG_DEFAULTS_PATH],
    queryFn: async () => {
      const { data } = await apiClient.listOrgConfigs();
      return new Map(data.map((config) => [config.configKey, config]));
    },
  });

export async function setOrgConfigDefault(
  configKey: OrgConfig['configKey'],
  orgConfig: OrgConfig,
  qc: QueryClient
) {
  const { data } = await apiClient.upsertOrgConfig(configKey!, orgConfig);
  await qc.invalidateQueries({ queryKey: [ORG_DEFAULTS_PATH] });
  return data;
}

export const useSetOrgConfigDefault = (
  configKey: OrgConfig['configKey'],
  onSuccess: (data: OrgConfig) => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) => {
  return useMutation<OrgConfig, unknown, OrgConfig>({
    mutationKey: [ORG_DEFAULTS_PATH],
    mutationFn: async (orgConfig: OrgConfig) => {
      const { data } = await apiClient.upsertOrgConfig(configKey!, orgConfig);
      return data;
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.refetchQueries({ queryKey: [ORG_DEFAULTS_PATH] });
    },
  });
};

const useUnSetOrgConfigDefault = (
  configKey: OrgConfig['configKey'],
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) => {
  return useMutation({
    mutationKey: [ORG_DEFAULTS_PATH],
    mutationFn: async () => {
      const { data } = await apiClient.deleteOrgConfig(configKey!);
      return data;
    },
    onSuccess,
    onError,
    onSettled: async () => {
      await qc.invalidateQueries({ queryKey: [ORG_DEFAULTS_PATH] });
    },
  });
};

export const useOrgConfigDefaults = (
  configKey: OrgConfig['configKey'],
  onSuccess: () => void,
  onError: (error: unknown) => void,
  qc: QueryClient
) => {
  const { mutate: setConfig } = useSetOrgConfigDefault(
    configKey,
    onSuccess,
    onError,
    qc
  );
  const { mutate: unsetConfig } = useUnSetOrgConfigDefault(
    configKey,
    onSuccess,
    onError,
    qc
  );
  return {
    setConfig,
    unsetConfig,
  };
};
