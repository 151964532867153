import React from 'react';
import {
  CloseConfig,
  FormField,
  Input,
  Loading,
  OrgConfig,
  useTranslation,
} from 'common';
import ErrorHandler from '../../../components/ErrorHandler';
import ConnectorLocation from '../Connectors/ConnectorLocation';

interface Props {
  connectorConfigValue: OrgConfig['configValue'];
  isLoading: boolean;
  setConnectorConfigValue: React.Dispatch<
    React.SetStateAction<OrgConfig['configValue']>
  >;
  setSaveableChanges: (arg: boolean) => void;
}

const configValueIsCloseConfig = (
  configValue: OrgConfig['configValue']
): configValue is CloseConfig => {
  return !!configValue && configValue.configType === 'closeConfig';
};

const CloseForm = ({
  setConnectorConfigValue,
  connectorConfigValue,
  setSaveableChanges,
  isLoading,
}: Props) => {
  const { tk } = useTranslation();

  const updateApiKey = (value: string) => {
    setConnectorConfigValue((prev) => ({
      ...prev,
      enabled: true,
      configType: 'closeConfig',
      apiKey: value,
    }));
    setSaveableChanges(true);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!configValueIsCloseConfig(connectorConfigValue)) {
    return (
      <ErrorHandler
        message={tk('There was an error loading Close configuration')}
      />
    );
  }

  if (!connectorConfigValue.enabled && connectorConfigValue.apiKey) {
    setSaveableChanges(true);
  }

  return (
    <div className="flex flex-col gap-2">
      <ConnectorLocation
        setSavableChanges={setSaveableChanges}
        connectorConfigValue={connectorConfigValue}
        setConnectorConfigValue={setConnectorConfigValue}
      />
      <FormField label={tk('API key')}>
        <Input
          id="api_key"
          className="h-8"
          onChange={updateApiKey}
          value={connectorConfigValue.apiKey}
        />
      </FormField>
    </div>
  );
};

export default CloseForm;
