import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { isFuture, parseISO } from 'date-fns';
import {
  BillingPeriodsType,
  formatDateOrDefault,
  getProductSpanStartIndex,
  HubspotConfig,
  InfoToolTip,
  isChangeProposalType,
  Item,
  ItemOverride,
  OrgConfig,
  Product,
  ProductRichTextEditorProps,
  ProductsDeprecated,
  ProductUpgrade,
  Proposal,
  ProposalItemRequest,
  ProposalLabel,
  QuantityOverride,
  SalesforceConfig,
  useToast,
  useTranslation,
} from 'common';

import { BillingPeriodPriceManagementDrawer } from './BillingPeriodPriceManagment/BillingPeriodPriceManagementDrawer';
import ProductOptionsButton from './ProductOptionsButton';
import { RichTextInput } from '../../../../components';
import {
  useDeleteProposalItem,
  useUpdateProposalItem,
} from 'app/src/services/proposal';
import { useFindOrgDefault } from '../../../../services/orgDefaults';

interface Props {
  billingPeriod: BillingPeriodsType;
  hideDiscounts: boolean;
  isDisabled?: boolean;
  isSaving: boolean;
  items: Item[];
  onUpdateOverrides?: (
    itemId: string,
    overrides?: ItemOverride[],
    quantityOverrides?: QuantityOverride[]
  ) => void;
  onUpgradeProduct?: (productId: string, upgradeProduct: Product) => void;
  proposal?: Proposal;
  readOnly?: boolean;
  upgrades?: ProductUpgrade[];
}

const ProductRichTextEditor: React.FC<ProductRichTextEditorProps> = (props) => (
  <RichTextInput {...props} featureSet="product.description" />
);

export function AppProducts({
  billingPeriod,
  hideDiscounts,
  isDisabled,
  isSaving,
  items,
  onUpdateOverrides: onUpdateOverridesProp,
  onUpgradeProduct,
  proposal,
  readOnly,
  upgrades,
}: Props): React.ReactElement {
  const { tk } = useTranslation();
  const [itemDraft, setItemDraft] = useState<Partial<Item>>();
  const [action, setAction] = useState<'customize' | 'price-management'>();
  const isChangeProposal = proposal && isChangeProposalType(proposal);
  const proposalSourceType = proposal?.externalSource?.sourceType ?? '';
  const getConfigKey = (): OrgConfig['configKey'] | undefined =>
    proposalSourceType === 'salesforce'
      ? 'salesforceConfig'
      : proposalSourceType === 'hubspot'
        ? 'hubspotConfig'
        : undefined;

  const { data: configData } = useFindOrgDefault(getConfigKey());

  const isHubspotConfig = (
    config: OrgConfig | null | undefined
  ): config is OrgConfig & { configValue: HubspotConfig } =>
    config?.configKey === 'hubspotConfig';

  const isSalesforceConfig = (
    config: OrgConfig | null | undefined
  ): config is OrgConfig & { configValue: SalesforceConfig } =>
    config?.configKey === 'salesforceConfig';

  const isProductLinkRequired: boolean | false | undefined =
    (isSalesforceConfig(configData) || isHubspotConfig(configData)) &&
    configData.configValue.syncLineItems &&
    (isSalesforceConfig(configData) || configData.configValue.syncProducts);

  const onUpdateOverrides = (
    itemId: string,
    overrides?: ItemOverride[],
    quantityOverrides?: QuantityOverride[]
  ) => {
    if (!itemDraft) return;

    const newDraft = {
      ...itemDraft,
      overrides,
      quantityOverrides,
    };
    setItemDraft(newDraft);
    onUpdateOverridesProp?.(itemId, overrides, quantityOverrides);
  };

  const qc = useQueryClient();
  const showToast = useToast();
  const { mutate: deleteProposalItem } = useDeleteProposalItem(
    proposal?.id,
    null,
    () => showToast.error('Proposal item deletion failed'),
    qc
  );
  const { mutate: updateProposalItem } = useUpdateProposalItem(proposal?.id);

  const getStartIndex = (bp: BillingPeriodsType): number | undefined =>
    proposal && isChangeProposal ? getProductSpanStartIndex(proposal, bp) : 0;

  const buildHeaderLabel = (name: string) => {
    return <ProposalLabel name={name} />;
  };

  const clearState = () => {
    setItemDraft(undefined);
    setAction(undefined);
  };

  const getActionsForItem = (item: Item) => {
    const { product } = item;

    //  proposal items only have actions for draft proposals in the proposal editor
    if (readOnly || !proposal || isDisabled) return null;

    const isOneTimeProduct = product.recurrence === 'one_time';
    const foundChangeType = proposal.changes?.itemChanges?.find(
      (ic) => ic.key === item.key
    )?.changeType;

    // on a change proposal, it's currently illegal to remove the last item or
    // remove a pre-existing one-time item, but removing
    // a newly added one is fine.
    const isRemovable =
      !isChangeProposal ||
      ((!isOneTimeProduct || foundChangeType === 'add') && items.length > 1);

    return (
      <div>
        {isChangeProposal && isOneTimeProduct && foundChangeType !== 'add' ? (
          <InfoToolTip
            title={tk(
              `One-time products cannot be removed or have quantities reduced. ${
                item.startDate && isFuture(parseISO(item.startDate))
                  ? `This item is scheduled for invoicing on ${formatDateOrDefault(
                      item.startDate
                    )}`
                  : `Invoiced on: ${formatDateOrDefault(
                      proposal.effectiveStartDate
                    )}`
              }`
            )}
          />
        ) : (
          <div className="-mt-2.5">
            <ProductOptionsButton
              dataTestId={`product-options-${item.name}`}
              isDisabled={!!itemDraft}
              isRemovable={isRemovable}
              item={item}
              onClickCustomize={() => {
                setAction('customize');
                setItemDraft(item);
              }}
              onClickEditDiscount={() => {
                setAction('price-management');
                setItemDraft(item);
              }}
              onClickRemoveDiscount={() =>
                item.id && onUpdateOverridesProp?.(item.id, [], [])
              }
              onClickRemoveItem={() => item.id && deleteProposalItem(item.id)}
              onUpdateItem={(body: ProposalItemRequest) => {
                item.id && updateProposalItem({ id: item.id, body });
              }}
              proposal={proposal}
            />
            {!!itemDraft && (
              <BillingPeriodPriceManagementDrawer
                billingPeriod={billingPeriod}
                isOpen={
                  action === 'price-management' &&
                  !!itemDraft.id &&
                  itemDraft.id === item.id
                }
                isSaving={isSaving}
                item={item}
                onClose={clearState}
                onUpdateOverrides={onUpdateOverrides}
                proposal={proposal}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <ProductsDeprecated
      actionsForItem={getActionsForItem}
      activeItemId={itemDraft?.id}
      billingPeriod={billingPeriod}
      buildHeaderLabel={buildHeaderLabel}
      editingItemId={action === 'customize' ? itemDraft?.id : undefined}
      getStartIndex={getStartIndex}
      hideDiscounts={hideDiscounts}
      hideUnchangedItems={false}
      isChangeType={isChangeProposal}
      isDisabled={isDisabled}
      isProductLinkRequired={isProductLinkRequired}
      itemChanges={readOnly ? undefined : proposal?.changes?.itemChanges}
      items={items}
      onUpdateCancel={clearState}
      onUpdateItem={(id: string, body: ProposalItemRequest) => {
        updateProposalItem({ id, body });
        setItemDraft(undefined);
      }}
      onUpgradeProduct={onUpgradeProduct}
      proposal={proposal}
      proposalSourceType={proposalSourceType}
      richTextEditor={ProductRichTextEditor}
      showCustomizations
      upgrades={upgrades}
    />
  );
}
